<template>
  <div v-if="active" class="mt-4">
    <VirtualTable
      :items="trainOperatorBookings"
      :key="trainOperatorBookings.length"
      :headers="headers"
      :row-props="getRowProps"
      :title="t('active_bookings')"
      :main-title="false"
      :loading="loading"
      :pages-loaded="pagesLoaded"
      @load-more="loadMore"
    >
      <template v-for="column in headers" #[`item.${column.value}`]="{ value }">
        <span :class="getColumnClass(value, column.value)" :key="column.value">
          {{ getColumnData(value, column.value) }}
        </span>
      </template>
    </VirtualTable>
  </div>
  <div v-else class="mt-4">
    <VirtualTable
      :items="trainOperatorBookings"
      :key="trainOperatorBookings.length"
      :headers="headers"
      :row-props="getRowProps"
      :title="t('completed_bookings')"
      :main-title="false"
      :item-value="'referenceNumber'"
      :button="true"
      :button-label="t('trainOperatorBookings.completeSelection')"
      :disable-button="disableButton"
      :loading="loading"
      :pages-loaded="pagesLoaded"
      @button-click="dialog = !dialog"
      @load-more="loadMore"
    >
      <template #selectAll>
        <v-checkbox
          :model-value="allSelected"
          hide-details
          density="comfortable"
          @update:model-value="toggleSelectAll"
        />
      </template>

      <template v-for="column in headers" #[`item.${column.value}`]="{ value }">
        <template v-if="column.value === 'select'">
          <v-checkbox
            hide-details
            density="comfortable"
            :key="column.key"
            :model-value="isSelected(value.referenceNumber)"
            @update:model-value="toggleSelection(value.referenceNumber)"
          />
        </template>
        <span
          v-else
          :class="getColumnClass(value, column.value)"
          :key="column.value"
        >
          {{ getColumnData(value, column.value) }}
        </span>
      </template>
    </VirtualTable>

    <ConfirmationDialog
      :dialog="dialog"
      :question-text="$t('markTrainBookingAsCompleted')"
      @confirm="deleteBooking"
      @deny="dialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { TrainOperatorBooking } from "@/services/client/generated/api";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { useI18n } from "vue-i18n";
import { getViewModel } from "@/views/train-operator/bookings-page/train-operator-bookings-logic";
import {
  ACTIVE_COLUMNS,
  COMPLETED_COLUMNS,
} from "@/constants/train-operator-booking-constants";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  trainOperatorBookings: {
    type: Array as () => TrainOperatorBooking[],
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  pagesLoaded: {
    type: Number,
    required: true,
    default: 0,
  },
});

const viewModel = getViewModel();

const emit = defineEmits([
  "selection-change",
  "load-more",
  "delete-selected-bookings",
]);
const { t } = useI18n();
const dialog = ref(false);
const disableButton = ref(true);
const selectedItemsSet = ref(new Set<string>());

const headers = computed(() => {
  const booking = props.trainOperatorBookings[0];
  if (!booking) return [];
  const columns = props.active ? ACTIVE_COLUMNS : COMPLETED_COLUMNS;
  return columns.map(column => ({
    title: t(column.title),
    value: column.value,
    key: column.key,
    sortable: column.sortable,
    align: "center" as "center" | "start" | "end",
  }));
});

const getRowProps = (row: {
  index: number;
  internalItem: object;
  item: TrainOperatorBooking;
}) => {
  return {
    style: `background-color: ${viewModel.cardColor(row.item)};`,
  };
};

const allSelected = computed(() => {
  return (
    props.trainOperatorBookings.length > 0 &&
    props.trainOperatorBookings.every(item =>
      selectedItemsSet.value.has(item.referenceNumber),
    )
  );
});

const toggleSelectAll = () => {
  if (allSelected.value) {
    selectedItemsSet.value.clear();
  } else {
    props.trainOperatorBookings.forEach(item =>
      selectedItemsSet.value.add(item.referenceNumber),
    );
  }
  handleSelectionChange(
    props.trainOperatorBookings.filter(item =>
      selectedItemsSet.value.has(item.referenceNumber),
    ),
  );
};

const isSelected = (referenceNumber: string) => {
  return selectedItemsSet.value.has(referenceNumber);
};

const toggleSelection = (referenceNumber: string) => {
  if (selectedItemsSet.value.has(referenceNumber)) {
    selectedItemsSet.value.delete(referenceNumber);
  } else {
    selectedItemsSet.value.add(referenceNumber);
  }

  const selectedItems = props.trainOperatorBookings.filter(item =>
    selectedItemsSet.value.has(item.referenceNumber),
  );
  handleSelectionChange(selectedItems);
};

const handleSelectionChange = (selectedItems: TrainOperatorBooking[]) => {
  disableButton.value = selectedItems.length === 0;
  emit("selection-change", selectedItems);
};

const loadMore = (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  emit("load-more", done);
};

const getColumnData = (item: TrainOperatorBooking, column: string) => {
  return (
    viewModel.allColumnData(item).find(col => col.id === column)?.data || ""
  );
};

const getColumnClass = (item: TrainOperatorBooking, column: string) => {
  return (
    viewModel.allColumnData(item).find(col => col.id === column)?.class || ""
  );
};

const deleteBooking = (): void => {
  dialog.value = false;
  emit("delete-selected-bookings");
};
</script>
