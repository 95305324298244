<template>
  <v-container class="d-flex justify-center pa-0">
    <v-card
      variant="outlined"
      class="pa-5 pt-8 border-left border-left-8"
      width="50vw"
    >
      <v-card-text class="pa-0">
        <v-row class="d-flex justify-center" dense>
          <v-col cols="12" md="4">
            <TextInput
              v-model="userId"
              :placeholder="$t('user_id')"
              :wide="true"
            />
          </v-col>

          <v-col cols="12" md="auto">
            <DropDown
              :selected-value="terrorState"
              :title="$t('userManagement.terror_state')"
              @select="
                (t: string) =>
                  (terrorState = t as TruckerIdentificationTOTerrorStatusEnum)
              "
              :entries="terrorStates"
            />
          </v-col>

          <v-col cols="12" md="auto">
            <DropDown
              :selected-value="warningState"
              @select="
                (t: string) =>
                  (warningState =
                    t as TruckerIdentificationTODocumentStatusEnum)
              "
              :title="$t('userManagement.identification_state')"
              :entries="idStates"
            />
          </v-col>

          <v-col cols="12" md="auto">
            <v-btn
              @click="() => $emit('submit', payload)"
              color="info"
              class="w-100"
            >
              {{ $t("search") }}
            </v-btn>
          </v-col>

          <v-col cols="12" md="auto">
            <v-btn @click="clearData" color="warning" class="w-100">
              {{ $t("clear") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import DropDown from "@/components/user-management/DropDown.vue";
import TextInput from "@/components/user-management/TextInput.vue";
import { terrorStates, idStates } from "@/internal-models/commons";
import {
  TruckerIdentificationTODocumentStatusEnum,
  TruckerIdentificationTOTerrorStatusEnum,
} from "@/services/client/generated";
import { ref, computed } from "vue";

const userId = ref<string | undefined>(undefined);

const terrorState = ref<TruckerIdentificationTOTerrorStatusEnum | undefined>(
  undefined,
);

const warningState = ref<TruckerIdentificationTODocumentStatusEnum | undefined>(
  undefined,
);

const emit = defineEmits<{
  submit: [
    payload: {
      userId: string | undefined;
      warningState: TruckerIdentificationTODocumentStatusEnum | undefined;
      terrorState: TruckerIdentificationTOTerrorStatusEnum | undefined;
    },
  ];
}>();

const clearData = () => {
  userId.value = undefined;
  warningState.value = undefined;
  terrorState.value = undefined;
  emit("submit", payload.value);
};

const payload = computed(() => {
  return {
    userId: userId.value,
    warningState: warningState.value,
    terrorState: terrorState.value,
  };
});
</script>
