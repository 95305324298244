// Composables
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { WebappUserRolesEnum } from "@/services/client/generated";
import { routesWithPrefix } from "@/router/router-utils";
import HomePage from "@/views/general/homepage/HomePage.vue";
import TerminalOperatorDashboard from "@/views/terminal-operator/dashboard/TerminalOperatorDashboard.vue";
import TerminalOperatorDeadlines from "@/views/terminal-operator/deadlines/TerminalOperatorDeadlines.vue";
import TerminalOperatorSlotManagement from "@/views/terminal-operator/slot-management/TerminalOperatorSlotManagement.vue";
import TerminalOperatorSettings from "@/views/terminal-operator/settings-page/TerminalOperatorSettings.vue";
// import TerminalOperatorBookingOverview from "@/views/terminal-operator/booking-overview/TerminalOperatorBookingOverview.vue";
import TrainOperatorBookings from "@/views/train-operator/bookings-page/TrainOperatorBookings.vue";
import ForwarderOperatorDashboard from "@/views/forwarder-operator/dashboard/ForwarderOperatorDashboard.vue";
import ForwarderOperatorTruckerManagement from "@/views/forwarder-operator/trucker-management/ForwarderOperatorTruckerManagement.vue";
import ForwarderOperatorBookingsOverview from "@/views/forwarder-operator/bookings-overview/ForwarderOperatorBookingsOverview.vue";
import GetStatusOverview from "@/views/forwarder-operator/get-status/GetStatusOverview.vue";
import GetStatusBatchOverview from "@/views/forwarder-operator/get-status-batch/GetStatusBatchOverview.vue";
// import SuccessView from "@/views/SuccessView.vue";
// import ContactView from "@/views/ContactView.vue";
// import ImpressumView from "@/views/ImpressumView.vue";
import NotFound from "@/views/general/not-found/NotFound.vue";
import UserManagement from "@/views/user-management/UserManagement.vue";
import TerminalOperatorBlockList from "@/views/terminal-operator/block-list/TerminalOperatorBlockList.vue";

export default [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/homepage",
        name: "home",
        component: HomePage,
        meta: {
          title: "Home | CONROO Webapp",
          requiresAuth: true,
          roles: [],
        },
      },
      //terminal operator routes
      ...routesWithPrefix("/terminalOperator", [
        {
          path: "",
          name: "terminalOperatorDashboard",

          component: TerminalOperatorDashboard,
          meta: {
            title: "Dashboard | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.TerminalOperationsAdmin,
              WebappUserRolesEnum.TerminalOperationsRead,
              WebappUserRolesEnum.TerminalOperationsWrite,
            ],
          },
        },
        {
          path: "/blockList",
          name: "terminalOperatorBlockList",
          component: TerminalOperatorBlockList,
          meta: {
            title: "Blockliste | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.IspsTerminalOperationsRead,
              WebappUserRolesEnum.IspsTerminalOperationsWrite,
            ],
          },
        },
        {
          path: "/deadlines",
          name: "terminalOperatorDeadlines",
          component: TerminalOperatorDeadlines,
          meta: {
            title: "Annahmebeschränkungen | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.TerminalOperationsAdmin,
              WebappUserRolesEnum.TerminalOperationsRead,
              WebappUserRolesEnum.TerminalOperationsWrite,
            ],
          },
        },
        {
          path: "/slotmanagement",
          name: "terminalOperatorSlotManagement",
          component: TerminalOperatorSlotManagement,
          meta: {
            title: "Slot Management | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.TerminalOperationsAdmin,
              WebappUserRolesEnum.TerminalOperationsRead,
              WebappUserRolesEnum.TerminalOperationsWrite,
            ],
          },
        },
        {
          path: "/settings",
          name: "terminalOperatorSettings",
          component: TerminalOperatorSettings,
          meta: {
            title: "Settings | CONROO Terminal Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.TerminalOperationsAdmin,
              WebappUserRolesEnum.TerminalOperationsRead,
              WebappUserRolesEnum.TerminalOperationsWrite,
            ],
          },
        },
        /**
         *? This is a temporary solution to hide the booking data page for terminal operators
         *? The service will be accessible in the future when it will be available
         *? per company.
         * with @Fabian, 14-10-2024
         **/
        // {
        //   path: "/bookingOverview",
        //   name: "terminalOperatorBookingOverview",
        //   component: TerminalOperatorBookingOverview,
        //   meta: {
        //     title: "Buchungsübersicht | CONROO Terminal Webapp",
        //     requiresAuth: true,
        //     roles: [
        //       WebappUserRolesEnum.ConrooWrite,
        //       WebappUserRolesEnum.ConrooRead,
        //       WebappUserRolesEnum.ConrooAdmin,
        //       WebappUserRolesEnum.TerminalOperationsAdmin,
        //       WebappUserRolesEnum.TerminalOperationsRead,
        //       WebappUserRolesEnum.TerminalOperationsWrite,
        //     ],
        //   },
        // },
      ]),
      {
        path: "/userManagement",
        name: "userManagement",
        component: UserManagement,
        meta: {
          title: "Benutzerverwaltung | CONROO Zugoperateure Webapp",
          requiresAuth: true,
          roles: [
            WebappUserRolesEnum.ConrooWrite,
            WebappUserRolesEnum.ConrooRead,
            WebappUserRolesEnum.ConrooAdmin,
          ],
        },
      },
      {
        path: "trainOperator",
        name: "trainOperatorBookings",
        component: TrainOperatorBookings,
        meta: {
          title: "Buchungsübersicht | CONROO Zugoperateure Webapp",
          requiresAuth: true,
          roles: [
            WebappUserRolesEnum.TrainOperatorRead,
            WebappUserRolesEnum.TrainOperatorWrite,
          ],
        },
      },
      ...routesWithPrefix("/forwarderOperator", [
        {
          path: "",
          name: "forwarderOperatorDashboard",
          component: ForwarderOperatorDashboard,
          meta: {
            title: "Dashboard | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
          },
        },
        {
          path: "/truckerManagement",
          name: "forwarderOperatorTruckerManagement",
          component: ForwarderOperatorTruckerManagement,
          meta: {
            title: "Benutzerverwaltung | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [WebappUserRolesEnum.ForwarderWrite],
          },
        },

        {
          path: "/bookingsOverview",
          name: "forwarderOperatorBookingsOverview",
          component: ForwarderOperatorBookingsOverview,
          meta: {
            title: "Buchungsübersicht | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
          },
        },

        {
          path: "/statusRequest",
          name: "statusRequest",
          component: GetStatusOverview,
          meta: {
            title: "Statusanfrage & Buchen | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
          },
        },

        {
          path: "/batchStatusRequest?file=:fileName&terminalId=:terminalIdInput&statusRequest=:getStatusRequestStringified",
          name: "batchStatusRequest",
          component: GetStatusBatchOverview,
          props: r => r.params,
          meta: {
            title: "Mehrfache Statusabfrage | CONROO Speditionen Webapp",
            requiresAuth: true,
            roles: [
              WebappUserRolesEnum.ForwarderWrite,
              WebappUserRolesEnum.ForwarderRead,
            ],
          },
        },
      ]),
      {
        path: "/:pathMatch(.*)*",
        name: "page-not-found-404",
        component: NotFound,
        meta: {
          title: "Seite nicht gefunden",
          requiresAuth: false,
          roles: [],
        },
      },
    ],
  },
];
