import { AxiosError } from "axios";
import { FirebaseError } from "firebase/app";

export type ErrorHandler = (error: unknown) => string;

export function getErrorByTypeOrDefault(
  error: unknown,
  defaultMessage?: string,
): string {
  if (error instanceof AxiosError && error.response?.data?.message) {
    return error.response.data.message;
  }

  if (error instanceof FirebaseError) {
    let message;

    switch (error.code) {
      case "auth/email-already-exists":
      case "auth/invalid-credential":
      case "auth/invalid-email":
      case "auth/invalid-password":
      case "auth/wrong-password":
        message = "invalid_credentials";
        break;
      default:
        message = "Something went wrong";
        break;
    }

    return message;
  }

  if (error instanceof Error) {
    if (error.message) return error.message;
  }

  return defaultMessage ?? "unknownError";
}
