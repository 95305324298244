<template>
  <div>
    <div v-if="terminalStore.loading">
      <v-progress-circular indeterminate color="red" />
    </div>

    <div v-else class="mt-6">
      <v-select
        class="mx-3"
        density="compact"
        :items="terminalStore.terminals ?? []"
        :no-data-text="$t('no_data_available')"
        :label="$t('terminals')"
        item-title="name"
        v-model="terminalStore.selectedTerminal"
        @update:modelValue="terminalStore.changeTerminal"
        variant="outlined"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTerminalStore } from "@/store/useTerminalStore";

const terminalStore = useTerminalStore();
</script>

<style scoped></style>
