<template>
  <div class="text-left pl-10">
    <v-row class="pb-4">
      <v-col>
        <TheMainHeader
          class="pt-10"
          :text="terminalStore.terminal?.name ?? $t('missing')"
        />
        <div class="text-h5 text-primary py-2">
          {{ $t("slotManagement.title") }} > {{ currentModule.name }}
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="currentModule"
          class="pt-10 pr-12"
          :label="$t('slotManagement.select_module')"
          item-title="name"
          item-value="id"
          return-object
          variant="outlined"
          :items="terminalData.modules"
        />
      </v-col>
    </v-row>

    <div class="pr-12">
      <ScheduleCalendarX
        :module="currentModule"
        :drawer="drawer"
        @selected-timeslots="updateSelectedTimeSlots"
      />
    </div>

    <div>
      <v-navigation-drawer
        v-model="drawer"
        @update:model-value="resetList"
        temporary
        location="right"
        :width="470"
      >
        <v-list-item class="font-weight-bold text-h5 py-3">
          {{ $t("edit_timeslot") }}
        </v-list-item>

        <v-divider />

        <v-list-item>
          <span class="font-weight-bold">
            <div class="my-3">
              {{ terminalStore.terminal?.name ?? $t("missing") }} |
              {{ currentModule.name }}
            </div>
            <v-divider
              v-if="currentSelectedTimeSlots.length > 1"
              class="my-2"
            />
            <span v-if="currentSelectedTimeSlots.length > 1">
              {{ $t("slotManagement.multiple_timeslot_selected") }} ({{
                currentSelectedTimeSlots.length
              }})
            </span>
          </span>

          <v-list v-if="currentSelectedTimeSlots.length">
            <v-list-item
              v-for="(timeSlot, index) in showFullList
                ? currentSelectedTimeSlots
                : currentSelectedTimeSlots.slice(0, 2)"
              :key="timeSlot.id"
            >
              <v-list-item-title>
                <v-row>
                  <v-col cols="1" align-self="center">
                    <span>#{{ index + 1 }}</span>
                  </v-col>
                  <v-col cols="11">
                    <span>
                      {{ formatTimeSlot(timeSlot) }}
                      <div>
                        <span :class="`bg-${timeSlot.category} px-2 rounded`">
                          {{ timeSlot.capacity.currentNumber }} /
                          {{ timeSlot.capacity.maximum }}
                        </span>
                      </div>
                    </span>
                  </v-col>
                </v-row>

                <v-divider
                  v-if="
                    index <
                      (showFullList
                        ? currentSelectedTimeSlots.length - 1
                        : 1) && currentSelectedTimeSlots.length > 1
                  "
                  class="mt-2"
                />
              </v-list-item-title>
            </v-list-item>

            <div v-if="currentSelectedTimeSlots.length > 2" class="mt-4">
              <v-btn @click="showFullList = !showFullList" variant="tonal">
                {{
                  showFullList
                    ? $t("collapse_show_less")
                    : $t("expand_show_all")
                }}
              </v-btn>
            </div>
          </v-list>
        </v-list-item>

        <v-divider />

        <v-list density="compact" nav>
          <v-list-item>
            <span class="font-weight-bold">
              {{ $t("slotManagement.slot_settings") }}
            </span>
          </v-list-item>
        </v-list>

        <v-switch
          v-model="status"
          :label="`${$t('slotManagement.slot_status')}: ${status ? $t('active') : $t('inactive')}`"
          color="primary"
          hide-details
          class="pl-4"
        />

        <v-text-field
          v-if="currentSelectedTimeSlots.length"
          v-model="capacityInput"
          class="pa-4"
          variant="outlined"
          density="compact"
          :label="$t('slotManagement.slot_capacity')"
          @update:model-value="handleCapacityChange"
        />

        <template #append>
          <v-divider />

          <div class="text-right pa-4">
            <v-btn
              class="mr-2"
              variant="text"
              color="red"
              @click="cancelChanges"
            >
              {{ $t("cancel") }}
            </v-btn>

            <v-btn color="primary" :disabled="!hasChanges" @click="saveChanges">
              {{ $t("save_changes") }}
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import ScheduleCalendarX from "@/components/ScheduleCalendarX.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import "@schedule-x/theme-default/dist/index.css";
import { useTerminalStore } from "@/store/useTerminalStore";
import data from "@/data/temporary-terminal-mock-data.json";
import TerminalData, { TimeSlot } from "@/internal-models/terminalMockData";
import { format, parseISO } from "date-fns";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { cloneDeep } from "lodash";
import { useI18n } from "vue-i18n";

const terminalStore = useTerminalStore();
const snackbarStore = useSnackbarStore();
const terminalData = ref<TerminalData>(data);
const { t } = useI18n();

const currentModule = ref(terminalData.value.modules[0]);
const showFullList = ref(false);
const drawer = ref(false);
const currentSelectedTimeSlots = ref<TimeSlot[]>([]);
const tempSlots = ref<TimeSlot[]>([]);
const status = ref<boolean>(true);
const capacityInput = ref<string | number>("");

const updateSelectedTimeSlots = (timeSlots: TimeSlot[]) => {
  currentSelectedTimeSlots.value = timeSlots;
  tempSlots.value = cloneDeep(timeSlots);

  if (timeSlots.length === 1) {
    capacityInput.value = timeSlots[0].capacity.maximum;
  } else {
    const allSameCapacity = timeSlots.every(
      slot => slot.capacity.maximum === timeSlots[0].capacity.maximum,
    );

    if (allSameCapacity) {
      capacityInput.value = timeSlots[0].capacity.maximum;
    } else {
      capacityInput.value = "Mixed";
    }
  }
  drawer.value = !drawer.value;
};

const hasChanges = computed(() => {
  return (
    JSON.stringify(currentSelectedTimeSlots.value) !==
    JSON.stringify(tempSlots.value)
  );
});

const resetList = (drawerState: boolean) => {
  if (!drawerState) {
    currentSelectedTimeSlots.value = [];
    tempSlots.value = [];
    capacityInput.value = "";
  }
};

const formatTimeSlot = (timeSlot: TimeSlot): string => {
  const start = parseISO(timeSlot.slotBegin);
  const end = parseISO(timeSlot.slotEnd);
  const day = format(start, "EEEE do 'of' MMMM yyyy");
  const time = `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
  return `${day} | ${time}`;
};

const toggleSlotStatus = () => {
  if (status.value) {
    tempSlots.value.forEach(slot => {
      slot.capacity.maximum = 50;
    });
  } else {
    tempSlots.value.forEach(slot => {
      slot.capacity.maximum = 0;
    });
  }
};

const canSaveChanges = computed(() => {
  return currentSelectedTimeSlots.value.every(slot => {
    const slotEndTime = new Date(slot.slotEnd).getTime();
    return slotEndTime >= Date.now();
  });
});

const handleCapacityChange = () => {
  const newCapacity = parseInt(capacityInput.value.toString(), 10);
  if (!isNaN(newCapacity)) {
    tempSlots.value.forEach(slot => {
      slot.capacity.maximum = newCapacity;
    });
  }
};

// Temporary function for mock data. This will be replaced with API calls
const saveChanges = () => {
  if (!canSaveChanges.value) {
    snackbarStore.showSnackbar({
      text: t("timeslot_in_the_past"),
      snackbarType: SnackbarType.ERROR,
    });
    return;
  }

  const moduleIndex = terminalData.value.modules.findIndex(
    module => module.id === currentModule.value.id,
  );

  if (moduleIndex !== -1) {
    const module = terminalData.value.modules[moduleIndex];

    module.timeSlots.forEach(slot => {
      const updatedSlot = tempSlots.value.find(s => s.id === slot.id);
      if (updatedSlot) {
        slot.capacity = { ...updatedSlot.capacity };
      }
    });
  }
  currentSelectedTimeSlots.value = [...tempSlots.value];
  drawer.value = false;
};

const cancelChanges = () => {
  tempSlots.value = JSON.parse(JSON.stringify(currentSelectedTimeSlots.value));
  drawer.value = false;
};

watch(status, () => {
  toggleSlotStatus();
});

watch(capacityInput, newValue => {
  if (newValue !== "Mixed") {
    handleCapacityChange();
  }
});
</script>

<style lang="css" scoped>
.v-navigation-drawer__scrim {
  position: fixed;
}
</style>
