<template>
  <div>
    <v-row class="mt-5">
      <v-col v-if="searchable" :cols="filterable ? 10 : 12">
        <template v-if="$slots['custom-search']">
          <slot name="custom-search" />
        </template>
        <template v-else>
          <v-text-field
            v-if="searchType === 'text'"
            v-model="search"
            :label="searchText"
            :placeholder="searchText"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            @update:model-value="emitSearch($event)"
          />
          <v-menu
            v-else
            v-model="searchDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
          >
            <template #activator="{ props: activeProps }">
              <v-text-field
                v-bind="activeProps"
                v-model="searchDateFormatted"
                readonly
                :label="searchText"
                :placeholder="searchText"
                prepend-inner-icon="mdi-calendar"
                variant="outlined"
                hide-details
              >
                <template #append-inner>
                  <v-icon
                    icon="mdi-close-circle"
                    :disabled="!searchDate"
                    :color="searchDate ? 'default' : 'grey'"
                    @click.stop="onDateCleared"
                  />
                </template>
              </v-text-field>
            </template>
            <v-locale-provider :locale="locale">
              <v-date-picker
                v-model="searchDate"
                :title="$t('select_date')"
                :header="$t('enter_date')"
                @update:model-value="onDateSelected"
              />
            </v-locale-provider>
          </v-menu>
        </template>
      </v-col>
      <v-col
        v-if="filterable"
        :cols="searchable ? 2 : 12"
        :align="searchable ? '' : 'end'"
      >
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template #activator="{ props: activeProps }">
            <v-btn
              v-bind="activeProps"
              variant="outlined"
              :block="searchable"
              :height="searchable ? '70%' : ''"
              append-icon="mdi-filter"
            >
              {{ $t("filter") }}
            </v-btn>
          </template>

          <v-card
            class="mx-auto"
            prepend-icon="mdi-table-filter"
            min-width="400"
            max-width="600"
          >
            <template #title>
              <span class="font-weight-black">
                {{ $t("filter_settings") }}
              </span>
            </template>
            <v-card-text>
              <slot name="filter" />
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <v-sheet v-if="filterable">
      <v-row>
        <v-col cols="12">
          <slot name="chips" />
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { format } from "date-fns";
import { useI18n } from "vue-i18n";

defineProps({
  searchable: {
    type: Boolean,
    default: false,
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  searchType: {
    type: String,
    default: "",
  },
  searchText: {
    type: String,
    default: "",
  },
});

const search = ref("");
const searchDate = ref<Date | null>(null);
const searchDateMenu = ref(false);
const filterMenu = ref(false);
const { locale } = useI18n();
const emit = defineEmits(["search", "search-date"]);

const searchDateFormatted = computed(() => {
  return searchDate.value ? format(searchDate.value, "MM/dd/yyyy") : "";
});

const onDateSelected = () => {
  searchDateMenu.value = false;
  emit("search-date", searchDateFormatted.value);
};

const emitSearch = (value: string) => {
  search.value = value;
  emit("search", value);
};

const onDateCleared = () => {
  searchDate.value = null;
  emit("search-date", null);
};
</script>
