import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { Terminal } from "@/services/client/generated";
import { terimnalUserClient } from "@/services/client/configs/services";
import { useAuthStore } from "./useAuthStore";

export const useTerminalStore = defineStore(
  "terminalStore",
  () => {
    const authStore = useAuthStore();
    const loading = ref(true);

    const terminal = ref<Terminal | null>(null);
    const selectedTerminal = ref<string | null>(null);

    const terminals = ref<Terminal[] | null>(null);

    const terminalData = computed(() => terminal.value);
    const terminalsData = computed(() => terminals.value);

    const setTerminal = (newTerminal: Terminal | null) => {
      terminal.value = newTerminal;
    };

    const setTerminals = (newTerminals: Terminal[]) => {
      terminals.value = newTerminals;
    };

    const setTerminalData = (newTerminal: Terminal | null): Terminal | null => {
      if (newTerminal) {
        setTerminal(newTerminal);
      }
      return newTerminal;
    };

    const getTerminal = (): Terminal | null => {
      if (terminal.value) {
        return terminal.value;
      }

      const savedTerminal = localStorage.getItem("terminal");
      if (savedTerminal) {
        terminal.value = JSON.parse(savedTerminal);
        return terminal.value;
      }
      return null;
    };

    const getTerminals = async (
      userId: string,
    ): Promise<Terminal[] | undefined> => {
      const response = await terimnalUserClient.getTerminalsForUser(userId);
      setTerminals(response.data);
      return response.data;
    };

    const fetchTerminals = async (): Promise<void> => {
      const userId = authStore.authUser?.userId;

      if (userId) {
        const terminalData = await getTerminals(userId);
        terminals.value = terminalData || null;

        const terminal = getTerminal();
        if (terminal) {
          selectedTerminal.value = terminal.name;
        } else if (terminals.value && terminals.value.length > 0) {
          selectedTerminal.value = terminals.value[0].name;
          setTerminalData(terminals.value[0]);
        }
      }

      loading.value = false;
    };

    const changeTerminal = async (value: string) => {
      selectedTerminal.value = value;
      if (selectedTerminal.value && terminals.value) {
        const terminalEntity = terminals.value.find(
          e => e.name === selectedTerminal.value,
        );
        if (terminalEntity) {
          setTerminalData(terminalEntity);
        }
      }
    };

    return {
      terminal,
      terminals,
      selectedTerminal,
      loading,
      terminalData,
      terminalsData,
      setTerminal,
      setTerminals,
      setTerminalData,
      getTerminal,
      getTerminals,
      fetchTerminals,
      changeTerminal,
    };
  },
  {
    persist: true,
  },
);
