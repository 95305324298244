<template>
  <v-form v-model="valid">
    <v-row class="mx-2">
      <v-col>
        <v-text-field
          v-model="trainNumber"
          class="rounded ma-0 pa-0"
          variant="outlined"
          required
          :placeholder="$t('trainNumber')"
          :label="$t('trainNumber')"
          :rules="trainNumberValidationRules"
          :error-messages="trainNumberError"
        />
      </v-col>

      <v-col>
        <v-menu
          transition="scale-transition"
          max-width="290px"
          min-width="290px"
          offset="-20"
          :close-on-content-click="false"
        >
          <template #activator="{ props }">
            <v-text-field
              v-bind="props"
              :model-value="fakeDepartureDate"
              variant="outlined"
              readonly
              data-testid="test-departureDate"
              :placeholder="departurePlaceholder"
              :label="$t('departureDate')"
              :error-messages="departureDateError"
            />
          </template>
          <v-locale-provider :locale="i18n.locale.value">
            <v-date-picker
              v-model="departureDate"
              color="primary"
              no-title
              :title="$t('select_date')"
              :no-data-text="$t('no_data_available')"
              :min="dateFormatted(today)"
              @click="updateDepartureDate"
            />
          </v-locale-provider>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          transition="scale-transition"
          max-width="290px"
          min-width="290px"
          offset="-20"
          :close-on-content-click="false"
        >
          <template #activator="{ props }">
            <v-text-field
              v-bind="props"
              :model-value="fakeDeadlineDate"
              readonly
              variant="outlined"
              :rules="[areAcceptanceDatesCorrect]"
              :placeholder="acceptanceDeadlinePlaceholder"
              :label="acceptanceDeadlinePlaceholder"
              :error-messages="acceptanceDatesError"
            />
          </template>
          <v-locale-provider :locale="i18n.locale.value">
            <v-date-picker
              v-model="acceptanceDates"
              multiple="range"
              color="primary"
              no-title
              :title="$t('select_date')"
              :min="dateFormatted(today)"
              :max="dateFormatted(departureDate)"
            />
          </v-locale-provider>
        </v-menu>
      </v-col>

      <v-spacer v-if="$vuetify.display.xxl" />

      <v-col>
        <TheButton
          :loading="false"
          :disabled="!valid"
          :elevation="0"
          :title="$t('create')"
          @click.native="addAcceptanceDeadline"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { format } from "date-fns";
import TheButton from "@/components/TheButton.vue";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./acceptance-restriction-logic";

const viewModel = getViewModel();
const i18n = useI18n();
const emit = defineEmits(["created-deadline"]);

const valid = ref(false);
const trainNumber = ref("");
const today = new Date();
const departureDate = ref(new Date(today));
const acceptanceDates = ref<Date[]>([]);
const trainNumberError = ref("");
const departureDateError = ref("");
const acceptanceDatesError = ref("");

const trainNumberValidationRules = [
  (v: string) => (!!v && v !== "0") || i18n.t("fieldRequired"),
  (v: string) => /^\d+$/.test(v) || i18n.t("onlyNumbers"),
];

const dateFormatted = (date: Date): string => {
  return format(date, "yyyy-MM-dd");
};

const areAcceptanceDatesCorrect = (): boolean | string => {
  return viewModel.isAcceptanceDeadlineCorrect(acceptanceDates.value);
};

const showErrorMessages = () => {
  if (!trainNumber.value)
    trainNumberError.value = i18n.t("fieldRequired") as string;
  if (!departureDate.value)
    departureDateError.value = i18n.t("fieldRequired") as string;
  if (typeof areAcceptanceDatesCorrect() === "string")
    acceptanceDatesError.value = i18n.t("pickBothDates") as string;
  setTimeout(() => {
    trainNumberError.value = "";
    departureDateError.value = "";
    acceptanceDatesError.value = "";
  }, 3000);
};

const updateList = () => {
  emit("created-deadline");
};

const updateDepartureDate = () => {
  acceptanceDates.value = [];
};

const addAcceptanceDeadline = async () => {
  try {
    if (!valid.value) {
      showErrorMessages();
      return;
    }

    await viewModel.addAcceptanceDeadline(
      acceptanceDates.value,
      dateFormatted(departureDate.value),
      trainNumber.value,
    );
    updateList();
  } catch (e) {
    const errorMessage = i18n.t(getErrorByTypeOrDefault(e));

    await viewModel.showSnackbarError(errorMessage as string);
  }
};

const departurePlaceholder = computed(() => i18n.t("departureDate") as string);
const acceptanceDeadlinePlaceholder = computed(
  () => `${i18n.t("startDate")} - ${i18n.t("endDate")}`,
);
const fakeDepartureDate = computed(() =>
  viewModel.getFakeDate(departureDate.value),
);
const fakeDeadlineDate = computed(() =>
  viewModel.getFakeDeadlineDates(acceptanceDates.value),
);
</script>

<style lang="scss" scoped></style>
