<template>
  <div v-if="items" id="select">
    <v-select
      v-model="defaultValueModel"
      clearable
      :density="density"
      :disabled="disabled"
      :items="items"
      :no-data-text="$t('no_data_available')"
      :variant="outlined ? 'outlined' : 'plain'"
      :item-title="itemTitle"
      :item-value="itemValue"
      :rules="rules"
      :hide-details="hideDetails"
      :label="$t(label)"
      @update:modelValue="changeItem"
    />
  </div>
</template>

<script setup lang="ts" generic="T">
import { watch, ref, Ref } from "vue";

type ValidatorCallback<T> = (value: T) => boolean | string;

const emits = defineEmits(["change-item"]);

const props = defineProps<{
  defaultValue?: T | null;
  label: string;
  items: T[];
  itemTitle?: string;
  itemValue?: string;
  rules?: ValidatorCallback<string>[];
  outlined?: boolean;
  hideDetails?: boolean;
  type?: string;
  disabled?: boolean;
  translate?: boolean;
  density?: "default" | "comfortable" | "compact";
}>();

const defaultValueModel = ref<T | null>(
  props.defaultValue ?? null,
) as Ref<T | null>;

watch(
  () => props.defaultValue,
  newVal => {
    if (newVal !== undefined) {
      defaultValueModel.value = newVal;
    }
  },
);

const changeItem = (value: T | null): void => {
  emits("change-item", { label: props.type, value });
};
</script>
