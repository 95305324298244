import Snackbar from "@/store/interfaces/snackbar";
import SnackbarType from "@/store/interfaces/snackbar-type";
import IconAssets from "@/constants/assets/icon-assets";

export function createSnackBar(text: string, type: SnackbarType): Snackbar {
  switch (type) {
    case SnackbarType.SUCCESS:
      return createSuccessSnackbar(text);
    case SnackbarType.WARNING:
      return createWarningSnackbar(text);
    case SnackbarType.ERROR:
      return createErrorSnackbar(text);
    case SnackbarType.INFO:
      return createInfoSnackbar(text);
    case SnackbarType.INITIAL:
      return createInitialSnackbar();
    default:
      throw new Error("Could not find snackbar type");
  }
}

function createSuccessSnackbar(text: string): Snackbar {
  return {
    value: true,
    color: "green",
    text,
    icon: IconAssets.SUCCESS,
  };
}

function createWarningSnackbar(text: string): Snackbar {
  return {
    value: true,
    color: "orange",
    text,
    icon: IconAssets.WARNING,
  };
}

function createErrorSnackbar(text: string): Snackbar {
  return {
    value: true,
    color: "red",
    text,
    icon: IconAssets.ERROR,
  };
}

function createInfoSnackbar(text: string): Snackbar {
  return {
    value: true,
    color: "blue",
    text,
    icon: IconAssets.ERROR,
  };
}

function createInitialSnackbar(): Snackbar {
  return {
    value: false,
    color: "green",
    text: "initial",
    icon: IconAssets.SUCCESS,
  };
}
