<template>
  <div>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="terminalStore.terminal?.name ?? $t('missing')"
    />

    <div>
      <div class="d-flex justify-space-between px-10 text-h4 text-primary py-2">
        <span>
          {{ $t("settings") }}
        </span>
        <span>
          <v-btn
            class="mr-4"
            variant="text"
            append-icon="mdi-delete-outline"
            @click="showDiscardModal = true"
          >
            {{ $t("discard_changes") }}
          </v-btn>
          <v-btn
            append-icon="mdi-content-save-outline"
            variant="tonal"
            @click="showSaveModal = true"
          >
            {{ $t("save_changes") }}
          </v-btn>
        </span>
      </div>
    </div>

    <div>
      <v-container>
        <v-card />
      </v-container>
    </div>

    <v-dialog v-model="showSaveModal" max-width="400px">
      <v-card>
        <v-card-text>{{ $t("save_changes_warning") }}</v-card-text>
        <v-card-actions>
          <v-btn @click="showSaveModal = false">{{ $t("cancel") }}</v-btn>
          <v-btn color="primary" @click="saveChanges">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDiscardModal" max-width="400px">
      <v-card>
        <v-card-text>{{ $t("discard_changes_warning") }}</v-card-text>
        <v-card-actions>
          <v-btn @click="showDiscardModal = false">{{ $t("cancel") }}</v-btn>
          <v-btn color="primary" @click="discardChanges">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import TheMainHeader from "@/components/TheMainHeader.vue";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const terminalStore = useTerminalStore();
const snackbarStore = useSnackbarStore();
const { t } = useI18n();

const showSaveModal = ref(false);
const showDiscardModal = ref(false);

const saveChanges = () => {
  snackbarStore.showSnackbar({
    text: t("changes_saved"),
    snackbarType: SnackbarType.SUCCESS,
  });
  showSaveModal.value = false;
};

const discardChanges = () => {
  snackbarStore.showSnackbar({
    text: t("changes_discarded"),
    snackbarType: SnackbarType.INFO,
  });
  showDiscardModal.value = false;
};
</script>
