<template>
  <div>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="$t('bookingsOverview')"
      data-testid="test-textBookingsOverview"
    />
    <div>
      <v-container fluid class="pt-10 pa-10">
        <VirtualTable
          :key="scrollKey"
          :items="filteredBookings"
          :headers="headers"
          :searchable="true"
          :filterable="true"
          :pages-loaded="page"
          :row-props="colorOfCard"
          @load-more="handleLoadMore"
        >
          <template #custom-search>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="referenceIdSearch"
                  @update:model-value="updateReferenceIdSearch"
                  :hint="$t('at_least_three_characters')"
                  :label="$t('search_by_reference_id')"
                  prepend-inner-icon="mdi-identifier"
                  variant="outlined"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="containerNumberSearch"
                  @update:model-value="updateContainerSearch"
                  :hint="$t('at_least_three_characters')"
                  :label="$t('search_by_container_number')"
                  prepend-inner-icon="mdi-train-car-container"
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </template>
          <template #filter>
            <FilterableAttributeRow
              v-if="bookings.length"
              :attributes="availableFilters"
              :applied-filters="appliedFilters"
              @filter-apply="applyFilterToBookings"
              @filter-update="updateAppliedFilters"
            />
          </template>
          <template #chips>
            <v-row class="pa-5">
              <v-chip
                v-for="filter in activeFilters"
                :key="filter.label"
                class="ma-2"
                @click:close="removeFilter(filter.label)"
                closable
              >
                {{ $t(filter.label) }}:
                {{
                  typeof filter.displayValue === "function"
                    ? filter.displayValue()
                    : filter.displayValue
                }}
              </v-chip>
            </v-row>
          </template>
          <template #item.status="{ value }">
            {{ getBookingStatus(value.status) }}
          </template>
          <template #item.type="{ value }">
            {{ t(value.type ?? "missing") }}
          </template>
          <template #item.referenceNumber="{ value }">
            {{ value.referenceNumber ? value.referenceNumber : t("missing") }}
          </template>
          <template #item.containerNumber="{ value }">
            {{ value.containerNumber ? value.containerNumber : t("missing") }}
          </template>
          <template #item.containerType="{ value }">
            {{ value.containerType ? value.containerType : t("missing") }}
          </template>
          <template #item.name="{ value }">
            {{ value.truckerInformation?.firstName }}
            {{ value.truckerInformation?.lastName }}
          </template>
          <template #item.timeSlot="{ value }">
            {{ parseTimeslot(value.timeslot) }}
          </template>
          <template #item.actions="{ value }">
            <v-icon
              v-if="value.status === 'completed'"
              size="x-large"
              icon="mdi-file-download-outline"
              color="#00295B"
              @click="downloadTranshipment(value.id as number)"
            />
            <div v-else-if="value.status === 'transhipment'">
              <v-spacer />
            </div>
            <v-icon
              v-else
              @click="clickDeleteBooking(value)"
              icon="mdi-close-circle-outline"
              size="x-large"
              color="#ff005b"
              data-testid="test-deleteBooking"
            />
          </template>
        </VirtualTable>
      </v-container>
    </div>
    <ConfirmationDialog
      v-if="selectedBooking && selectedBooking.id"
      :dialog="deleteDialog"
      :question-text="$t('deleteConfirmationBooking')"
      @confirm="deleteBooking(selectedBooking.id)"
      @deny="denyBookingDeletion"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import {
  BookingForForwarderTO,
  BookingForForwarderTOStatusEnum,
  BookingsFilterRequestTO,
  TimeSlot,
} from "@/services/client/generated";
import FilterableAttributeRow from "@/views/forwarder-operator/bookings-overview/FilterableAttributeRow.vue";
import { useI18n } from "vue-i18n";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { getViewModel } from "./forwarder-bookings-overview-logic";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();
const viewModel = getViewModel();
const deleteDialog = ref(false);
const page = ref(0);
const size = ref(200);
const loading = ref(false);
const scrollKey = ref(0);

const bookings = ref<BookingForForwarderTO[]>([]);
const filteredBookings = ref<BookingForForwarderTO[]>([]);

const selectedBooking = ref<BookingForForwarderTO | null>(null);
const filterRequest = ref<BookingsFilterRequestTO>({});
const { t, locale } = useI18n();

const availableFilters = ref<Record<string, object[]>>({});
const activeFilters = ref<
  { label: string; value: string; displayValue: string | (() => string) }[]
>([]);
const appliedFilters = ref<{ label: string; value: string }[]>([]);

const referenceIdSearch = ref("");
const containerNumberSearch = ref("");

const headers = computed(
  () =>
    [
      { title: t("status"), value: "status", align: "center" },
      { title: t("bookingType"), value: "type", align: "center" },
      {
        title: t("referenceNumber"),
        value: "referenceNumber",
        align: "center",
      },
      {
        title: t("containerNumber"),
        value: "containerNumber",
        align: "center",
      },
      { title: t("containerType"), value: "containerType", align: "center" },
      { title: t("truckerName"), value: "name", align: "center" },
      { title: t("licensePlate"), value: "licensePlate", align: "center" },
      { title: t("terminal"), value: "terminalName", align: "center" },
      { title: t("timeSlot"), value: "timeSlot", align: "center" },
      { title: "", value: "actions", align: "center" },
    ] as const,
);

const applyFilterToBookings = async (
  filters: {
    label: string;
    value: string | { id: string; display: string };
    displayValue?: string;
  }[],
) => {
  if (!filters || filters.length === 0) {
    page.value = 0;

    activeFilters.value = activeFilters.value.filter(filter =>
      ["referenceNumber", "containerNumber"].includes(filter.label),
    );

    filterRequest.value = Object.fromEntries(
      Object.entries(filterRequest.value).filter(([key]) =>
        ["referenceNumber", "containerNumber"].includes(key),
      ),
    );

    scrollKey.value++;

    try {
      filteredBookings.value = await refreshState();
    } catch {}
    return;
  }

  const retainedLabels = ["referenceNumber", "containerNumber"];
  const filterLabels = [
    ...filters.map(filter => filter.label),
    ...retainedLabels,
  ];

  activeFilters.value = activeFilters.value.filter(filter =>
    filterLabels.includes(filter.label),
  );

  for (const key of Object.keys(filterRequest.value)) {
    if (!filterLabels.includes(key === "truckerId" ? "truckerName" : key)) {
      delete filterRequest.value[key as keyof BookingsFilterRequestTO];
    }
  }

  filters.forEach(filter => {
    const existingFilterIndex = activeFilters.value.findIndex(
      f => f.label === filter.label,
    );

    const { value, displayValue, label } = (() => {
      switch (filter.label) {
        case "truckerId":
          if (typeof filter.value === "object") {
            return {
              value: filter.value.id,
              displayValue: filter.value.display,
              label: computed(() => t("truckerName")),
            };
          }
          break;
        case "bookingType":
          if (typeof filter.value === "string") {
            return {
              value: filter.value,
              displayValue: t(filter.value),
              label: filter.label,
            };
          }
          break;
        case "timeSlotId":
          if (typeof filter.value === "object") {
            return {
              value: filter.value.id,
              displayValue: filter.value.display,
              label: filter.label,
            };
          }
          break;
        default:
          return {
            value: filter.value as string,
            displayValue: filter.displayValue || (filter.value as string),
            label: filter.label,
          };
      }
      return {
        value: filter.value,
        displayValue: filter.value,
        label: filter.label,
      };
    })();

    const newFilter = {
      label: label instanceof Object && "value" in label ? label.value : label,
      value: value as string,
      displayValue: displayValue as string,
    };

    if (existingFilterIndex !== -1) {
      activeFilters.value[existingFilterIndex] = newFilter;
    } else {
      activeFilters.value.push(newFilter);
    }

    const requestLabel = label === "truckerName" ? "truckerId" : label;

    filterRequest.value = {
      ...filterRequest.value,
      [requestLabel as keyof BookingsFilterRequestTO]: value,
    };
  });

  page.value = 0;
  scrollKey.value++;

  try {
    filteredBookings.value = await refreshState();
  } catch {}
};

const updateAppliedFilters = (filters: { label: string; value: string }[]) => {
  appliedFilters.value = filters;
};

const removeFilter = async (label: string) => {
  activeFilters.value = activeFilters.value.filter(
    filter => filter.label !== label,
  );

  appliedFilters.value = appliedFilters.value.filter(
    filter => filter.label !== label,
  );

  const requestLabel = label === "truckerName" ? "truckerId" : label;
  delete filterRequest.value[requestLabel as keyof BookingsFilterRequestTO];

  page.value = 0;

  if (label === "referenceNumber") {
    referenceIdSearch.value = "";
  }
  if (label === "containerNumber") {
    containerNumberSearch.value = "";
  }

  scrollKey.value++;

  filteredBookings.value = await refreshState();
};

const parseTimeslot = (timeslot: TimeSlot | undefined | null): string => {
  if (!timeslot) return t("missing") as string;
  return viewModel.parseTimeslot(timeslot, locale.value);
};

const getBookingStatus = (
  bookingStatus: BookingForForwarderTOStatusEnum | undefined,
): string => {
  if (bookingStatus === undefined) {
    return t("missing");
  }

  switch (bookingStatus) {
    case BookingForForwarderTOStatusEnum.Active:
      return t("booked");
    case BookingForForwarderTOStatusEnum.RegistrationBlocked:
      return t("registrationBlocked");
    case BookingForForwarderTOStatusEnum.RegistrationRestricted:
      return t("registrationRestricted");
    case BookingForForwarderTOStatusEnum.Registered:
    case BookingForForwarderTOStatusEnum.Transhipment:
    case BookingForForwarderTOStatusEnum.TerminalReached:
      return t("terminalReached");
    case BookingForForwarderTOStatusEnum.Completed:
      return t("completed");
    case BookingForForwarderTOStatusEnum.Cancelled:
      return t("cancelled");
    case BookingForForwarderTOStatusEnum.CounterRequired:
      return t("counterRequired");
  }
};

const refreshState = async (): Promise<BookingForForwarderTO[]> => {
  try {
    const fetchedBookings = await viewModel.getBookingsForForwarder(
      filterRequest.value,
      page.value,
      size.value,
    );

    if (page.value === 0) {
      bookings.value = fetchedBookings;
    } else {
      bookings.value = [...bookings.value, ...fetchedBookings];
    }

    availableFilters.value = viewModel.mapBookingValuesIntoUIComponent(
      bookings.value,
    );

    if (fetchedBookings.length > 0) {
      page.value++;
    }

    applyAllActiveFilters();

    return fetchedBookings;
  } catch (_) {
    return [];
  }
};

const clickDeleteBooking = (booking: BookingForForwarderTO): void => {
  selectedBooking.value = booking;
  deleteDialog.value = true;
};

const denyBookingDeletion = (): void => {
  selectedBooking.value = null;
  deleteDialog.value = false;
};

const deleteBooking = async (bookingId: number): Promise<void> => {
  const booking = bookings.value?.find(e => e.id === bookingId);
  if (booking && booking.truckerInformation && booking.truckerInformation.id) {
    await viewModel.deleteBooking(bookingId, booking.truckerInformation.id);
  }
  await refreshState();
  deleteDialog.value = false;
};

const downloadTranshipment = async (bookingId: number): Promise<void> => {
  const booking = bookings.value?.find(e => e.id === bookingId);
  if (booking && booking.truckerInformation && booking.truckerInformation.id) {
    const name = [
      t("transhipment"),
      booking.referenceNumber,
      booking.containerNumber,
    ]
      .filter(e => e)
      .join("-");
    await viewModel.downloadTranshipment(
      bookingId,
      booking.truckerInformation.id,
      name,
    );
  }
};

const colorOfCard = (row: {
  index: number;
  internalItem: object;
  item: BookingForForwarderTO;
}) => {
  switch (row.item.status) {
    case BookingForForwarderTOStatusEnum.Active:
      return { style: `background-color: #fddc7f` };
    case BookingForForwarderTOStatusEnum.RegistrationBlocked:
    case BookingForForwarderTOStatusEnum.RegistrationRestricted:
    case BookingForForwarderTOStatusEnum.Cancelled:
    case BookingForForwarderTOStatusEnum.CounterRequired:
      return { style: `background-color: #b72003c8` };
    case BookingForForwarderTOStatusEnum.Registered:
    case BookingForForwarderTOStatusEnum.Transhipment:
    case BookingForForwarderTOStatusEnum.TerminalReached:
      return { style: `background-color: #fddc7f` };
    case BookingForForwarderTOStatusEnum.Completed:
      return { style: `background-color: #c9e08c` };
    default:
      throw new Error("Value should be part of enum");
  }
};

const handleLoadMore = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  if (loading.value) return;

  loading.value = true;
  done("loading");
  try {
    const loadedBookings = await refreshState();

    if (activeFilters.value.length === 0) {
      filteredBookings.value = [...bookings.value];
    }

    done(loadedBookings.length ? "ok" : "empty");
  } catch {
    done("error");
  } finally {
    loading.value = false;
  }
};

const applyAllActiveFilters = () => {
  if (!activeFilters.value.length) {
    filteredBookings.value = [...bookings.value];
    return;
  }

  filteredBookings.value = bookings.value.filter(booking =>
    activeFilters.value.every(filter => {
      if (filter.label === "referenceNumber") {
        return booking.referenceNumber?.includes(filter.value);
      }
      if (filter.label === "containerNumber") {
        return booking.containerNumber?.includes(filter.value);
      }
      return true;
    }),
  );
};

async function updateReferenceIdSearch(newValue: string) {
  if (newValue.length >= 3) {
    page.value = 0;

    filterRequest.value.referenceNumber = "";
    filterRequest.value.referenceNumber = newValue;

    activeFilters.value = activeFilters.value.filter(
      f => f.label !== "referenceNumber",
    );
    activeFilters.value.push({
      label: "referenceNumber",
      value: newValue,
      displayValue: newValue,
    });

    filteredBookings.value = await refreshState();
    applyAllActiveFilters();
  } else if (newValue.length === 0) {
    removeFilter("referenceNumber");
  }
}

async function updateContainerSearch(newValue: string) {
  if (newValue.length >= 3) {
    page.value = 0;

    filterRequest.value.containerNumber = "";
    filterRequest.value.containerNumber = newValue;

    activeFilters.value = activeFilters.value.filter(
      f => f.label !== "containerNumber",
    );
    activeFilters.value.push({
      label: "containerNumber",
      value: newValue,
      displayValue: newValue,
    });

    filteredBookings.value = await refreshState();
    applyAllActiveFilters();
  } else if (newValue.length === 0) {
    removeFilter("containerNumber");
  }
}

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      bookings.value = [];
      page.value = 0;
      size.value = 50;
      await refreshState();
    }
  },
);
</script>

<style scoped lang="scss"></style>
