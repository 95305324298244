<template>
  <v-card elevation="0" data-testid="test-bookings-filter-row">
    <v-card-title class="pa-5">
      <v-row>
        <v-col cols="12" align-self="center">
          {{ $t("status") }}
        </v-col>
        <v-col cols="6" align-self="center">
          <TheDropdown
            data-testid="test-bookingsOverviewBookingTypeFilter"
            label="bookingType"
            item-title="itemKey"
            item-value="itemValue"
            :type="'bookingType'"
            :translate="true"
            :outlined="true"
            :hide-details="true"
            :density="'comfortable'"
            :items="translatedAttributes.bookingType"
            :default-value="getSelectedFilterValue('bookingType')"
            @change-item="assignFilter"
          />
        </v-col>
        <v-col cols="6" align-self="center">
          <TheDropdown
            data-testid="test-bookingsOverviewContainerTypeFilter"
            label="containerType"
            item-title="itemKey"
            item-value="itemValue"
            :type="'containerType'"
            :outlined="true"
            :hide-details="true"
            :density="'comfortable'"
            :items="translatedAttributes.containerType"
            :default-value="getSelectedFilterValue('containerType')"
            @change-item="assignFilter"
          />
        </v-col>
        <v-col cols="6" align-self="center">
          <TheDropdown
            data-testid="test-bookingsOverviewTruckerNameFilter"
            label="truckerName"
            item-title="itemKey"
            item-value="itemValue"
            :type="'truckerName'"
            :outlined="true"
            :hide-details="true"
            :density="'comfortable'"
            :items="translatedAttributes.truckerName"
            :default-value="getSelectedFilterValue('truckerName')"
            @change-item="assignFilter"
          />
        </v-col>
        <v-col cols="6" align-self="center">
          <TheDropdown
            data-testid="test-bookingsOverviewLicensePlateFilter"
            label="licensePlate"
            item-title="itemKey"
            item-value="itemValue"
            :type="'licensePlate'"
            :outlined="true"
            :hide-details="true"
            :density="'comfortable'"
            :items="translatedAttributes.licensePlate"
            :default-value="getSelectedFilterValue('licensePlate')"
            @change-item="assignFilter"
          />
        </v-col>
        <v-col cols="6" align-self="center">
          <TheDropdown
            data-testid="test-bookingsOverviewTerminalFilter"
            label="terminal"
            item-title="itemKey"
            item-value="itemValue"
            :type="'terminal'"
            :outlined="true"
            :hide-details="true"
            :density="'comfortable'"
            :items="translatedAttributes.terminal"
            :default-value="getSelectedFilterValue('terminal')"
            @change-item="assignFilter"
          />
        </v-col>
        <v-col cols="6" align-self="center">
          <TheDropdown
            data-testid="test-bookingsOverviewTimeSlotFilter"
            label="timeSlotId"
            item-title="itemKey"
            item-value="itemValue"
            :type="'timeSlotId'"
            :outlined="true"
            :hide-details="true"
            :density="'comfortable'"
            :items="translatedAttributes.timeSlotId"
            :default-value="getSelectedFilterValue('timeSlotId')"
            @change-item="assignFilter"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-actions>
      <v-col cols="12" class="text-right">
        <v-btn
          data-testid="test-bookings-filter-apply-button"
          @click="applyFilters"
          color="primary"
          >{{ $t("apply_filters") }}</v-btn
        >
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { toRefs, computed } from "vue";
import TheDropdown from "@/components/TheDropdown.vue";
import { TimeSlot } from "@/services/client/generated";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./forwarder-bookings-overview-logic";

const i18n = useI18n();
const viewModel = getViewModel();

const props = defineProps<{
  attributes: Record<string, object[] | string[]>;
  spacer?: boolean;
  appliedFilters: {
    label: string;
    value: string | { id: string; display: string };
    displayValue?: string;
  }[];
}>();

const emits = defineEmits(["filter-apply", "filter-update"]);

const { attributes } = toRefs(props);

const applyFilters = () => {
  emits("filter-apply", props.appliedFilters);
};

const getSelectedFilterValue = (label: string) => {
  const filter = props.appliedFilters.find(f => f.label === label);
  if (filter) {
    const attributeArray =
      translatedAttributes.value[
        label as keyof typeof translatedAttributes.value
      ];

    if (attributeArray) {
      return attributeArray.find(item => {
        return (
          (label === "truckerName" &&
            typeof filter.value === "string" &&
            item &&
            item.itemValue === filter.value) ||
          (item && item.itemValue === filter.value)
        );
      });
    }
  }
  return null;
};

const assignFilter = (payload: { label: string; value: string | object }) => {
  const updatedFilters = [...props.appliedFilters];

  const index = updatedFilters.findIndex(
    filter => filter.label === payload.label,
  );

  if (index !== -1) {
    updatedFilters.splice(index, 1);
  }

  if (payload.value) {
    switch (payload.label) {
      case "truckerName": {
        const selectedTrucker = (
          attributes.value.truckerName as {
            id: string;
            firstName: string;
            lastName: string;
          }[]
        ).find(trucker => trucker.id === payload.value);

        if (selectedTrucker) {
          updatedFilters.push({
            label: "truckerName",
            value: selectedTrucker.id,
            displayValue: `${selectedTrucker.firstName} ${selectedTrucker.lastName}`,
          });
        }
        break;
      }
      case "terminal": {
        updatedFilters.push({
          label: "terminalName",
          value: payload.value as string,
        });
        break;
      }
      case "timeSlotId": {
        const selectedTimeSlot = (
          attributes.value.timeSlotId as TimeSlot[]
        ).find(slot => slot.id === parseInt(payload.value as string));

        if (selectedTimeSlot) {
          updatedFilters.push({
            label: "timeSlotId",
            value: {
              id: selectedTimeSlot.id.toString(),
              display: viewModel.parseTimeslot(
                selectedTimeSlot,
                i18n.locale.value,
              ),
            },
          });
        }
        break;
      }
      default: {
        updatedFilters.push({
          label: payload.label,
          value:
            typeof payload.value === "string"
              ? payload.value
              : { id: "", display: "" },
        });
        break;
      }
    }
  }

  emits("filter-update", updatedFilters);
};

const translatedAttributes = computed(() => {
  const translateItems = (items: string[]) =>
    items.map(item => {
      if (item != null) {
        const translatedItem: Record<string, string | object> = {};
        translatedItem.itemKey = i18n.t(item);
        translatedItem.itemValue = item;

        return translatedItem;
      }
    });

  return {
    bookingType: translateItems(attributes.value.bookingType as string[]),
    containerType: translateItems(attributes.value.containerType as string[]),
    truckerName: (
      attributes.value.truckerName as {
        id: string;
        firstName: string;
        lastName: string;
      }[]
    ).map(trucker => ({
      itemKey: `${trucker.firstName} ${trucker.lastName}`,
      itemValue: trucker.id,
    })),
    licensePlate: (attributes.value.licensePlate as string[]).map(plate => ({
      itemKey: plate,
      itemValue: plate || "",
    })),
    terminal: (attributes.value.terminal as string[]).map(terminal => ({
      itemKey: terminal,
      itemValue: terminal || "",
    })),
    timeSlotId: (attributes.value.timeSlotId as TimeSlot[]).map(timeSlot => ({
      itemKey: viewModel.parseTimeslot(timeSlot, i18n.locale.value),
      itemValue: timeSlot.id ? String(timeSlot.id) : "",
    })),
  };
});
</script>
