import "vuetify/styles";
import { createVuetify } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";
import { customSVGs } from "@/assets/iconSets/customSVG";
import { en, de } from "vuetify/locale";

const vuetify = createVuetify({
  locale: {
    messages: { en, de },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#14005c",
          "primary-lighten-1": "43337d",
          secondary: "#c1c1c1",
          red: "#F03254",
          green: "#2fb760",
          "green-lighten-1": "#6dcd90",
          yellow: "#ffc82d",
          "yellow-lighten-1": "#ffd96c",
          "red-lighten-1": "#f57087",
          aqua: "#01ffff",
          "aqua-lighten-1": "#006b6b",
          lightestBlue: "#bfc9d7",
          lightBlue: "#3f5d85",
          lightPink: "#FAA0A0",
          darkGrey: "#A1A1A1",
          darkBlue: "#1e2f55",
          darkBlue2: "#00295B",
          greyish: "#E5E9EE",
          pinkish: "#ff005b",
          anchor: "#8c9eff",
          error: "#ff453a",
          creamWhite: "#ffffe6",
        },
      },
    },
  },
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
});

export default vuetify;
