<template>
  <div
    class="text-white d-flex justify-end"
    :class="{ 'language-switcher position-absolute px-4': absolutePosition }"
  >
    <v-menu
      :transition="transition"
      :location="absolutePosition ? 'bottom' : 'end'"
    >
      <template #activator="{ props }">
        <v-btn
          rounded="lg"
          :variant="absolutePosition ? 'outlined' : 'text'"
          :color="absolutePosition ? 'white' : 'primary'"
          icon="mdi-translate"
          :block="block"
          v-bind="props"
        />
      </template>
      <v-list>
        <v-list-item
          v-for="(lang, index) in languages"
          :key="index"
          @click="switchLanguage(lang.value)"
        >
          <v-list-item-title>
            <img
              width="15"
              :src="lang.value === 'en' ? EnglishFlag : GermanFlag"
              :alt="lang.value === 'en' ? 'English Flag' : 'German Flag'"
            />
            {{ $t(lang.text) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts" setup>
import { useLocales, changeLocale } from "@/composables/useLocales";
import { useI18n } from "vue-i18n";
import EnglishFlag from "@/assets/flags/en.png";
import GermanFlag from "@/assets/flags/de.png";
import { onMounted } from "vue";

defineProps({
  absolutePosition: {
    type: Boolean,
    default: false,
  },
  dropdownIcon: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "strong-text",
  },
  block: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Boolean,
    default: false,
  },
  transition: {
    type: String,
    default: "slide-y-transition",
  },
});

const { locale } = useI18n();

const { languages } = useLocales();

const switchLanguage = (newLang: string) => {
  changeLocale(newLang, locale);
};

onMounted(() => {
  const savedLocale = localStorage.getItem("user-locale");
  if (savedLocale) {
    locale.value = savedLocale;
  }
});
</script>

<style lang="css" scoped>
.language-switcher {
  top: 1rem;
  right: 2rem;
}
</style>
