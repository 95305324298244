<template>
  <div>
    <v-card-title v-if="title || showSettings || button" class="pa-0">
      <v-row>
        <v-col
          v-if="title"
          class="text-left text-primary font-weight-bold"
          :class="mainTitle ? 'text-h3' : 'text-h5'"
        >
          {{ title }}
        </v-col>
        <v-col v-if="showSettings" class="text-right">
          <v-btn append-icon="mdi-cog" variant="tonal">
            {{ t("settings") }}
          </v-btn>
        </v-col>
        <v-col cols="12" align="end" v-if="button">
          <v-btn
            color="primary"
            :disabled="disableButton"
            @click="onButtonClick"
          >
            {{ buttonLabel }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-sheet v-if="headerSectionName" variant="outlined">
      <v-card class="rounded-lg" elevation="2">
        <v-card-title class="my-4">{{ headerSectionName }}</v-card-title>
        <v-row>
          <v-col cols="12">
            <slot name="header" />
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>

    <v-card-subtitle v-if="subtitle" class="mb-5 text-left">
      {{ subtitle }}
    </v-card-subtitle>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps({
  headerSectionName: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  },
  showSettings: {
    type: Boolean,
    default: false,
  },
  button: {
    type: Boolean,
    default: false,
  },
  buttonLabel: {
    type: String,
    default: "",
  },
  disableButton: {
    type: Boolean,
    default: false,
  },
  mainTitle: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["buttonClick"]);
const onButtonClick = () => {
  emit("buttonClick");
};
</script>
