/* tslint:disable */
/* eslint-disable */
/**
 * Conroo REST Frontend Backend
 * REST Interface for new Conroo backend for frontend app communication.
 *
 * The version of the OpenAPI document: v1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * acceptance deadline to be created
 * @export
 * @interface AcceptanceDeadline
 */
export interface AcceptanceDeadline {
    /**
     * 
     * @type {number}
     * @memberof AcceptanceDeadline
     */
    'id'?: number;
    /**
     * 
     * @type {Array<Train>}
     * @memberof AcceptanceDeadline
     */
    'trains'?: Array<Train>;
    /**
     * 
     * @type {string}
     * @memberof AcceptanceDeadline
     */
    'deadlineStart': string;
    /**
     * 
     * @type {string}
     * @memberof AcceptanceDeadline
     */
    'deadlineEnd': string;
    /**
     * 
     * @type {number}
     * @memberof AcceptanceDeadline
     */
    'terminalId': number;
}
/**
 * App version update
 * @export
 * @interface AppVersionResponse
 */
export interface AppVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    'link'?: string;
}
/**
 * Delivery/Pickup Booking of Container
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'bookingId': number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'relatedBookingsIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'referenceNumber'?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof Booking
     */
    'type': BookingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'forwarderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'module'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'parkingLot'?: string;
    /**
     * state of a Booking
     * @type {string}
     * @memberof Booking
     */
    'bookingState': BookingBookingStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'containerUpdatable': boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'cancelledBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'counterReason'?: BookingCounterReasonEnum;
    /**
     * 
     * @type {number}
     * @memberof Booking
     * @deprecated
     */
    'terminalId': number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     * @deprecated
     */
    'terminalName': string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'facilityId': number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'facilityName': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'facilityType': BookingFacilityTypeEnum;
    /**
     * 
     * @type {Container}
     * @memberof Booking
     */
    'container'?: Container;
    /**
     * 
     * @type {TimeSlot}
     * @memberof Booking
     */
    'timeSlot': TimeSlot;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'bookingTerminalStatus'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'userRegisterNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'autoRegisterNext'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Booking
     */
    'missingFields'?: Array<BookingMissingFieldsEnum>;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'customerName'?: string;
}

export const BookingTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type BookingTypeEnum = typeof BookingTypeEnum[keyof typeof BookingTypeEnum];
export const BookingBookingStateEnum = {
    Active: 'active',
    TerminalReached: 'terminal_reached',
    RegistrationBlocked: 'registration_blocked',
    RegistrationRestricted: 'registration_restricted',
    Registered: 'registered',
    Transhipment: 'transhipment',
    Completed: 'completed',
    Cancelled: 'cancelled',
    CounterRequired: 'counter_required'
} as const;

export type BookingBookingStateEnum = typeof BookingBookingStateEnum[keyof typeof BookingBookingStateEnum];
export const BookingCounterReasonEnum = {
    DangerousGoods: 'dangerous_goods',
    Toll: 'toll',
    Waste: 'waste',
    NotDeliveredYet: 'not_delivered_yet',
    Blocked: 'blocked',
    ContainerDataIncomplete: 'container_data_incomplete',
    ModuleClosed: 'module_closed',
    Error: 'error',
    ErrorOnUpdateLoadUnit: 'error_on_update_load_unit',
    LoadingUnitUnknown: 'loading_unit_unknown'
} as const;

export type BookingCounterReasonEnum = typeof BookingCounterReasonEnum[keyof typeof BookingCounterReasonEnum];
export const BookingFacilityTypeEnum = {
    Terminal: 'TERMINAL',
    IspsTerminal: 'ISPS_TERMINAL',
    Depot: 'DEPOT'
} as const;

export type BookingFacilityTypeEnum = typeof BookingFacilityTypeEnum[keyof typeof BookingFacilityTypeEnum];
export const BookingMissingFieldsEnum = {
    NetWeight: 'net_weight',
    Seals: 'seals',
    ContainerNumber: 'container_number'
} as const;

export type BookingMissingFieldsEnum = typeof BookingMissingFieldsEnum[keyof typeof BookingMissingFieldsEnum];

/**
 * 
 * @export
 * @interface BookingForForwarderTO
 */
export interface BookingForForwarderTO {
    /**
     * 
     * @type {number}
     * @memberof BookingForForwarderTO
     */
    'id'?: number;
    /**
     * state of a Booking
     * @type {string}
     * @memberof BookingForForwarderTO
     */
    'status'?: BookingForForwarderTOStatusEnum;
    /**
     * Type of a Task
     * @type {string}
     * @memberof BookingForForwarderTO
     */
    'type'?: BookingForForwarderTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingForForwarderTO
     */
    'containerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingForForwarderTO
     */
    'referenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingForForwarderTO
     */
    'containerType'?: string;
    /**
     * 
     * @type {TruckerInformationTO}
     * @memberof BookingForForwarderTO
     */
    'truckerInformation'?: TruckerInformationTO;
    /**
     * 
     * @type {string}
     * @memberof BookingForForwarderTO
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingForForwarderTO
     */
    'terminalName'?: string;
    /**
     * 
     * @type {TimeSlot}
     * @memberof BookingForForwarderTO
     */
    'timeslot'?: TimeSlot;
}

export const BookingForForwarderTOStatusEnum = {
    Active: 'active',
    TerminalReached: 'terminal_reached',
    RegistrationBlocked: 'registration_blocked',
    RegistrationRestricted: 'registration_restricted',
    Registered: 'registered',
    Transhipment: 'transhipment',
    Completed: 'completed',
    Cancelled: 'cancelled',
    CounterRequired: 'counter_required'
} as const;

export type BookingForForwarderTOStatusEnum = typeof BookingForForwarderTOStatusEnum[keyof typeof BookingForForwarderTOStatusEnum];
export const BookingForForwarderTOTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type BookingForForwarderTOTypeEnum = typeof BookingForForwarderTOTypeEnum[keyof typeof BookingForForwarderTOTypeEnum];

/**
 * Object use to request a booking
 * @export
 * @interface BookingRequest
 */
export interface BookingRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'containerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'relatedBookingsIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'licensePlate': string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof BookingRequest
     */
    'type': BookingRequestTypeEnum;
    /**
     * 
     * @type {TimeSlot}
     * @memberof BookingRequest
     */
    'timeSlot': TimeSlot;
    /**
     * 
     * @type {number}
     * @memberof BookingRequest
     */
    'terminalId': number;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'forwarder': string;
}

export const BookingRequestTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type BookingRequestTypeEnum = typeof BookingRequestTypeEnum[keyof typeof BookingRequestTypeEnum];

/**
 * Object which gives filtering parameters
 * @export
 * @interface BookingsFilterRequestTO
 */
export interface BookingsFilterRequestTO {
    /**
     * 
     * @type {number}
     * @memberof BookingsFilterRequestTO
     */
    'timeSlotId'?: number;
    /**
     * state of a Booking
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'bookingState'?: BookingsFilterRequestTOBookingStateEnum;
    /**
     * Type of a Task
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'bookingType'?: BookingsFilterRequestTOBookingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'truckerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'containerType'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'terminalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'containerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingsFilterRequestTO
     */
    'referenceNumber'?: string;
}

export const BookingsFilterRequestTOBookingStateEnum = {
    Active: 'active',
    TerminalReached: 'terminal_reached',
    RegistrationBlocked: 'registration_blocked',
    RegistrationRestricted: 'registration_restricted',
    Registered: 'registered',
    Transhipment: 'transhipment',
    Completed: 'completed',
    Cancelled: 'cancelled',
    CounterRequired: 'counter_required'
} as const;

export type BookingsFilterRequestTOBookingStateEnum = typeof BookingsFilterRequestTOBookingStateEnum[keyof typeof BookingsFilterRequestTOBookingStateEnum];
export const BookingsFilterRequestTOBookingTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type BookingsFilterRequestTOBookingTypeEnum = typeof BookingsFilterRequestTOBookingTypeEnum[keyof typeof BookingsFilterRequestTOBookingTypeEnum];

/**
 * Color Border
 * @export
 * @interface ColorBorder
 */
export interface ColorBorder {
    /**
     * 
     * @type {number}
     * @memberof ColorBorder
     */
    'minValue': number;
    /**
     * 
     * @type {number}
     * @memberof ColorBorder
     */
    'maxValue': number;
    /**
     * Statistic Color
     * @type {string}
     * @memberof ColorBorder
     */
    'color': ColorBorderColorEnum;
}

export const ColorBorderColorEnum = {
    Green: 'green',
    Yellow: 'yellow',
    Red: 'red',
    Blue: 'blue',
    Purple: 'purple'
} as const;

export type ColorBorderColorEnum = typeof ColorBorderColorEnum[keyof typeof ColorBorderColorEnum];

/**
 * Booking entries update object
 * @export
 * @interface ConfirmBookingEntries
 */
export interface ConfirmBookingEntries {
    /**
     * 
     * @type {Array<number>}
     * @memberof ConfirmBookingEntries
     */
    'bookingIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ConstantForwarderStatisticFloatValueResponseTO
 */
export interface ConstantForwarderStatisticFloatValueResponseTO {
    /**
     * 
     * @type {number}
     * @memberof ConstantForwarderStatisticFloatValueResponseTO
     */
    'floatValue': number;
    /**
     * 
     * @type {string}
     * @memberof ConstantForwarderStatisticFloatValueResponseTO
     */
    'unit': string;
    /**
     * 
     * @type {Array<ColorBorder>}
     * @memberof ConstantForwarderStatisticFloatValueResponseTO
     */
    'colorBorders': Array<ColorBorder>;
    /**
     * Forwarder Statistic Name
     * @type {string}
     * @memberof ConstantForwarderStatisticFloatValueResponseTO
     */
    'name': ConstantForwarderStatisticFloatValueResponseTONameEnum;
}

export const ConstantForwarderStatisticFloatValueResponseTONameEnum = {
    OpenOrders: 'open_orders',
    RegisteredTruckers: 'registered_truckers'
} as const;

export type ConstantForwarderStatisticFloatValueResponseTONameEnum = typeof ConstantForwarderStatisticFloatValueResponseTONameEnum[keyof typeof ConstantForwarderStatisticFloatValueResponseTONameEnum];

/**
 * request body object
 * @export
 * @interface ConstantForwarderStatisticValueRequestTO
 */
export interface ConstantForwarderStatisticValueRequestTO {
    /**
     * Forwarder Statistic Name
     * @type {string}
     * @memberof ConstantForwarderStatisticValueRequestTO
     */
    'name'?: ConstantForwarderStatisticValueRequestTONameEnum;
}

export const ConstantForwarderStatisticValueRequestTONameEnum = {
    OpenOrders: 'open_orders',
    RegisteredTruckers: 'registered_truckers'
} as const;

export type ConstantForwarderStatisticValueRequestTONameEnum = typeof ConstantForwarderStatisticValueRequestTONameEnum[keyof typeof ConstantForwarderStatisticValueRequestTONameEnum];

/**
 * container / loading unit
 * @export
 * @interface Container
 */
export interface Container {
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'containerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'isoCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'suggestedContainerNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'emptyWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'netWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'containerType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'containerLength'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    'hasDangerousGoods'?: boolean;
    /**
     * 
     * @type {Array<DangerousGoodsData>}
     * @memberof Container
     */
    'listDangerousGoodsData'?: Array<DangerousGoodsData>;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    'hasSeal'?: boolean;
    /**
     * 
     * @type {Array<SealData>}
     * @memberof Container
     */
    'listSealData'?: Array<SealData>;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    'hasToll'?: boolean;
    /**
     * 
     * @type {Array<TollData>}
     * @memberof Container
     */
    'listTollData'?: Array<TollData>;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    'hasWaste'?: boolean;
    /**
     * 
     * @type {Array<WasteData>}
     * @memberof Container
     */
    'listWasteData'?: Array<WasteData>;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'overhang'?: number;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'teu'?: number;
}
/**
 * 
 * @export
 * @interface ContainerBookingCreationRequestTO
 */
export interface ContainerBookingCreationRequestTO {
    /**
     * 
     * @type {number}
     * @memberof ContainerBookingCreationRequestTO
     */
    'containerBookingId': number;
    /**
     * 
     * @type {string}
     * @memberof ContainerBookingCreationRequestTO
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerBookingCreationRequestTO
     */
    'containerNumber'?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof ContainerBookingCreationRequestTO
     */
    'type': ContainerBookingCreationRequestTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContainerBookingCreationRequestTO
     */
    'isoCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerBookingCreationRequestTO
     */
    'customerId'?: string;
}

export const ContainerBookingCreationRequestTOTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type ContainerBookingCreationRequestTOTypeEnum = typeof ContainerBookingCreationRequestTOTypeEnum[keyof typeof ContainerBookingCreationRequestTOTypeEnum];

/**
 * User create Object
 * @export
 * @interface CreateForwarderUserTO
 */
export interface CreateForwarderUserTO {
    /**
     * 
     * @type {TrainOperatorUserTOLanguage}
     * @memberof CreateForwarderUserTO
     */
    'language'?: TrainOperatorUserTOLanguage;
    /**
     * 
     * @type {string}
     * @memberof CreateForwarderUserTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateForwarderUserTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateForwarderUserTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateForwarderUserTO
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateForwarderUserTO
     */
    'forwarderUserRole'?: CreateForwarderUserTOForwarderUserRoleEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateForwarderUserTO
     */
    'managedForwarderIDs'?: Array<number>;
}

export const CreateForwarderUserTOForwarderUserRoleEnum = {
    Write: 'FORWARDER_WRITE',
    Read: 'FORWARDER_READ'
} as const;

export type CreateForwarderUserTOForwarderUserRoleEnum = typeof CreateForwarderUserTOForwarderUserRoleEnum[keyof typeof CreateForwarderUserTOForwarderUserRoleEnum];

/**
 * User create Object
 * @export
 * @interface CreateTerminalOperatorUserTO
 */
export interface CreateTerminalOperatorUserTO {
    /**
     * 
     * @type {TrainOperatorUserTOLanguage}
     * @memberof CreateTerminalOperatorUserTO
     */
    'language'?: TrainOperatorUserTOLanguage;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminalOperatorUserTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminalOperatorUserTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminalOperatorUserTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminalOperatorUserTO
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTerminalOperatorUserTO
     */
    'terminalOperatorUserRole'?: CreateTerminalOperatorUserTOTerminalOperatorUserRoleEnum;
}

export const CreateTerminalOperatorUserTOTerminalOperatorUserRoleEnum = {
    Read: 'TERMINAL_OPERATIONS_READ',
    Write: 'TERMINAL_OPERATIONS_WRITE',
    Admin: 'TERMINAL_OPERATIONS_ADMIN'
} as const;

export type CreateTerminalOperatorUserTOTerminalOperatorUserRoleEnum = typeof CreateTerminalOperatorUserTOTerminalOperatorUserRoleEnum[keyof typeof CreateTerminalOperatorUserTOTerminalOperatorUserRoleEnum];

/**
 * User create Object
 * @export
 * @interface CreateTrainOperatorUserTO
 */
export interface CreateTrainOperatorUserTO {
    /**
     * 
     * @type {TrainOperatorUserTOLanguage}
     * @memberof CreateTrainOperatorUserTO
     */
    'language'?: TrainOperatorUserTOLanguage;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainOperatorUserTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainOperatorUserTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainOperatorUserTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainOperatorUserTO
     */
    'password'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTrainOperatorUserTO
     */
    'managedTrainOperatorIDs'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainOperatorUserTO
     */
    'trainOperatorUserRoleTO'?: CreateTrainOperatorUserTOTrainOperatorUserRoleTOEnum;
}

export const CreateTrainOperatorUserTOTrainOperatorUserRoleTOEnum = {
    Read: 'TRAIN_OPERATOR_READ',
    Write: 'TRAIN_OPERATOR_WRITE'
} as const;

export type CreateTrainOperatorUserTOTrainOperatorUserRoleTOEnum = typeof CreateTrainOperatorUserTOTrainOperatorUserRoleTOEnum[keyof typeof CreateTrainOperatorUserTOTrainOperatorUserRoleTOEnum];

/**
 * 
 * @export
 * @interface CustomerTO
 */
export interface CustomerTO {
    /**
     * 
     * @type {string}
     * @memberof CustomerTO
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTO
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerTO
     */
    'avisMandatory'?: boolean;
}
/**
 * DangerousGoodsData
 * @export
 * @interface DangerousGoodsData
 */
export interface DangerousGoodsData {
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsData
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsData
     */
    'lq': boolean;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsData
     */
    'uNNr'?: string;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsData
     */
    'clazz': string;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsData
     */
    'addedBy': DangerousGoodsDataAddedByEnum;
}

export const DangerousGoodsDataAddedByEnum = {
    Trucker: 'trucker',
    Terminal: 'terminal',
    ForwarderOperator: 'forwarder_operator'
} as const;

export type DangerousGoodsDataAddedByEnum = typeof DangerousGoodsDataAddedByEnum[keyof typeof DangerousGoodsDataAddedByEnum];

/**
 * delivery times object for loading unit
 * @export
 * @interface DeliveryTimes
 */
export interface DeliveryTimes {
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimes
     */
    'plannedTrainDeparture': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimes
     */
    'latestLoadingTime': string;
}
/**
 * 
 * @export
 * @interface DepotContainerBookingTO
 */
export interface DepotContainerBookingTO {
    /**
     * 
     * @type {number}
     * @memberof DepotContainerBookingTO
     */
    'containerBookingId': number;
    /**
     * 
     * @type {Container}
     * @memberof DepotContainerBookingTO
     */
    'container'?: Container;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    'module'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    'referenceNumber'?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    'type': DepotContainerBookingTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DepotContainerBookingTO
     */
    'customerId': string;
}

export const DepotContainerBookingTOTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type DepotContainerBookingTOTypeEnum = typeof DepotContainerBookingTOTypeEnum[keyof typeof DepotContainerBookingTOTypeEnum];

/**
 * 
 * @export
 * @interface DepotGetStatusMultiRequestTO
 */
export interface DepotGetStatusMultiRequestTO {
    /**
     * 
     * @type {Array<DepotGetStatusRequestTO>}
     * @memberof DepotGetStatusMultiRequestTO
     */
    'statusRequests': Array<DepotGetStatusRequestTO>;
}
/**
 * 
 * @export
 * @interface DepotGetStatusMultiResponseTO
 */
export interface DepotGetStatusMultiResponseTO {
    /**
     * 
     * @type {Array<DepotGetStatusResponseTO>}
     * @memberof DepotGetStatusMultiResponseTO
     */
    'responses': Array<DepotGetStatusResponseTO>;
}
/**
 * 
 * @export
 * @interface DepotGetStatusRequestTO
 */
export interface DepotGetStatusRequestTO {
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    'referenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    'containerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    'isoCode'?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    'type': DepotGetStatusRequestTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusRequestTO
     */
    'customerId'?: string;
}

export const DepotGetStatusRequestTOTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type DepotGetStatusRequestTOTypeEnum = typeof DepotGetStatusRequestTOTypeEnum[keyof typeof DepotGetStatusRequestTOTypeEnum];

/**
 * 
 * @export
 * @interface DepotGetStatusResponseTO
 */
export interface DepotGetStatusResponseTO {
    /**
     * 
     * @type {DepotGetStatusRequestTO}
     * @memberof DepotGetStatusResponseTO
     */
    'getStatusRequest': DepotGetStatusRequestTO;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    'validTo'?: string;
    /**
     * 
     * @type {Container}
     * @memberof DepotGetStatusResponseTO
     */
    'container'?: Container;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    'module'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotGetStatusResponseTO
     */
    'errorCode'?: DepotGetStatusResponseTOErrorCodeEnum;
}

export const DepotGetStatusResponseTOErrorCodeEnum = {
    RequestNotValid: 'REQUEST_NOT_VALID',
    CtrIdInvalid: 'CTR_ID_INVALID',
    ReferenceNumberMissing: 'REFERENCE_NUMBER_MISSING',
    IsoCodeMissing: 'ISO_CODE_MISSING',
    CustomerKeyMissing: 'CUSTOMER_KEY_MISSING',
    TurnInRefMissing: 'TURN_IN_REF_MISSING',
    CtrIdMissing: 'CTR_ID_MISSING',
    CtrIdAndIsoCodeMissing: 'CTR_ID_AND_ISO_CODE_MISSING',
    CtrIdAndIsoCodeSet: 'CTR_ID_AND_ISO_CODE_SET',
    CustomerNotFound: 'CUSTOMER_NOT_FOUND',
    DepotNotFound: 'DEPOT_NOT_FOUND',
    AvisNotFound: 'AVIS_NOT_FOUND',
    AvisNotForFacility: 'AVIS_NOT_FOR_FACILITY',
    AvisNotForCustomer: 'AVIS_NOT_FOR_CUSTOMER',
    AvisNotValid: 'AVIS_NOT_VALID',
    AvisNotForCtrid: 'AVIS_NOT_FOR_CTRID',
    ReleaseNotFound: 'RELEASE_NOT_FOUND',
    ReleaseNotOpen: 'RELEASE_NOT_OPEN',
    ReleaseNotForFacility: 'RELEASE_NOT_FOR_FACILITY',
    ReleaseNotForCustomer: 'RELEASE_NOT_FOR_CUSTOMER',
    ReleaseNotValid: 'RELEASE_NOT_VALID'
} as const;

export type DepotGetStatusResponseTOErrorCodeEnum = typeof DepotGetStatusResponseTOErrorCodeEnum[keyof typeof DepotGetStatusResponseTOErrorCodeEnum];

/**
 * Error Object
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message'?: string;
}
/**
 * custom message to be created
 * @export
 * @interface FacilityMessageTO
 */
export interface FacilityMessageTO {
    /**
     * 
     * @type {number}
     * @memberof FacilityMessageTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacilityMessageTO
     */
    'startingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityMessageTO
     */
    'endingDate'?: string;
    /**
     * 
     * @type {Array<FacilityMessageTranslationsTO>}
     * @memberof FacilityMessageTO
     */
    'translations'?: Array<FacilityMessageTranslationsTO>;
    /**
     * 
     * @type {string}
     * @memberof FacilityMessageTO
     */
    'severity'?: FacilityMessageTOSeverityEnum;
}

export const FacilityMessageTOSeverityEnum = {
    Info: 'INFO',
    Warning: 'WARNING',
    Error: 'ERROR'
} as const;

export type FacilityMessageTOSeverityEnum = typeof FacilityMessageTOSeverityEnum[keyof typeof FacilityMessageTOSeverityEnum];

/**
 * 
 * @export
 * @interface FacilityMessageTranslationsTO
 */
export interface FacilityMessageTranslationsTO {
    /**
     * 
     * @type {string}
     * @memberof FacilityMessageTranslationsTO
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityMessageTranslationsTO
     */
    'content'?: string;
}
/**
 * Forwarder
 * @export
 * @interface Forwarder
 */
export interface Forwarder {
    /**
     * 
     * @type {number}
     * @memberof Forwarder
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Forwarder
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Forwarder
     */
    'email': string;
}
/**
 * Forwarder approval
 * @export
 * @interface ForwarderApproval
 */
export interface ForwarderApproval {
    /**
     * 
     * @type {boolean}
     * @memberof ForwarderApproval
     */
    'approved'?: boolean;
}
/**
 * Specific user to be added
 * @export
 * @interface ForwarderUserTO
 */
export interface ForwarderUserTO {
    /**
     * 
     * @type {string}
     * @memberof ForwarderUserTO
     */
    'id'?: string;
    /**
     * 
     * @type {TrainOperatorUserTOLanguage}
     * @memberof ForwarderUserTO
     */
    'language'?: TrainOperatorUserTOLanguage;
    /**
     * 
     * @type {string}
     * @memberof ForwarderUserTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ForwarderUserTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ForwarderUserTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ForwarderUserTO
     */
    'forwarderUserRole'?: ForwarderUserTOForwarderUserRoleEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ForwarderUserTO
     */
    'managedForwarderIDs'?: Array<number>;
}

export const ForwarderUserTOForwarderUserRoleEnum = {
    Write: 'FORWARDER_WRITE',
    Read: 'FORWARDER_READ'
} as const;

export type ForwarderUserTOForwarderUserRoleEnum = typeof ForwarderUserTOForwarderUserRoleEnum[keyof typeof ForwarderUserTOForwarderUserRoleEnum];

/**
 * Csv file containing container data to be parsed
 * @export
 * @interface GetStatusDownloadCSVRequestTO
 */
export interface GetStatusDownloadCSVRequestTO {
    /**
     * 
     * @type {Array<GetStatusRequestResponseTO>}
     * @memberof GetStatusDownloadCSVRequestTO
     */
    'getStatusRequestResponses'?: Array<GetStatusRequestResponseTO>;
    /**
     * 
     * @type {number}
     * @memberof GetStatusDownloadCSVRequestTO
     */
    'terminalId'?: number;
}
/**
 * Ref & containerNumber as an StatusRequest object
 * @export
 * @interface GetStatusRequest
 */
export interface GetStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequest
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequest
     */
    'containerNumber'?: string;
    /**
     * Type of a Task
     * @type {string}
     * @memberof GetStatusRequest
     */
    'type': GetStatusRequestTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetStatusRequest
     */
    'terminalId'?: number;
}

export const GetStatusRequestTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type GetStatusRequestTypeEnum = typeof GetStatusRequestTypeEnum[keyof typeof GetStatusRequestTypeEnum];

/**
 * 
 * @export
 * @interface GetStatusRequestResponseTO
 */
export interface GetStatusRequestResponseTO {
    /**
     * 
     * @type {GetStatusRequest}
     * @memberof GetStatusRequestResponseTO
     */
    'getStatusRequest'?: GetStatusRequest;
    /**
     * 
     * @type {GetStatusResponse}
     * @memberof GetStatusRequestResponseTO
     */
    'getStatusResponse'?: GetStatusResponse;
    /**
     * 
     * @type {string}
     * @memberof GetStatusRequestResponseTO
     */
    'errorCode'?: string;
}
/**
 * GetStatus Response
 * @export
 * @interface GetStatusResponse
 */
export interface GetStatusResponse {
    /**
     * 
     * @type {Container}
     * @memberof GetStatusResponse
     */
    'container'?: Container;
    /**
     * 
     * @type {Array<TimeSlot>}
     * @memberof GetStatusResponse
     */
    'timeSlots'?: Array<TimeSlot>;
    /**
     * 
     * @type {string}
     * @memberof GetStatusResponse
     */
    'referenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusResponse
     */
    'bookingTerminalStatus'?: string;
}
/**
 * 
 * @export
 * @interface IspsChangeApprovalRequestTO
 */
export interface IspsChangeApprovalRequestTO {
    /**
     * 
     * @type {boolean}
     * @memberof IspsChangeApprovalRequestTO
     */
    'approved'?: boolean;
}
/**
 * 
 * @export
 * @interface IspsChangeSupervisorTO
 */
export interface IspsChangeSupervisorTO {
    /**
     * 
     * @type {string}
     * @memberof IspsChangeSupervisorTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsChangeSupervisorTO
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface IspsLicenseKeyResponseTO
 */
export interface IspsLicenseKeyResponseTO {
    /**
     * 
     * @type {boolean}
     * @memberof IspsLicenseKeyResponseTO
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface IspsLicenseTO
 */
export interface IspsLicenseTO {
    /**
     * 
     * @type {number}
     * @memberof IspsLicenseTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IspsLicenseTO
     */
    'licenseKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IspsLicenseTO
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface IspsTruckerChangeRequestTO
 */
export interface IspsTruckerChangeRequestTO {
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeRequestTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeRequestTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeRequestTO
     */
    'documentStatus'?: IspsTruckerChangeRequestTODocumentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeRequestTO
     */
    'terrorStatus'?: IspsTruckerChangeRequestTOTerrorStatusEnum;
}

export const IspsTruckerChangeRequestTODocumentStatusEnum = {
    Accepted: 'ACCEPTED',
    Pending: 'PENDING',
    Denied: 'DENIED',
    Warn: 'WARN',
    DeniedPreliminary: 'DENIED_PRELIMINARY',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED',
    Duplicate: 'DUPLICATE',
    IdentityNotMatchingOnReverification: 'IDENTITY_NOT_MATCHING_ON_REVERIFICATION',
    Failed: 'FAILED'
} as const;

export type IspsTruckerChangeRequestTODocumentStatusEnum = typeof IspsTruckerChangeRequestTODocumentStatusEnum[keyof typeof IspsTruckerChangeRequestTODocumentStatusEnum];
export const IspsTruckerChangeRequestTOTerrorStatusEnum = {
    Ok: 'OK',
    Warning: 'WARNING',
    RedAlert: 'RED_ALERT',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED'
} as const;

export type IspsTruckerChangeRequestTOTerrorStatusEnum = typeof IspsTruckerChangeRequestTOTerrorStatusEnum[keyof typeof IspsTruckerChangeRequestTOTerrorStatusEnum];

/**
 * 
 * @export
 * @interface IspsTruckerChangeTO
 */
export interface IspsTruckerChangeTO {
    /**
     * 
     * @type {number}
     * @memberof IspsTruckerChangeTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeTO
     */
    'documentStatus'?: IspsTruckerChangeTODocumentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeTO
     */
    'terrorStatus'?: IspsTruckerChangeTOTerrorStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeTO
     */
    'state'?: IspsTruckerChangeTOStateEnum;
    /**
     * 
     * @type {WebappUser}
     * @memberof IspsTruckerChangeTO
     */
    'creator'?: WebappUser;
    /**
     * 
     * @type {IspsChangeSupervisorTO}
     * @memberof IspsTruckerChangeTO
     */
    'supervisor'?: IspsChangeSupervisorTO;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeTO
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IspsTruckerChangeTO
     */
    'reset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IspsTruckerChangeTO
     */
    'delete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerChangeTO
     */
    'completedAt'?: string;
}

export const IspsTruckerChangeTODocumentStatusEnum = {
    Accepted: 'ACCEPTED',
    Pending: 'PENDING',
    Denied: 'DENIED',
    Warn: 'WARN',
    DeniedPreliminary: 'DENIED_PRELIMINARY',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED',
    Duplicate: 'DUPLICATE',
    IdentityNotMatchingOnReverification: 'IDENTITY_NOT_MATCHING_ON_REVERIFICATION',
    Failed: 'FAILED'
} as const;

export type IspsTruckerChangeTODocumentStatusEnum = typeof IspsTruckerChangeTODocumentStatusEnum[keyof typeof IspsTruckerChangeTODocumentStatusEnum];
export const IspsTruckerChangeTOTerrorStatusEnum = {
    Ok: 'OK',
    Warning: 'WARNING',
    RedAlert: 'RED_ALERT',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED'
} as const;

export type IspsTruckerChangeTOTerrorStatusEnum = typeof IspsTruckerChangeTOTerrorStatusEnum[keyof typeof IspsTruckerChangeTOTerrorStatusEnum];
export const IspsTruckerChangeTOStateEnum = {
    Approved: 'APPROVED',
    Denied: 'DENIED',
    Pending: 'PENDING',
    Cancelled: 'CANCELLED',
    System: 'SYSTEM',
    Trucker: 'TRUCKER'
} as const;

export type IspsTruckerChangeTOStateEnum = typeof IspsTruckerChangeTOStateEnum[keyof typeof IspsTruckerChangeTOStateEnum];

/**
 * 
 * @export
 * @interface IspsTruckerTO
 */
export interface IspsTruckerTO {
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'loginType'?: string;
    /**
     * 
     * @type {Forwarder}
     * @memberof IspsTruckerTO
     */
    'forwarder'?: Forwarder;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'otherForwarder'?: string;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {IspsLicenseTO}
     * @memberof IspsTruckerTO
     */
    'ispsLicense'?: IspsLicenseTO;
    /**
     * 
     * @type {Array<TruckerIdentificationTO>}
     * @memberof IspsTruckerTO
     */
    'truckerIdentification'?: Array<TruckerIdentificationTO>;
    /**
     * 
     * @type {Array<IspsTruckerChangeTO>}
     * @memberof IspsTruckerTO
     */
    'changes'?: Array<IspsTruckerChangeTO>;
    /**
     * 
     * @type {string}
     * @memberof IspsTruckerTO
     */
    'deletedDate'?: string;
}
/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'nano'?: number;
}
/**
 * Old and new Trucker Ids Entry
 * @export
 * @interface MigrateISPSLicenseRequestTO
 */
export interface MigrateISPSLicenseRequestTO {
    /**
     * 
     * @type {string}
     * @memberof MigrateISPSLicenseRequestTO
     */
    'oldTruckerId': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateISPSLicenseRequestTO
     */
    'newTruckerId': string;
}
/**
 * 
 * @export
 * @interface MobileTruckerIdentificationDuplicateAccountTO
 */
export interface MobileTruckerIdentificationDuplicateAccountTO {
    /**
     * 
     * @type {string}
     * @memberof MobileTruckerIdentificationDuplicateAccountTO
     */
    'maskedLoginInfo'?: string;
}
/**
 * 
 * @export
 * @interface MobileTruckerIdentificationTO
 */
export interface MobileTruckerIdentificationTO {
    /**
     * 
     * @type {string}
     * @memberof MobileTruckerIdentificationTO
     */
    'identityVerified'?: MobileTruckerIdentificationTOIdentityVerifiedEnum;
    /**
     * 
     * @type {string}
     * @memberof MobileTruckerIdentificationTO
     */
    'deviceVerified'?: MobileTruckerIdentificationTODeviceVerifiedEnum;
    /**
     * 
     * @type {Array<MobileTruckerIdentificationDuplicateAccountTO>}
     * @memberof MobileTruckerIdentificationTO
     */
    'duplicateAccounts'?: Array<MobileTruckerIdentificationDuplicateAccountTO>;
}

export const MobileTruckerIdentificationTOIdentityVerifiedEnum = {
    Verified: 'VERIFIED',
    NotVerified: 'NOT_VERIFIED',
    Pending: 'PENDING',
    DeniedPreliminary: 'DENIED_PRELIMINARY',
    Denied: 'DENIED',
    Duplicate: 'DUPLICATE',
    IdentityNotMatchingOnReverification: 'IDENTITY_NOT_MATCHING_ON_REVERIFICATION',
    Failed: 'FAILED'
} as const;

export type MobileTruckerIdentificationTOIdentityVerifiedEnum = typeof MobileTruckerIdentificationTOIdentityVerifiedEnum[keyof typeof MobileTruckerIdentificationTOIdentityVerifiedEnum];
export const MobileTruckerIdentificationTODeviceVerifiedEnum = {
    Verified: 'VERIFIED',
    NotVerified: 'NOT_VERIFIED'
} as const;

export type MobileTruckerIdentificationTODeviceVerifiedEnum = typeof MobileTruckerIdentificationTODeviceVerifiedEnum[keyof typeof MobileTruckerIdentificationTODeviceVerifiedEnum];

/**
 * acceptance deadline to be created
 * @export
 * @interface OneToOneRestriction
 */
export interface OneToOneRestriction {
    /**
     * 
     * @type {number}
     * @memberof OneToOneRestriction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OneToOneRestriction
     */
    'restrictionStart': string;
    /**
     * 
     * @type {string}
     * @memberof OneToOneRestriction
     */
    'restrictionEnd': string;
    /**
     * 
     * @type {number}
     * @memberof OneToOneRestriction
     */
    'terminalId': number;
}
/**
 * Response for parseGetStatusFile request containing parsed requests and information about errors
 * @export
 * @interface ParseGetStatusFileResponse
 */
export interface ParseGetStatusFileResponse {
    /**
     * parsed getStatusRequest objects from csv file, null on parsing errors
     * @type {Array<GetStatusRequest>}
     * @memberof ParseGetStatusFileResponse
     */
    'getStatusRequests'?: Array<GetStatusRequest>;
    /**
     * if in header row column headers are missing, they are listed here
     * @type {Array<string>}
     * @memberof ParseGetStatusFileResponse
     */
    'missingColumns'?: Array<ParseGetStatusFileResponseMissingColumnsEnum>;
    /**
     * row in the csv where the error was found. -1 means csv header row
     * @type {number}
     * @memberof ParseGetStatusFileResponse
     */
    'corruptRow'?: number;
    /**
     * Errors that can happen when parsing a csv
     * @type {string}
     * @memberof ParseGetStatusFileResponse
     */
    'errorType'?: ParseGetStatusFileResponseErrorTypeEnum;
}

export const ParseGetStatusFileResponseMissingColumnsEnum = {
    ReferenceNumber: 'reference_number',
    TerminalId: 'terminal_id',
    ContainerNumber: 'container_number',
    Type: 'type'
} as const;

export type ParseGetStatusFileResponseMissingColumnsEnum = typeof ParseGetStatusFileResponseMissingColumnsEnum[keyof typeof ParseGetStatusFileResponseMissingColumnsEnum];
export const ParseGetStatusFileResponseErrorTypeEnum = {
    ColumnMissing: 'column_missing',
    RowIncomplete: 'row_incomplete',
    DatatypeMismatch: 'datatype_mismatch',
    BookingTypeNotParseable: 'booking_type_not_parseable'
} as const;

export type ParseGetStatusFileResponseErrorTypeEnum = typeof ParseGetStatusFileResponseErrorTypeEnum[keyof typeof ParseGetStatusFileResponseErrorTypeEnum];

/**
 * pickup times object for loading unit
 * @export
 * @interface PickupTimes
 */
export interface PickupTimes {
    /**
     * 
     * @type {string}
     * @memberof PickupTimes
     */
    'plannedTrainArrival': string;
    /**
     * 
     * @type {string}
     * @memberof PickupTimes
     */
    'availabilityTime': string;
}
/**
 * request body object
 * @export
 * @interface RangedTerminalStatisticsValueRequest
 */
export interface RangedTerminalStatisticsValueRequest {
    /**
     * 
     * @type {string}
     * @memberof RangedTerminalStatisticsValueRequest
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof RangedTerminalStatisticsValueRequest
     */
    'end': string;
    /**
     * Statistic Name
     * @type {string}
     * @memberof RangedTerminalStatisticsValueRequest
     */
    'name': RangedTerminalStatisticsValueRequestNameEnum;
}

export const RangedTerminalStatisticsValueRequestNameEnum = {
    ActiveBookings: 'active_bookings',
    CompletedBookings: 'completed_bookings',
    CanceledBookings: 'canceled_bookings',
    TimeRegisterSuccessTranshipment: 'time_register_success_transhipment'
} as const;

export type RangedTerminalStatisticsValueRequestNameEnum = typeof RangedTerminalStatisticsValueRequestNameEnum[keyof typeof RangedTerminalStatisticsValueRequestNameEnum];

/**
 * Ranged terminal float value response
 * @export
 * @interface RangedTerminalStatisticsValueResponse
 */
export interface RangedTerminalStatisticsValueResponse {
    /**
     * 
     * @type {string}
     * @memberof RangedTerminalStatisticsValueResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof RangedTerminalStatisticsValueResponse
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof RangedTerminalStatisticsValueResponse
     */
    'floatValue': number;
    /**
     * 
     * @type {string}
     * @memberof RangedTerminalStatisticsValueResponse
     */
    'unit': string;
    /**
     * 
     * @type {Array<ColorBorder>}
     * @memberof RangedTerminalStatisticsValueResponse
     */
    'colorBorders': Array<ColorBorder>;
    /**
     * Statistic Name
     * @type {string}
     * @memberof RangedTerminalStatisticsValueResponse
     */
    'name': RangedTerminalStatisticsValueResponseNameEnum;
}

export const RangedTerminalStatisticsValueResponseNameEnum = {
    ActiveBookings: 'active_bookings',
    CompletedBookings: 'completed_bookings',
    CanceledBookings: 'canceled_bookings',
    TimeRegisterSuccessTranshipment: 'time_register_success_transhipment'
} as const;

export type RangedTerminalStatisticsValueResponseNameEnum = typeof RangedTerminalStatisticsValueResponseNameEnum[keyof typeof RangedTerminalStatisticsValueResponseNameEnum];

/**
 * Reset password body
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'email': string;
}
/**
 * SealData
 * @export
 * @interface SealData
 */
export interface SealData {
    /**
     * 
     * @type {number}
     * @memberof SealData
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SealData
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof SealData
     */
    'addedBy': SealDataAddedByEnum;
}

export const SealDataAddedByEnum = {
    Trucker: 'trucker',
    Terminal: 'terminal',
    ForwarderOperator: 'forwarder_operator'
} as const;

export type SealDataAddedByEnum = typeof SealDataAddedByEnum[keyof typeof SealDataAddedByEnum];

/**
 * Terminal
 * @export
 * @interface Terminal
 */
export interface Terminal {
    /**
     * 
     * @type {number}
     * @memberof Terminal
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Terminal
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Terminal
     */
    'logoUrl': string;
    /**
     * 
     * @type {number}
     * @memberof Terminal
     */
    'latPosition': number;
    /**
     * 
     * @type {number}
     * @memberof Terminal
     */
    'lonPosition': number;
    /**
     * 
     * @type {Array<TerminalModule>}
     * @memberof Terminal
     */
    'modules'?: Array<TerminalModule>;
    /**
     * 
     * @type {Array<TerminalOpeningHour>}
     * @memberof Terminal
     */
    'openingHours'?: Array<TerminalOpeningHour>;
}
/**
 * Terminal module
 * @export
 * @interface TerminalModule
 */
export interface TerminalModule {
    /**
     * 
     * @type {string}
     * @memberof TerminalModule
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TerminalModule
     */
    'moduleName': string;
    /**
     * 
     * @type {boolean}
     * @memberof TerminalModule
     */
    'opened'?: boolean;
}
/**
 * Terminal opening hour
 * @export
 * @interface TerminalOpeningHour
 */
export interface TerminalOpeningHour {
    /**
     * 
     * @type {number}
     * @memberof TerminalOpeningHour
     */
    'id': number;
    /**
     * 
     * @type {LocalTime}
     * @memberof TerminalOpeningHour
     */
    'openedFrom': LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof TerminalOpeningHour
     */
    'closedFrom': LocalTime;
    /**
     * 
     * @type {string}
     * @memberof TerminalOpeningHour
     */
    'openingHourDay': TerminalOpeningHourOpeningHourDayEnum;
    /**
     * type of Facility opening hour
     * @type {string}
     * @memberof TerminalOpeningHour
     */
    'openingHourType': TerminalOpeningHourOpeningHourTypeEnum;
}

export const TerminalOpeningHourOpeningHourDayEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type TerminalOpeningHourOpeningHourDayEnum = typeof TerminalOpeningHourOpeningHourDayEnum[keyof typeof TerminalOpeningHourOpeningHourDayEnum];
export const TerminalOpeningHourOpeningHourTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery',
    Counter: 'counter'
} as const;

export type TerminalOpeningHourOpeningHourTypeEnum = typeof TerminalOpeningHourOpeningHourTypeEnum[keyof typeof TerminalOpeningHourOpeningHourTypeEnum];

/**
 * 
 * @export
 * @interface TerminalOperatorBookingTO
 */
export interface TerminalOperatorBookingTO {
    /**
     * 
     * @type {number}
     * @memberof TerminalOperatorBookingTO
     */
    'id'?: number;
    /**
     * Type of a Task
     * @type {string}
     * @memberof TerminalOperatorBookingTO
     */
    'type'?: TerminalOperatorBookingTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorBookingTO
     */
    'referenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorBookingTO
     */
    'containerNumber'?: string;
    /**
     * 
     * @type {TimeSlot}
     * @memberof TerminalOperatorBookingTO
     */
    'timeSlot'?: TimeSlot;
    /**
     * state of a Booking
     * @type {string}
     * @memberof TerminalOperatorBookingTO
     */
    'state'?: TerminalOperatorBookingTOStateEnum;
}

export const TerminalOperatorBookingTOTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type TerminalOperatorBookingTOTypeEnum = typeof TerminalOperatorBookingTOTypeEnum[keyof typeof TerminalOperatorBookingTOTypeEnum];
export const TerminalOperatorBookingTOStateEnum = {
    Active: 'active',
    TerminalReached: 'terminal_reached',
    RegistrationBlocked: 'registration_blocked',
    RegistrationRestricted: 'registration_restricted',
    Registered: 'registered',
    Transhipment: 'transhipment',
    Completed: 'completed',
    Cancelled: 'cancelled',
    CounterRequired: 'counter_required'
} as const;

export type TerminalOperatorBookingTOStateEnum = typeof TerminalOperatorBookingTOStateEnum[keyof typeof TerminalOperatorBookingTOStateEnum];

/**
 * 
 * @export
 * @interface TerminalOperatorTruckerTO
 */
export interface TerminalOperatorTruckerTO {
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorTruckerTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorTruckerTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorTruckerTO
     */
    'id'?: string;
    /**
     * Trucker blocked state
     * @type {string}
     * @memberof TerminalOperatorTruckerTO
     */
    'blockedState'?: TerminalOperatorTruckerTOBlockedStateEnum;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorTruckerTO
     */
    'blockedReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorTruckerTO
     */
    'blockedDate'?: string;
}

export const TerminalOperatorTruckerTOBlockedStateEnum = {
    Blocked: 'BLOCKED',
    NotBlocked: 'NOT_BLOCKED'
} as const;

export type TerminalOperatorTruckerTOBlockedStateEnum = typeof TerminalOperatorTruckerTOBlockedStateEnum[keyof typeof TerminalOperatorTruckerTOBlockedStateEnum];

/**
 * Specific user to be added
 * @export
 * @interface TerminalOperatorUserTO
 */
export interface TerminalOperatorUserTO {
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorUserTO
     */
    'id'?: string;
    /**
     * 
     * @type {TrainOperatorUserTOLanguage}
     * @memberof TerminalOperatorUserTO
     */
    'language'?: TrainOperatorUserTOLanguage;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorUserTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorUserTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorUserTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TerminalOperatorUserTO
     */
    'terminalOperatorUserRole'?: TerminalOperatorUserTOTerminalOperatorUserRoleEnum;
}

export const TerminalOperatorUserTOTerminalOperatorUserRoleEnum = {
    Read: 'TERMINAL_OPERATIONS_READ',
    Write: 'TERMINAL_OPERATIONS_WRITE',
    Admin: 'TERMINAL_OPERATIONS_ADMIN'
} as const;

export type TerminalOperatorUserTOTerminalOperatorUserRoleEnum = typeof TerminalOperatorUserTOTerminalOperatorUserRoleEnum[keyof typeof TerminalOperatorUserTOTerminalOperatorUserRoleEnum];

/**
 * Terminal reduced object
 * @export
 * @interface TerminalReduced
 */
export interface TerminalReduced {
    /**
     * 
     * @type {number}
     * @memberof TerminalReduced
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TerminalReduced
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TerminalReduced
     */
    'logoUrl': string;
    /**
     * 
     * @type {number}
     * @memberof TerminalReduced
     */
    'latPosition': number;
    /**
     * 
     * @type {number}
     * @memberof TerminalReduced
     */
    'lonPosition': number;
}
/**
 * request body object
 * @export
 * @interface TimeSeriesRequest
 */
export interface TimeSeriesRequest {
    /**
     * Statistic Name
     * @type {string}
     * @memberof TimeSeriesRequest
     */
    'name': TimeSeriesRequestNameEnum;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesRequest
     */
    'seriesStart': string;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesRequest
     */
    'seriesEnd': string;
    /**
     * Duration type on which to divide intervals
     * @type {string}
     * @memberof TimeSeriesRequest
     */
    'duration': TimeSeriesRequestDurationEnum;
}

export const TimeSeriesRequestNameEnum = {
    ActiveBookings: 'active_bookings',
    CompletedBookings: 'completed_bookings',
    CanceledBookings: 'canceled_bookings',
    TimeRegisterSuccessTranshipment: 'time_register_success_transhipment'
} as const;

export type TimeSeriesRequestNameEnum = typeof TimeSeriesRequestNameEnum[keyof typeof TimeSeriesRequestNameEnum];
export const TimeSeriesRequestDurationEnum = {
    Hour: 'hour',
    Day: 'day',
    Week: 'week'
} as const;

export type TimeSeriesRequestDurationEnum = typeof TimeSeriesRequestDurationEnum[keyof typeof TimeSeriesRequestDurationEnum];

/**
 * Time series response
 * @export
 * @interface TimeSeriesResponse
 */
export interface TimeSeriesResponse {
    /**
     * Statistic Name
     * @type {string}
     * @memberof TimeSeriesResponse
     */
    'name': TimeSeriesResponseNameEnum;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesResponse
     */
    'seriesStart': string;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesResponse
     */
    'seriesEnd': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TimeSeriesResponse
     */
    'xAxis': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TimeSeriesResponse
     */
    'yAxis': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesResponse
     */
    'unit'?: string;
    /**
     * 
     * @type {Array<ColorBorder>}
     * @memberof TimeSeriesResponse
     */
    'colorBorders'?: Array<ColorBorder>;
}

export const TimeSeriesResponseNameEnum = {
    ActiveBookings: 'active_bookings',
    CompletedBookings: 'completed_bookings',
    CanceledBookings: 'canceled_bookings',
    TimeRegisterSuccessTranshipment: 'time_register_success_transhipment'
} as const;

export type TimeSeriesResponseNameEnum = typeof TimeSeriesResponseNameEnum[keyof typeof TimeSeriesResponseNameEnum];

/**
 * time slot for a booking
 * @export
 * @interface TimeSlot
 */
export interface TimeSlot {
    /**
     * 
     * @type {number}
     * @memberof TimeSlot
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    'slotEnd': string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    'slotBegin': string;
    /**
     * category of a time slot
     * @type {string}
     * @memberof TimeSlot
     */
    'category'?: TimeSlotCategoryEnum;
}

export const TimeSlotCategoryEnum = {
    Green: 'green',
    Yellow: 'yellow',
    Red: 'red',
    NotSet: 'not_set'
} as const;

export type TimeSlotCategoryEnum = typeof TimeSlotCategoryEnum[keyof typeof TimeSlotCategoryEnum];

/**
 * TollData
 * @export
 * @interface TollData
 */
export interface TollData {
    /**
     * 
     * @type {number}
     * @memberof TollData
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TollData
     */
    'documentType': string;
    /**
     * 
     * @type {string}
     * @memberof TollData
     */
    'documentNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TollData
     */
    'addedBy': TollDataAddedByEnum;
    /**
     * 
     * @type {string}
     * @memberof TollData
     */
    'parsedDocumentNumber'?: string;
}

export const TollDataAddedByEnum = {
    Trucker: 'trucker',
    Terminal: 'terminal',
    ForwarderOperator: 'forwarder_operator'
} as const;

export type TollDataAddedByEnum = typeof TollDataAddedByEnum[keyof typeof TollDataAddedByEnum];

/**
 * 
 * @export
 * @interface TourCreationRequestTO
 */
export interface TourCreationRequestTO {
    /**
     * 
     * @type {number}
     * @memberof TourCreationRequestTO
     */
    'tourId'?: number;
    /**
     * 
     * @type {TimeSlot}
     * @memberof TourCreationRequestTO
     */
    'timeSlot': TimeSlot;
    /**
     * 
     * @type {string}
     * @memberof TourCreationRequestTO
     */
    'licensePlate': string;
    /**
     * 
     * @type {string}
     * @memberof TourCreationRequestTO
     */
    'forwarderName': string;
    /**
     * 
     * @type {Array<ContainerBookingCreationRequestTO>}
     * @memberof TourCreationRequestTO
     */
    'statusRequests': Array<ContainerBookingCreationRequestTO>;
}
/**
 * 
 * @export
 * @interface TourTO
 */
export interface TourTO {
    /**
     * 
     * @type {number}
     * @memberof TourTO
     */
    'tourId'?: number;
    /**
     * 
     * @type {Array<DepotContainerBookingTO>}
     * @memberof TourTO
     */
    'containerBookings': Array<DepotContainerBookingTO>;
}
/**
 * Train
 * @export
 * @interface Train
 */
export interface Train {
    /**
     * 
     * @type {number}
     * @memberof Train
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Train
     */
    'trainNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Train
     */
    'trainId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Train
     */
    'trainOperator'?: string;
    /**
     * 
     * @type {string}
     * @memberof Train
     */
    'trainDate'?: string;
    /**
     * 
     * @type {PickupTimes}
     * @memberof Train
     */
    'pickupTimes'?: PickupTimes;
    /**
     * 
     * @type {DeliveryTimes}
     * @memberof Train
     */
    'deliveryTimes'?: DeliveryTimes;
    /**
     * 
     * @type {TrainLocationTO}
     * @memberof Train
     */
    'origin'?: TrainLocationTO;
    /**
     * 
     * @type {TrainLocationTO}
     * @memberof Train
     */
    'destination'?: TrainLocationTO;
    /**
     * type if train update is for incoming or outgoing train
     * @type {string}
     * @memberof Train
     */
    'trainType'?: TrainTrainTypeEnum;
}

export const TrainTrainTypeEnum = {
    Import: 'import',
    Export: 'export'
} as const;

export type TrainTrainTypeEnum = typeof TrainTrainTypeEnum[keyof typeof TrainTrainTypeEnum];

/**
 * 
 * @export
 * @interface TrainLocationTO
 */
export interface TrainLocationTO {
    /**
     * 
     * @type {string}
     * @memberof TrainLocationTO
     */
    'locationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainLocationTO
     */
    'locationAbbreviation'?: string;
}
/**
 * Train Operator to be shown for selection
 * @export
 * @interface TrainOperator
 */
export interface TrainOperator {
    /**
     * 
     * @type {number}
     * @memberof TrainOperator
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TrainOperator
     */
    'name': string;
}
/**
 * Delivery/Pickup Booking of Container to be shown in twa for train operator
 * @export
 * @interface TrainOperatorBooking
 */
export interface TrainOperatorBooking {
    /**
     * 
     * @type {number}
     * @memberof TrainOperatorBooking
     */
    'bookingId': number;
    /**
     * Type of a Task
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'type': TrainOperatorBookingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'referenceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof TrainOperatorBooking
     */
    'terminalId': number;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'containerNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainOperatorBooking
     */
    'emptyWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainOperatorBooking
     */
    'netWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainOperatorBooking
     */
    'containerLength'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'forwarder'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'transhipmentTaskConfirmation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'trainNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'latestLoadingTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'slotBegin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorBooking
     */
    'slotEnd'?: string;
}

export const TrainOperatorBookingTypeEnum = {
    Pickup: 'pickup',
    Delivery: 'delivery'
} as const;

export type TrainOperatorBookingTypeEnum = typeof TrainOperatorBookingTypeEnum[keyof typeof TrainOperatorBookingTypeEnum];

/**
 * Specific user to be added
 * @export
 * @interface TrainOperatorUserTO
 */
export interface TrainOperatorUserTO {
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTO
     */
    'id'?: string;
    /**
     * 
     * @type {TrainOperatorUserTOLanguage}
     * @memberof TrainOperatorUserTO
     */
    'language'?: TrainOperatorUserTOLanguage;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTO
     */
    'email': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrainOperatorUserTO
     */
    'managedTrainOperatorIDs'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTO
     */
    'trainOperatorUserRoleTO'?: TrainOperatorUserTOTrainOperatorUserRoleTOEnum;
}

export const TrainOperatorUserTOTrainOperatorUserRoleTOEnum = {
    Read: 'TRAIN_OPERATOR_READ',
    Write: 'TRAIN_OPERATOR_WRITE'
} as const;

export type TrainOperatorUserTOTrainOperatorUserRoleTOEnum = typeof TrainOperatorUserTOTrainOperatorUserRoleTOEnum[keyof typeof TrainOperatorUserTOTrainOperatorUserRoleTOEnum];

/**
 * 
 * @export
 * @interface TrainOperatorUserTOLanguage
 */
export interface TrainOperatorUserTOLanguage {
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'variant'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'script'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof TrainOperatorUserTOLanguage
     */
    'unicodeLocaleAttributes'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof TrainOperatorUserTOLanguage
     */
    'unicodeLocaleKeys'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'displayLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'displayScript'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'displayCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'displayVariant'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof TrainOperatorUserTOLanguage
     */
    'extensionKeys'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'iso3Language'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainOperatorUserTOLanguage
     */
    'iso3Country'?: string;
}
/**
 * blocklist entry to be created
 * @export
 * @interface TruckerBlocklistRequestTO
 */
export interface TruckerBlocklistRequestTO {
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistRequestTO
     */
    'truckerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistRequestTO
     */
    'blockedReason'?: string;
}
/**
 * 
 * @export
 * @interface TruckerBlocklistResponseTO
 */
export interface TruckerBlocklistResponseTO {
    /**
     * 
     * @type {number}
     * @memberof TruckerBlocklistResponseTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistResponseTO
     */
    'truckerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistResponseTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistResponseTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TruckerBlocklistResponseTO
     */
    'facilityId'?: number;
    /**
     * 
     * @type {Forwarder}
     * @memberof TruckerBlocklistResponseTO
     */
    'forwarder'?: Forwarder;
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistResponseTO
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistResponseTO
     */
    'blockedReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerBlocklistResponseTO
     */
    'blockedDate'?: string;
}
/**
 * 
 * @export
 * @interface TruckerForForwarderTO
 */
export interface TruckerForForwarderTO {
    /**
     * 
     * @type {string}
     * @memberof TruckerForForwarderTO
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerForForwarderTO
     */
    'registeredDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerForForwarderTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerForForwarderTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerForForwarderTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerForForwarderTO
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {ForwarderApproval}
     * @memberof TruckerForForwarderTO
     */
    'forwarderApproval'?: ForwarderApproval;
    /**
     * 
     * @type {number}
     * @memberof TruckerForForwarderTO
     */
    'completedBookingsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TruckerForForwarderTO
     */
    'lastBookingCompletedDate'?: string;
}
/**
 * 
 * @export
 * @interface TruckerIdentificationTO
 */
export interface TruckerIdentificationTO {
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'identificationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'issuer'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'cisFileUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'create_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'verified_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'terrorStatus'?: TruckerIdentificationTOTerrorStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TruckerIdentificationTO
     */
    'documentStatus'?: TruckerIdentificationTODocumentStatusEnum;
    /**
     * 
     * @type {WebappUser}
     * @memberof TruckerIdentificationTO
     */
    'manuallyApprovedBy'?: WebappUser;
    /**
     * 
     * @type {WebappUser}
     * @memberof TruckerIdentificationTO
     */
    'manualApprovalSupervisor'?: WebappUser;
}

export const TruckerIdentificationTOTerrorStatusEnum = {
    Ok: 'OK',
    Warning: 'WARNING',
    RedAlert: 'RED_ALERT',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED'
} as const;

export type TruckerIdentificationTOTerrorStatusEnum = typeof TruckerIdentificationTOTerrorStatusEnum[keyof typeof TruckerIdentificationTOTerrorStatusEnum];
export const TruckerIdentificationTODocumentStatusEnum = {
    Accepted: 'ACCEPTED',
    Pending: 'PENDING',
    Denied: 'DENIED',
    Warn: 'WARN',
    DeniedPreliminary: 'DENIED_PRELIMINARY',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED',
    Duplicate: 'DUPLICATE',
    IdentityNotMatchingOnReverification: 'IDENTITY_NOT_MATCHING_ON_REVERIFICATION',
    Failed: 'FAILED'
} as const;

export type TruckerIdentificationTODocumentStatusEnum = typeof TruckerIdentificationTODocumentStatusEnum[keyof typeof TruckerIdentificationTODocumentStatusEnum];

/**
 * 
 * @export
 * @interface TruckerInformationTO
 */
export interface TruckerInformationTO {
    /**
     * 
     * @type {string}
     * @memberof TruckerInformationTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerInformationTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TruckerInformationTO
     */
    'lastName'?: string;
}
/**
 * Updated trucker object
 * @export
 * @interface UpdateTruckerForForwarderTO
 */
export interface UpdateTruckerForForwarderTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateTruckerForForwarderTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTruckerForForwarderTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTruckerForForwarderTO
     */
    'licensePlate'?: string;
}
/**
 * CONROO user
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Forwarder}
     * @memberof User
     */
    'forwarder': Forwarder;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'otherForwarder'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'isAtTerminal'?: number;
    /**
     * 
     * @type {IspsLicenseKeyResponseTO}
     * @memberof User
     */
    'ispsLicense'?: IspsLicenseKeyResponseTO;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'identityVerified'?: UserIdentityVerifiedEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deviceVerified'?: UserDeviceVerifiedEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'resetAllowedAfter'?: string;
    /**
     * 
     * @type {MobileTruckerIdentificationTO}
     * @memberof User
     */
    'truckerIdentification'?: MobileTruckerIdentificationTO;
}

export const UserIdentityVerifiedEnum = {
    Verified: 'VERIFIED',
    NotVerified: 'NOT_VERIFIED',
    Pending: 'PENDING',
    DeniedPreliminary: 'DENIED_PRELIMINARY',
    Denied: 'DENIED',
    Duplicate: 'DUPLICATE',
    IdentityNotMatchingOnReverification: 'IDENTITY_NOT_MATCHING_ON_REVERIFICATION',
    Failed: 'FAILED'
} as const;

export type UserIdentityVerifiedEnum = typeof UserIdentityVerifiedEnum[keyof typeof UserIdentityVerifiedEnum];
export const UserDeviceVerifiedEnum = {
    Verified: 'VERIFIED',
    NotVerified: 'NOT_VERIFIED'
} as const;

export type UserDeviceVerifiedEnum = typeof UserDeviceVerifiedEnum[keyof typeof UserDeviceVerifiedEnum];

/**
 * Verify email body
 * @export
 * @interface VerifyEmail
 */
export interface VerifyEmail {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmail
     */
    'email': string;
}
/**
 * WasteData
 * @export
 * @interface WasteData
 */
export interface WasteData {
    /**
     * 
     * @type {number}
     * @memberof WasteData
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    'eAKKeyNumber': string;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    'aVVNumber': string;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    'voucherNumber': string;
    /**
     * 
     * @type {string}
     * @memberof WasteData
     */
    'addedBy': WasteDataAddedByEnum;
}

export const WasteDataAddedByEnum = {
    Trucker: 'trucker',
    Terminal: 'terminal',
    ForwarderOperator: 'forwarder_operator'
} as const;

export type WasteDataAddedByEnum = typeof WasteDataAddedByEnum[keyof typeof WasteDataAddedByEnum];

/**
 * Updated user object
 * @export
 * @interface WebappUser
 */
export interface WebappUser {
    /**
     * 
     * @type {string}
     * @memberof WebappUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WebappUser
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebappUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebappUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebappUser
     */
    'email'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebappUser
     */
    'roles'?: Array<WebappUserRolesEnum>;
}

export const WebappUserRolesEnum = {
    TerminalOperationsRead: 'terminal_operations_read',
    TerminalOperationsWrite: 'terminal_operations_write',
    TerminalOperationsAdmin: 'terminal_operations_admin',
    ForwarderRead: 'forwarder_read',
    ForwarderWrite: 'forwarder_write',
    TrainOperatorRead: 'train_operator_read',
    TrainOperatorWrite: 'train_operator_write',
    ConrooRead: 'conroo_read',
    ConrooWrite: 'conroo_write',
    ConrooAdmin: 'conroo_admin',
    MirrorAppRead: 'mirror_app_read',
    MirrorAppWrite: 'mirror_app_write',
    IspsTerminalOperationsRead: 'isps_terminal_operations_read',
    IspsTerminalOperationsWrite: 'isps_terminal_operations_write'
} as const;

export type WebappUserRolesEnum = typeof WebappUserRolesEnum[keyof typeof WebappUserRolesEnum];


/**
 * AcceptanceRestrictionsApi - axios parameter creator
 * @export
 */
export const AcceptanceRestrictionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {AcceptanceDeadline} acceptanceDeadline 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAcceptanceDeadlineForTerminal: async (userId: string, terminalId: number, acceptanceDeadline: AcceptanceDeadline, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createAcceptanceDeadlineForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('createAcceptanceDeadlineForTerminal', 'terminalId', terminalId)
            // verify required parameter 'acceptanceDeadline' is not null or undefined
            assertParamExists('createAcceptanceDeadlineForTerminal', 'acceptanceDeadline', acceptanceDeadline)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/acceptanceDeadlines`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptanceDeadline, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates one to one restriction for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {OneToOneRestriction} oneToOneRestriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneToOneRestrictionForTerminal: async (userId: string, terminalId: number, oneToOneRestriction: OneToOneRestriction, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createOneToOneRestrictionForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('createOneToOneRestrictionForTerminal', 'terminalId', terminalId)
            // verify required parameter 'oneToOneRestriction' is not null or undefined
            assertParamExists('createOneToOneRestrictionForTerminal', 'oneToOneRestriction', oneToOneRestriction)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/oneToOneRestrictions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oneToOneRestriction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} acceptanceDeadlineId id of acceptance deadline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAcceptanceDeadlineForTerminal: async (userId: string, terminalId: number, acceptanceDeadlineId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteAcceptanceDeadlineForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('deleteAcceptanceDeadlineForTerminal', 'terminalId', terminalId)
            // verify required parameter 'acceptanceDeadlineId' is not null or undefined
            assertParamExists('deleteAcceptanceDeadlineForTerminal', 'acceptanceDeadlineId', acceptanceDeadlineId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/acceptanceDeadlines/{acceptanceDeadlineId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)))
                .replace(`{${"acceptanceDeadlineId"}}`, encodeURIComponent(String(acceptanceDeadlineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes one to one restriction for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} oneToOneRestrictionId id of acceptance deadline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneToOneRestrictionForTerminal: async (userId: string, terminalId: number, oneToOneRestrictionId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteOneToOneRestrictionForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('deleteOneToOneRestrictionForTerminal', 'terminalId', terminalId)
            // verify required parameter 'oneToOneRestrictionId' is not null or undefined
            assertParamExists('deleteOneToOneRestrictionForTerminal', 'oneToOneRestrictionId', oneToOneRestrictionId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/oneToOneRestrictions/{oneToOneRestrictionId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)))
                .replace(`{${"oneToOneRestrictionId"}}`, encodeURIComponent(String(oneToOneRestrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all acceptance deadlines of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptanceDeadlinesForTerminal: async (userId: string, terminalId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAcceptanceDeadlinesForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getAcceptanceDeadlinesForTerminal', 'terminalId', terminalId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/acceptanceDeadlines`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all one to one  restrictions of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneToOneRestrictionsForTerminal: async (userId: string, terminalId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOneToOneRestrictionsForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getOneToOneRestrictionsForTerminal', 'terminalId', terminalId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/oneToOneRestrictions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} acceptanceDeadlineId id of acceptance deadline
         * @param {AcceptanceDeadline} acceptanceDeadline 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAcceptanceDeadlineForTerminal: async (userId: string, terminalId: number, acceptanceDeadlineId: number, acceptanceDeadline: AcceptanceDeadline, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateAcceptanceDeadlineForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('updateAcceptanceDeadlineForTerminal', 'terminalId', terminalId)
            // verify required parameter 'acceptanceDeadlineId' is not null or undefined
            assertParamExists('updateAcceptanceDeadlineForTerminal', 'acceptanceDeadlineId', acceptanceDeadlineId)
            // verify required parameter 'acceptanceDeadline' is not null or undefined
            assertParamExists('updateAcceptanceDeadlineForTerminal', 'acceptanceDeadline', acceptanceDeadline)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/acceptanceDeadlines/{acceptanceDeadlineId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)))
                .replace(`{${"acceptanceDeadlineId"}}`, encodeURIComponent(String(acceptanceDeadlineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptanceDeadline, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates one to one restriction  for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} oneToOneRestrictionId id of acceptance deadline
         * @param {OneToOneRestriction} oneToOneRestriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneToOneRestrictionForTerminal: async (userId: string, terminalId: number, oneToOneRestrictionId: number, oneToOneRestriction: OneToOneRestriction, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateOneToOneRestrictionForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('updateOneToOneRestrictionForTerminal', 'terminalId', terminalId)
            // verify required parameter 'oneToOneRestrictionId' is not null or undefined
            assertParamExists('updateOneToOneRestrictionForTerminal', 'oneToOneRestrictionId', oneToOneRestrictionId)
            // verify required parameter 'oneToOneRestriction' is not null or undefined
            assertParamExists('updateOneToOneRestrictionForTerminal', 'oneToOneRestriction', oneToOneRestriction)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/oneToOneRestrictions/{oneToOneRestrictionId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)))
                .replace(`{${"oneToOneRestrictionId"}}`, encodeURIComponent(String(oneToOneRestrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oneToOneRestriction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AcceptanceRestrictionsApi - functional programming interface
 * @export
 */
export const AcceptanceRestrictionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AcceptanceRestrictionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {AcceptanceDeadline} acceptanceDeadline 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadline: AcceptanceDeadline, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptanceDeadline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadline, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.createAcceptanceDeadlineForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates one to one restriction for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {OneToOneRestriction} oneToOneRestriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestriction: OneToOneRestriction, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OneToOneRestriction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestriction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.createOneToOneRestrictionForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} acceptanceDeadlineId id of acceptance deadline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.deleteAcceptanceDeadlineForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes one to one restriction for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} oneToOneRestrictionId id of acceptance deadline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.deleteOneToOneRestrictionForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all acceptance deadlines of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAcceptanceDeadlinesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AcceptanceDeadline>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAcceptanceDeadlinesForTerminal(userId, terminalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.getAcceptanceDeadlinesForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all one to one  restrictions of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneToOneRestrictionsForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OneToOneRestriction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneToOneRestrictionsForTerminal(userId, terminalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.getOneToOneRestrictionsForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} acceptanceDeadlineId id of acceptance deadline
         * @param {AcceptanceDeadline} acceptanceDeadline 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, acceptanceDeadline: AcceptanceDeadline, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptanceDeadline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, acceptanceDeadline, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.updateAcceptanceDeadlineForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates one to one restriction  for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} oneToOneRestrictionId id of acceptance deadline
         * @param {OneToOneRestriction} oneToOneRestriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, oneToOneRestriction: OneToOneRestriction, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OneToOneRestriction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, oneToOneRestriction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AcceptanceRestrictionsApi.updateOneToOneRestrictionForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AcceptanceRestrictionsApi - factory interface
 * @export
 */
export const AcceptanceRestrictionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AcceptanceRestrictionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {AcceptanceDeadline} acceptanceDeadline 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadline: AcceptanceDeadline, options?: RawAxiosRequestConfig): AxiosPromise<AcceptanceDeadline> {
            return localVarFp.createAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadline, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates one to one restriction for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {OneToOneRestriction} oneToOneRestriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestriction: OneToOneRestriction, options?: RawAxiosRequestConfig): AxiosPromise<OneToOneRestriction> {
            return localVarFp.createOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestriction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} acceptanceDeadlineId id of acceptance deadline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes one to one restriction for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} oneToOneRestrictionId id of acceptance deadline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all acceptance deadlines of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptanceDeadlinesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<AcceptanceDeadline>> {
            return localVarFp.getAcceptanceDeadlinesForTerminal(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all one to one  restrictions of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneToOneRestrictionsForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OneToOneRestriction>> {
            return localVarFp.getOneToOneRestrictionsForTerminal(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates acceptance deadline for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} acceptanceDeadlineId id of acceptance deadline
         * @param {AcceptanceDeadline} acceptanceDeadline 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, acceptanceDeadline: AcceptanceDeadline, options?: RawAxiosRequestConfig): AxiosPromise<AcceptanceDeadline> {
            return localVarFp.updateAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, acceptanceDeadline, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates one to one restriction  for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} oneToOneRestrictionId id of acceptance deadline
         * @param {OneToOneRestriction} oneToOneRestriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, oneToOneRestriction: OneToOneRestriction, options?: RawAxiosRequestConfig): AxiosPromise<OneToOneRestriction> {
            return localVarFp.updateOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, oneToOneRestriction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AcceptanceRestrictionsApi - object-oriented interface
 * @export
 * @class AcceptanceRestrictionsApi
 * @extends {BaseAPI}
 */
export class AcceptanceRestrictionsApi extends BaseAPI {
    /**
     * 
     * @summary Creates acceptance deadline for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {AcceptanceDeadline} acceptanceDeadline 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public createAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadline: AcceptanceDeadline, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).createAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates one to one restriction for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {OneToOneRestriction} oneToOneRestriction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public createOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestriction: OneToOneRestriction, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).createOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestriction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes acceptance deadline for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {number} acceptanceDeadlineId id of acceptance deadline
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public deleteAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).deleteAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes one to one restriction for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {number} oneToOneRestrictionId id of acceptance deadline
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public deleteOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).deleteOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all acceptance deadlines of a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public getAcceptanceDeadlinesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).getAcceptanceDeadlinesForTerminal(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all one to one  restrictions of a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public getOneToOneRestrictionsForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).getOneToOneRestrictionsForTerminal(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates acceptance deadline for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {number} acceptanceDeadlineId id of acceptance deadline
     * @param {AcceptanceDeadline} acceptanceDeadline 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public updateAcceptanceDeadlineForTerminal(userId: string, terminalId: number, acceptanceDeadlineId: number, acceptanceDeadline: AcceptanceDeadline, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).updateAcceptanceDeadlineForTerminal(userId, terminalId, acceptanceDeadlineId, acceptanceDeadline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates one to one restriction  for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {number} oneToOneRestrictionId id of acceptance deadline
     * @param {OneToOneRestriction} oneToOneRestriction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcceptanceRestrictionsApi
     */
    public updateOneToOneRestrictionForTerminal(userId: string, terminalId: number, oneToOneRestrictionId: number, oneToOneRestriction: OneToOneRestriction, options?: RawAxiosRequestConfig) {
        return AcceptanceRestrictionsApiFp(this.configuration).updateOneToOneRestrictionForTerminal(userId, terminalId, oneToOneRestrictionId, oneToOneRestriction, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomMessageApi - axios parameter creator
 * @export
 */
export const CustomMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {FacilityMessageTO} facilityMessageTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomMessageForTerminal: async (userId: string, terminalId: number, facilityMessageTO: FacilityMessageTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createCustomMessageForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('createCustomMessageForTerminal', 'terminalId', terminalId)
            // verify required parameter 'facilityMessageTO' is not null or undefined
            assertParamExists('createCustomMessageForTerminal', 'facilityMessageTO', facilityMessageTO)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/messages`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityMessageTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} messageId id of custom message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomMessageForTerminal: async (userId: string, terminalId: number, messageId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteCustomMessageForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('deleteCustomMessageForTerminal', 'terminalId', terminalId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('deleteCustomMessageForTerminal', 'messageId', messageId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/messages/{messageId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all custom messages of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMessagesForTerminal: async (userId: string, terminalId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCustomMessagesForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getCustomMessagesForTerminal', 'terminalId', terminalId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/messages`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} messageId id of custom message
         * @param {FacilityMessageTO} facilityMessageTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomMessageForTerminal: async (userId: string, terminalId: number, messageId: number, facilityMessageTO: FacilityMessageTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCustomMessageForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('updateCustomMessageForTerminal', 'terminalId', terminalId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('updateCustomMessageForTerminal', 'messageId', messageId)
            // verify required parameter 'facilityMessageTO' is not null or undefined
            assertParamExists('updateCustomMessageForTerminal', 'facilityMessageTO', facilityMessageTO)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/messages/{messageId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityMessageTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomMessageApi - functional programming interface
 * @export
 */
export const CustomMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {FacilityMessageTO} facilityMessageTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomMessageForTerminal(userId: string, terminalId: number, facilityMessageTO: FacilityMessageTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityMessageTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomMessageForTerminal(userId, terminalId, facilityMessageTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomMessageApi.createCustomMessageForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} messageId id of custom message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomMessageForTerminal(userId: string, terminalId: number, messageId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomMessageForTerminal(userId, terminalId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomMessageApi.deleteCustomMessageForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all custom messages of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomMessagesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityMessageTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomMessagesForTerminal(userId, terminalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomMessageApi.getCustomMessagesForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} messageId id of custom message
         * @param {FacilityMessageTO} facilityMessageTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomMessageForTerminal(userId: string, terminalId: number, messageId: number, facilityMessageTO: FacilityMessageTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityMessageTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomMessageForTerminal(userId, terminalId, messageId, facilityMessageTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomMessageApi.updateCustomMessageForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomMessageApi - factory interface
 * @export
 */
export const CustomMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomMessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {FacilityMessageTO} facilityMessageTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomMessageForTerminal(userId: string, terminalId: number, facilityMessageTO: FacilityMessageTO, options?: RawAxiosRequestConfig): AxiosPromise<FacilityMessageTO> {
            return localVarFp.createCustomMessageForTerminal(userId, terminalId, facilityMessageTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} messageId id of custom message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomMessageForTerminal(userId: string, terminalId: number, messageId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCustomMessageForTerminal(userId, terminalId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all custom messages of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomMessagesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<FacilityMessageTO>> {
            return localVarFp.getCustomMessagesForTerminal(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates custom message for a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} messageId id of custom message
         * @param {FacilityMessageTO} facilityMessageTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomMessageForTerminal(userId: string, terminalId: number, messageId: number, facilityMessageTO: FacilityMessageTO, options?: RawAxiosRequestConfig): AxiosPromise<FacilityMessageTO> {
            return localVarFp.updateCustomMessageForTerminal(userId, terminalId, messageId, facilityMessageTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomMessageApi - object-oriented interface
 * @export
 * @class CustomMessageApi
 * @extends {BaseAPI}
 */
export class CustomMessageApi extends BaseAPI {
    /**
     * 
     * @summary Creates custom message for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {FacilityMessageTO} facilityMessageTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomMessageApi
     */
    public createCustomMessageForTerminal(userId: string, terminalId: number, facilityMessageTO: FacilityMessageTO, options?: RawAxiosRequestConfig) {
        return CustomMessageApiFp(this.configuration).createCustomMessageForTerminal(userId, terminalId, facilityMessageTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes custom message for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {number} messageId id of custom message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomMessageApi
     */
    public deleteCustomMessageForTerminal(userId: string, terminalId: number, messageId: number, options?: RawAxiosRequestConfig) {
        return CustomMessageApiFp(this.configuration).deleteCustomMessageForTerminal(userId, terminalId, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all custom messages of a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomMessageApi
     */
    public getCustomMessagesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig) {
        return CustomMessageApiFp(this.configuration).getCustomMessagesForTerminal(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates custom message for a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {number} messageId id of custom message
     * @param {FacilityMessageTO} facilityMessageTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomMessageApi
     */
    public updateCustomMessageForTerminal(userId: string, terminalId: number, messageId: number, facilityMessageTO: FacilityMessageTO, options?: RawAxiosRequestConfig) {
        return CustomMessageApiFp(this.configuration).updateCustomMessageForTerminal(userId, terminalId, messageId, facilityMessageTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ForwarderBookingsApi - axios parameter creator
 * @export
 */
export const ForwarderBookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancels a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking: async (userId: string, forwarderId: number, truckerId: string, bookingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cancelBooking', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('cancelBooking', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('cancelBooking', 'truckerId', truckerId)
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('cancelBooking', 'bookingId', bookingId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}/bookings/{bookingId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs a booking for a trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingForTrucker: async (userId: string, forwarderId: number, truckerId: string, bookingRequest: BookingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createBookingForTrucker', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('createBookingForTrucker', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('createBookingForTrucker', 'truckerId', truckerId)
            // verify required parameter 'bookingRequest' is not null or undefined
            assertParamExists('createBookingForTrucker', 'bookingRequest', bookingRequest)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Booking by id for trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingByIdForTrucker: async (userId: string, forwarderId: number, truckerId: string, bookingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBookingByIdForTrucker', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getBookingByIdForTrucker', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('getBookingByIdForTrucker', 'truckerId', truckerId)
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getBookingByIdForTrucker', 'bookingId', bookingId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}/bookings/{bookingId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export booking transhipment confirmation as pdf
         * @summary Export booking transhipment confirmation as pdf
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranshipmentConfirmationDocument: async (userId: string, forwarderId: number, truckerId: string, bookingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTranshipmentConfirmationDocument', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getTranshipmentConfirmationDocument', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('getTranshipmentConfirmationDocument', 'truckerId', truckerId)
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('getTranshipmentConfirmationDocument', 'bookingId', bookingId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}/bookings/{bookingId}/export`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Bookings for forwarder
         * @summary Get all Bookings for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {BookingsFilterRequestTO} bookingsFilterRequestTO 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsForForwarder: async (userId: string, forwarderId: number, bookingsFilterRequestTO: BookingsFilterRequestTO, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listBookingsForForwarder', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('listBookingsForForwarder', 'forwarderId', forwarderId)
            // verify required parameter 'bookingsFilterRequestTO' is not null or undefined
            assertParamExists('listBookingsForForwarder', 'bookingsFilterRequestTO', bookingsFilterRequestTO)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingsFilterRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all bookings for a specific trucker
         * @summary Get all Bookings for trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId UUID that comes from firebase authentication
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsForTrucker: async (userId: string, forwarderId: number, truckerId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listBookingsForTrucker', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('listBookingsForTrucker', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('listBookingsForTrucker', 'truckerId', truckerId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForwarderBookingsApi - functional programming interface
 * @export
 */
export const ForwarderBookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForwarderBookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancels a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelBooking(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelBooking(userId, forwarderId, truckerId, bookingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderBookingsApi.cancelBooking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Performs a booking for a trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBookingForTrucker(userId: string, forwarderId: number, truckerId: string, bookingRequest: BookingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBookingForTrucker(userId, forwarderId, truckerId, bookingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderBookingsApi.createBookingForTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Booking by id for trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingByIdForTrucker(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingForForwarderTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingByIdForTrucker(userId, forwarderId, truckerId, bookingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderBookingsApi.getBookingByIdForTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Export booking transhipment confirmation as pdf
         * @summary Export booking transhipment confirmation as pdf
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranshipmentConfirmationDocument(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranshipmentConfirmationDocument(userId, forwarderId, truckerId, bookingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderBookingsApi.getTranshipmentConfirmationDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all Bookings for forwarder
         * @summary Get all Bookings for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {BookingsFilterRequestTO} bookingsFilterRequestTO 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookingsForForwarder(userId: string, forwarderId: number, bookingsFilterRequestTO: BookingsFilterRequestTO, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingForForwarderTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBookingsForForwarder(userId, forwarderId, bookingsFilterRequestTO, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderBookingsApi.listBookingsForForwarder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets all bookings for a specific trucker
         * @summary Get all Bookings for trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId UUID that comes from firebase authentication
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookingsForTrucker(userId: string, forwarderId: number, truckerId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingForForwarderTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBookingsForTrucker(userId, forwarderId, truckerId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderBookingsApi.listBookingsForTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForwarderBookingsApi - factory interface
 * @export
 */
export const ForwarderBookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForwarderBookingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancels a booking
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelBooking(userId, forwarderId, truckerId, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs a booking for a trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBookingForTrucker(userId: string, forwarderId: number, truckerId: string, bookingRequest: BookingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Booking> {
            return localVarFp.createBookingForTrucker(userId, forwarderId, truckerId, bookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Booking by id for trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingByIdForTrucker(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig): AxiosPromise<BookingForForwarderTO> {
            return localVarFp.getBookingByIdForTrucker(userId, forwarderId, truckerId, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Export booking transhipment confirmation as pdf
         * @summary Export booking transhipment confirmation as pdf
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker the booking belongs to
         * @param {number} bookingId Booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranshipmentConfirmationDocument(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getTranshipmentConfirmationDocument(userId, forwarderId, truckerId, bookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Bookings for forwarder
         * @summary Get all Bookings for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {BookingsFilterRequestTO} bookingsFilterRequestTO 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsForForwarder(userId: string, forwarderId: number, bookingsFilterRequestTO: BookingsFilterRequestTO, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BookingForForwarderTO>> {
            return localVarFp.listBookingsForForwarder(userId, forwarderId, bookingsFilterRequestTO, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all bookings for a specific trucker
         * @summary Get all Bookings for trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId UUID that comes from firebase authentication
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsForTrucker(userId: string, forwarderId: number, truckerId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BookingForForwarderTO>> {
            return localVarFp.listBookingsForTrucker(userId, forwarderId, truckerId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForwarderBookingsApi - object-oriented interface
 * @export
 * @class ForwarderBookingsApi
 * @extends {BaseAPI}
 */
export class ForwarderBookingsApi extends BaseAPI {
    /**
     * 
     * @summary Cancels a booking
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId id of the trucker the booking belongs to
     * @param {number} bookingId Booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderBookingsApi
     */
    public cancelBooking(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig) {
        return ForwarderBookingsApiFp(this.configuration).cancelBooking(userId, forwarderId, truckerId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs a booking for a trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId id of the trucker the booking belongs to
     * @param {BookingRequest} bookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderBookingsApi
     */
    public createBookingForTrucker(userId: string, forwarderId: number, truckerId: string, bookingRequest: BookingRequest, options?: RawAxiosRequestConfig) {
        return ForwarderBookingsApiFp(this.configuration).createBookingForTrucker(userId, forwarderId, truckerId, bookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Booking by id for trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId id of the trucker the booking belongs to
     * @param {number} bookingId Booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderBookingsApi
     */
    public getBookingByIdForTrucker(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig) {
        return ForwarderBookingsApiFp(this.configuration).getBookingByIdForTrucker(userId, forwarderId, truckerId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Export booking transhipment confirmation as pdf
     * @summary Export booking transhipment confirmation as pdf
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId id of the trucker the booking belongs to
     * @param {number} bookingId Booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderBookingsApi
     */
    public getTranshipmentConfirmationDocument(userId: string, forwarderId: number, truckerId: string, bookingId: number, options?: RawAxiosRequestConfig) {
        return ForwarderBookingsApiFp(this.configuration).getTranshipmentConfirmationDocument(userId, forwarderId, truckerId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Bookings for forwarder
     * @summary Get all Bookings for forwarder
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {BookingsFilterRequestTO} bookingsFilterRequestTO 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderBookingsApi
     */
    public listBookingsForForwarder(userId: string, forwarderId: number, bookingsFilterRequestTO: BookingsFilterRequestTO, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return ForwarderBookingsApiFp(this.configuration).listBookingsForForwarder(userId, forwarderId, bookingsFilterRequestTO, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all bookings for a specific trucker
     * @summary Get all Bookings for trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId UUID that comes from firebase authentication
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderBookingsApi
     */
    public listBookingsForTrucker(userId: string, forwarderId: number, truckerId: string, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return ForwarderBookingsApiFp(this.configuration).listBookingsForTrucker(userId, forwarderId, truckerId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ForwarderContainersApi - axios parameter creator
 * @export
 */
export const ForwarderContainersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Downloads a csv with getStatus list requests and responses
         * @summary Downloads a csv with getStatus list requests and responses
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {GetStatusDownloadCSVRequestTO} getStatusDownloadCSVRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGetStatusCsv: async (userId: string, forwarderId: number, getStatusDownloadCSVRequestTO: GetStatusDownloadCSVRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('downloadGetStatusCsv', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('downloadGetStatusCsv', 'forwarderId', forwarderId)
            // verify required parameter 'getStatusDownloadCSVRequestTO' is not null or undefined
            assertParamExists('downloadGetStatusCsv', 'getStatusDownloadCSVRequestTO', getStatusDownloadCSVRequestTO)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/containers/downloadCsv`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStatusDownloadCSVRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Indicates whether a booking is possible to be booked and supplies necessary information.
         * @summary Requests the status of a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {number} terminalId The terminal id of the terminal to be called
         * @param {GetStatusRequest} getStatusRequest 
         * @param {GetStatus1RequestTypeEnum} [requestType] place where get status was triggered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus1: async (userId: string, forwarderId: number, terminalId: number, getStatusRequest: GetStatusRequest, requestType?: GetStatus1RequestTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getStatus1', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getStatus1', 'forwarderId', forwarderId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getStatus1', 'terminalId', terminalId)
            // verify required parameter 'getStatusRequest' is not null or undefined
            assertParamExists('getStatus1', 'getStatusRequest', getStatusRequest)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/terminals/{terminalId}/containers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestType !== undefined) {
                localVarQueryParameter['requestType'] = requestType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * parses csv file for container data and returns getStatusRequests to be done by webapp.
         * @summary parses csv file for container data and returns getStatusRequests to be done by webapp
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {File} file Csv file containing container data to be parsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseGetStatusFile: async (userId: string, forwarderId: number, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('parseGetStatusFile', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('parseGetStatusFile', 'forwarderId', forwarderId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('parseGetStatusFile', 'file', file)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/containers/parseCsv`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForwarderContainersApi - functional programming interface
 * @export
 */
export const ForwarderContainersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForwarderContainersApiAxiosParamCreator(configuration)
    return {
        /**
         * Downloads a csv with getStatus list requests and responses
         * @summary Downloads a csv with getStatus list requests and responses
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {GetStatusDownloadCSVRequestTO} getStatusDownloadCSVRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadGetStatusCsv(userId: string, forwarderId: number, getStatusDownloadCSVRequestTO: GetStatusDownloadCSVRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadGetStatusCsv(userId, forwarderId, getStatusDownloadCSVRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderContainersApi.downloadGetStatusCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Indicates whether a booking is possible to be booked and supplies necessary information.
         * @summary Requests the status of a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {number} terminalId The terminal id of the terminal to be called
         * @param {GetStatusRequest} getStatusRequest 
         * @param {GetStatus1RequestTypeEnum} [requestType] place where get status was triggered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus1(userId: string, forwarderId: number, terminalId: number, getStatusRequest: GetStatusRequest, requestType?: GetStatus1RequestTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus1(userId, forwarderId, terminalId, getStatusRequest, requestType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderContainersApi.getStatus1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * parses csv file for container data and returns getStatusRequests to be done by webapp.
         * @summary parses csv file for container data and returns getStatusRequests to be done by webapp
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {File} file Csv file containing container data to be parsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseGetStatusFile(userId: string, forwarderId: number, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParseGetStatusFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseGetStatusFile(userId, forwarderId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderContainersApi.parseGetStatusFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForwarderContainersApi - factory interface
 * @export
 */
export const ForwarderContainersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForwarderContainersApiFp(configuration)
    return {
        /**
         * Downloads a csv with getStatus list requests and responses
         * @summary Downloads a csv with getStatus list requests and responses
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {GetStatusDownloadCSVRequestTO} getStatusDownloadCSVRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGetStatusCsv(userId: string, forwarderId: number, getStatusDownloadCSVRequestTO: GetStatusDownloadCSVRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.downloadGetStatusCsv(userId, forwarderId, getStatusDownloadCSVRequestTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Indicates whether a booking is possible to be booked and supplies necessary information.
         * @summary Requests the status of a loading unit
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {number} terminalId The terminal id of the terminal to be called
         * @param {GetStatusRequest} getStatusRequest 
         * @param {GetStatus1RequestTypeEnum} [requestType] place where get status was triggered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus1(userId: string, forwarderId: number, terminalId: number, getStatusRequest: GetStatusRequest, requestType?: GetStatus1RequestTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<GetStatusResponse> {
            return localVarFp.getStatus1(userId, forwarderId, terminalId, getStatusRequest, requestType, options).then((request) => request(axios, basePath));
        },
        /**
         * parses csv file for container data and returns getStatusRequests to be done by webapp.
         * @summary parses csv file for container data and returns getStatusRequests to be done by webapp
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {File} file Csv file containing container data to be parsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseGetStatusFile(userId: string, forwarderId: number, file: File, options?: RawAxiosRequestConfig): AxiosPromise<ParseGetStatusFileResponse> {
            return localVarFp.parseGetStatusFile(userId, forwarderId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForwarderContainersApi - object-oriented interface
 * @export
 * @class ForwarderContainersApi
 * @extends {BaseAPI}
 */
export class ForwarderContainersApi extends BaseAPI {
    /**
     * Downloads a csv with getStatus list requests and responses
     * @summary Downloads a csv with getStatus list requests and responses
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId forwarderId as long parameter
     * @param {GetStatusDownloadCSVRequestTO} getStatusDownloadCSVRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderContainersApi
     */
    public downloadGetStatusCsv(userId: string, forwarderId: number, getStatusDownloadCSVRequestTO: GetStatusDownloadCSVRequestTO, options?: RawAxiosRequestConfig) {
        return ForwarderContainersApiFp(this.configuration).downloadGetStatusCsv(userId, forwarderId, getStatusDownloadCSVRequestTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Indicates whether a booking is possible to be booked and supplies necessary information.
     * @summary Requests the status of a loading unit
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId forwarderId as long parameter
     * @param {number} terminalId The terminal id of the terminal to be called
     * @param {GetStatusRequest} getStatusRequest 
     * @param {GetStatus1RequestTypeEnum} [requestType] place where get status was triggered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderContainersApi
     */
    public getStatus1(userId: string, forwarderId: number, terminalId: number, getStatusRequest: GetStatusRequest, requestType?: GetStatus1RequestTypeEnum, options?: RawAxiosRequestConfig) {
        return ForwarderContainersApiFp(this.configuration).getStatus1(userId, forwarderId, terminalId, getStatusRequest, requestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * parses csv file for container data and returns getStatusRequests to be done by webapp.
     * @summary parses csv file for container data and returns getStatusRequests to be done by webapp
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId forwarderId as long parameter
     * @param {File} file Csv file containing container data to be parsed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderContainersApi
     */
    public parseGetStatusFile(userId: string, forwarderId: number, file: File, options?: RawAxiosRequestConfig) {
        return ForwarderContainersApiFp(this.configuration).parseGetStatusFile(userId, forwarderId, file, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetStatus1RequestTypeEnum = {
    SingleGetStatus: 'single_get_status',
    ListGetStatus: 'list_get_status'
} as const;
export type GetStatus1RequestTypeEnum = typeof GetStatus1RequestTypeEnum[keyof typeof GetStatus1RequestTypeEnum];


/**
 * ForwarderOperatorForwardersApi - axios parameter creator
 * @export
 */
export const ForwarderOperatorForwardersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gets all forwarders
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForwarders: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllForwarders', 'userId', userId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets forwarder by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForwarderById: async (userId: string, forwarderId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getForwarderById', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getForwarderById', 'forwarderId', forwarderId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForwarderOperatorForwardersApi - functional programming interface
 * @export
 */
export const ForwarderOperatorForwardersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForwarderOperatorForwardersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gets all forwarders
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllForwarders(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Forwarder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllForwarders(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderOperatorForwardersApi.getAllForwarders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary gets forwarder by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForwarderById(userId: string, forwarderId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Forwarder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForwarderById(userId, forwarderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderOperatorForwardersApi.getForwarderById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForwarderOperatorForwardersApi - factory interface
 * @export
 */
export const ForwarderOperatorForwardersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForwarderOperatorForwardersApiFp(configuration)
    return {
        /**
         * 
         * @summary gets all forwarders
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllForwarders(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Forwarder>> {
            return localVarFp.getAllForwarders(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets forwarder by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId forwarderId as long parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForwarderById(userId: string, forwarderId: number, options?: RawAxiosRequestConfig): AxiosPromise<Forwarder> {
            return localVarFp.getForwarderById(userId, forwarderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForwarderOperatorForwardersApi - object-oriented interface
 * @export
 * @class ForwarderOperatorForwardersApi
 * @extends {BaseAPI}
 */
export class ForwarderOperatorForwardersApi extends BaseAPI {
    /**
     * 
     * @summary gets all forwarders
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderOperatorForwardersApi
     */
    public getAllForwarders(userId: string, options?: RawAxiosRequestConfig) {
        return ForwarderOperatorForwardersApiFp(this.configuration).getAllForwarders(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets forwarder by id
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId forwarderId as long parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderOperatorForwardersApi
     */
    public getForwarderById(userId: string, forwarderId: number, options?: RawAxiosRequestConfig) {
        return ForwarderOperatorForwardersApiFp(this.configuration).getForwarderById(userId, forwarderId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ForwarderOperatorTerminalApiApi - axios parameter creator
 * @export
 */
export const ForwarderOperatorTerminalApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all one to one  restrictions of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the Forwarder
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneToOneRestrictionsForForwarderOperatorForTerminal: async (userId: string, forwarderId: number, terminalId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOneToOneRestrictionsForForwarderOperatorForTerminal', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getOneToOneRestrictionsForForwarderOperatorForTerminal', 'forwarderId', forwarderId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getOneToOneRestrictionsForForwarderOperatorForTerminal', 'terminalId', terminalId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/terminals/{terminalId}/oneToOneRestrictions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a User ID, Forwarder ID  and returns all existing terminals
         * @summary Gets all terminals for forwarder operator
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of Forwarder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForForwarderOperator: async (userId: string, forwarderId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTerminalsForForwarderOperator', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getTerminalsForForwarderOperator', 'forwarderId', forwarderId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/terminals`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForwarderOperatorTerminalApiApi - functional programming interface
 * @export
 */
export const ForwarderOperatorTerminalApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForwarderOperatorTerminalApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all one to one  restrictions of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the Forwarder
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneToOneRestrictionsForForwarderOperatorForTerminal(userId: string, forwarderId: number, terminalId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OneToOneRestriction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneToOneRestrictionsForForwarderOperatorForTerminal(userId, forwarderId, terminalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderOperatorTerminalApiApi.getOneToOneRestrictionsForForwarderOperatorForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a User ID, Forwarder ID  and returns all existing terminals
         * @summary Gets all terminals for forwarder operator
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of Forwarder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalsForForwarderOperator(userId: string, forwarderId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalReduced>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalsForForwarderOperator(userId, forwarderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderOperatorTerminalApiApi.getTerminalsForForwarderOperator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForwarderOperatorTerminalApiApi - factory interface
 * @export
 */
export const ForwarderOperatorTerminalApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForwarderOperatorTerminalApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all one to one  restrictions of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the Forwarder
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneToOneRestrictionsForForwarderOperatorForTerminal(userId: string, forwarderId: number, terminalId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OneToOneRestriction>> {
            return localVarFp.getOneToOneRestrictionsForForwarderOperatorForTerminal(userId, forwarderId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a User ID, Forwarder ID  and returns all existing terminals
         * @summary Gets all terminals for forwarder operator
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of Forwarder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForForwarderOperator(userId: string, forwarderId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TerminalReduced>> {
            return localVarFp.getTerminalsForForwarderOperator(userId, forwarderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForwarderOperatorTerminalApiApi - object-oriented interface
 * @export
 * @class ForwarderOperatorTerminalApiApi
 * @extends {BaseAPI}
 */
export class ForwarderOperatorTerminalApiApi extends BaseAPI {
    /**
     * 
     * @summary Gets all one to one  restrictions of a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the Forwarder
     * @param {number} terminalId id of requested terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderOperatorTerminalApiApi
     */
    public getOneToOneRestrictionsForForwarderOperatorForTerminal(userId: string, forwarderId: number, terminalId: number, options?: RawAxiosRequestConfig) {
        return ForwarderOperatorTerminalApiApiFp(this.configuration).getOneToOneRestrictionsForForwarderOperatorForTerminal(userId, forwarderId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a User ID, Forwarder ID  and returns all existing terminals
     * @summary Gets all terminals for forwarder operator
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of Forwarder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderOperatorTerminalApiApi
     */
    public getTerminalsForForwarderOperator(userId: string, forwarderId: number, options?: RawAxiosRequestConfig) {
        return ForwarderOperatorTerminalApiApiFp(this.configuration).getTerminalsForForwarderOperator(userId, forwarderId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ForwarderTruckersApiApi - axios parameter creator
 * @export
 */
export const ForwarderTruckersApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Approves a trucker to have this forwarder
         * @summary Approves a trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to update
         * @param {ForwarderApproval} forwarderApproval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTrucker: async (userId: string, forwarderId: number, truckerId: string, forwarderApproval: ForwarderApproval, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('approveTrucker', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('approveTrucker', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('approveTrucker', 'truckerId', truckerId)
            // verify required parameter 'forwarderApproval' is not null or undefined
            assertParamExists('approveTrucker', 'forwarderApproval', forwarderApproval)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}/approve`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forwarderApproval, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes current trucker
         * @summary Delete trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrucker: async (userId: string, forwarderId: number, truckerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTrucker', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('deleteTrucker', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('deleteTrucker', 'truckerId', truckerId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all approved truckers for forwarder
         * @summary Gets all approved Truckers for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApprovedTruckers: async (userId: string, forwarderId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllApprovedTruckers', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getAllApprovedTruckers', 'forwarderId', forwarderId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/approved`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all current available truckers for forwarder
         * @summary Gets all registered Truckers for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTruckersForForwarder: async (userId: string, forwarderId: number, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllTruckersForForwarder', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getAllTruckersForForwarder', 'forwarderId', forwarderId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets trucker information by his id 
         * @summary Gets trucker by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckerById: async (userId: string, forwarderId: number, truckerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTruckerById', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getTruckerById', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('getTruckerById', 'truckerId', truckerId)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates truck driver information
         * @summary Updates trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to get
         * @param {UpdateTruckerForForwarderTO} updateTruckerForForwarderTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrucker: async (userId: string, forwarderId: number, truckerId: string, updateTruckerForForwarderTO: UpdateTruckerForForwarderTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateTrucker', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('updateTrucker', 'forwarderId', forwarderId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('updateTrucker', 'truckerId', truckerId)
            // verify required parameter 'updateTruckerForForwarderTO' is not null or undefined
            assertParamExists('updateTrucker', 'updateTruckerForForwarderTO', updateTruckerForForwarderTO)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/truckers/{truckerId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTruckerForForwarderTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForwarderTruckersApiApi - functional programming interface
 * @export
 */
export const ForwarderTruckersApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForwarderTruckersApiApiAxiosParamCreator(configuration)
    return {
        /**
         * Approves a trucker to have this forwarder
         * @summary Approves a trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to update
         * @param {ForwarderApproval} forwarderApproval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTrucker(userId: string, forwarderId: number, truckerId: string, forwarderApproval: ForwarderApproval, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveTrucker(userId, forwarderId, truckerId, forwarderApproval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderTruckersApiApi.approveTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes current trucker
         * @summary Delete trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrucker(userId: string, forwarderId: number, truckerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrucker(userId, forwarderId, truckerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderTruckersApiApi.deleteTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets all approved truckers for forwarder
         * @summary Gets all approved Truckers for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllApprovedTruckers(userId: string, forwarderId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllApprovedTruckers(userId, forwarderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderTruckersApiApi.getAllApprovedTruckers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets all current available truckers for forwarder
         * @summary Gets all registered Truckers for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTruckersForForwarder(userId: string, forwarderId: number, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TruckerForForwarderTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTruckersForForwarder(userId, forwarderId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderTruckersApiApi.getAllTruckersForForwarder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets trucker information by his id 
         * @summary Gets trucker by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTruckerById(userId: string, forwarderId: number, truckerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckerForForwarderTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckerById(userId, forwarderId, truckerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderTruckersApiApi.getTruckerById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates truck driver information
         * @summary Updates trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to get
         * @param {UpdateTruckerForForwarderTO} updateTruckerForForwarderTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrucker(userId: string, forwarderId: number, truckerId: string, updateTruckerForForwarderTO: UpdateTruckerForForwarderTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckerForForwarderTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrucker(userId, forwarderId, truckerId, updateTruckerForForwarderTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderTruckersApiApi.updateTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForwarderTruckersApiApi - factory interface
 * @export
 */
export const ForwarderTruckersApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForwarderTruckersApiApiFp(configuration)
    return {
        /**
         * Approves a trucker to have this forwarder
         * @summary Approves a trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to update
         * @param {ForwarderApproval} forwarderApproval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTrucker(userId: string, forwarderId: number, truckerId: string, forwarderApproval: ForwarderApproval, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.approveTrucker(userId, forwarderId, truckerId, forwarderApproval, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes current trucker
         * @summary Delete trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrucker(userId: string, forwarderId: number, truckerId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTrucker(userId, forwarderId, truckerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all approved truckers for forwarder
         * @summary Gets all approved Truckers for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApprovedTruckers(userId: string, forwarderId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.getAllApprovedTruckers(userId, forwarderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all current available truckers for forwarder
         * @summary Gets all registered Truckers for forwarder
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTruckersForForwarder(userId: string, forwarderId: number, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TruckerForForwarderTO>> {
            return localVarFp.getAllTruckersForForwarder(userId, forwarderId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets trucker information by his id 
         * @summary Gets trucker by id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckerById(userId: string, forwarderId: number, truckerId: string, options?: RawAxiosRequestConfig): AxiosPromise<TruckerForForwarderTO> {
            return localVarFp.getTruckerById(userId, forwarderId, truckerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates truck driver information
         * @summary Updates trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {string} truckerId id of the trucker to get
         * @param {UpdateTruckerForForwarderTO} updateTruckerForForwarderTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrucker(userId: string, forwarderId: number, truckerId: string, updateTruckerForForwarderTO: UpdateTruckerForForwarderTO, options?: RawAxiosRequestConfig): AxiosPromise<TruckerForForwarderTO> {
            return localVarFp.updateTrucker(userId, forwarderId, truckerId, updateTruckerForForwarderTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForwarderTruckersApiApi - object-oriented interface
 * @export
 * @class ForwarderTruckersApiApi
 * @extends {BaseAPI}
 */
export class ForwarderTruckersApiApi extends BaseAPI {
    /**
     * Approves a trucker to have this forwarder
     * @summary Approves a trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId id of the trucker to update
     * @param {ForwarderApproval} forwarderApproval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderTruckersApiApi
     */
    public approveTrucker(userId: string, forwarderId: number, truckerId: string, forwarderApproval: ForwarderApproval, options?: RawAxiosRequestConfig) {
        return ForwarderTruckersApiApiFp(this.configuration).approveTrucker(userId, forwarderId, truckerId, forwarderApproval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes current trucker
     * @summary Delete trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId id of the trucker to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderTruckersApiApi
     */
    public deleteTrucker(userId: string, forwarderId: number, truckerId: string, options?: RawAxiosRequestConfig) {
        return ForwarderTruckersApiApiFp(this.configuration).deleteTrucker(userId, forwarderId, truckerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all approved truckers for forwarder
     * @summary Gets all approved Truckers for forwarder
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderTruckersApiApi
     */
    public getAllApprovedTruckers(userId: string, forwarderId: number, options?: RawAxiosRequestConfig) {
        return ForwarderTruckersApiApiFp(this.configuration).getAllApprovedTruckers(userId, forwarderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all current available truckers for forwarder
     * @summary Gets all registered Truckers for forwarder
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderTruckersApiApi
     */
    public getAllTruckersForForwarder(userId: string, forwarderId: number, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return ForwarderTruckersApiApiFp(this.configuration).getAllTruckersForForwarder(userId, forwarderId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets trucker information by his id 
     * @summary Gets trucker by id
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderTruckersApiApi
     */
    public getTruckerById(userId: string, forwarderId: number, truckerId: string, options?: RawAxiosRequestConfig) {
        return ForwarderTruckersApiApiFp(this.configuration).getTruckerById(userId, forwarderId, truckerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates truck driver information
     * @summary Updates trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {string} truckerId id of the trucker to get
     * @param {UpdateTruckerForForwarderTO} updateTruckerForForwarderTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderTruckersApiApi
     */
    public updateTrucker(userId: string, forwarderId: number, truckerId: string, updateTruckerForForwarderTO: UpdateTruckerForForwarderTO, options?: RawAxiosRequestConfig) {
        return ForwarderTruckersApiApiFp(this.configuration).updateTrucker(userId, forwarderId, truckerId, updateTruckerForForwarderTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ForwarderUserApi - axios parameter creator
 * @export
 */
export const ForwarderUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates a Forwarder User Role
         * @param {string} userId Admin user id
         * @param {ForwarderUserTO} forwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleForForwarderUser: async (userId: string, forwarderUserTO: ForwarderUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addRoleForForwarderUser', 'userId', userId)
            // verify required parameter 'forwarderUserTO' is not null or undefined
            assertParamExists('addRoleForForwarderUser', 'forwarderUserTO', forwarderUserTO)
            const localVarPath = `/web/forwarderOperatorUsers/v1/{userId}/addForwarderUserRole`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forwarderUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Forwarder User
         * @param {string} userId Admin user id
         * @param {CreateForwarderUserTO} createForwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserForForwarder: async (userId: string, createForwarderUserTO: CreateForwarderUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createUserForForwarder', 'userId', userId)
            // verify required parameter 'createForwarderUserTO' is not null or undefined
            assertParamExists('createUserForForwarder', 'createForwarderUserTO', createForwarderUserTO)
            const localVarPath = `/web/forwarderOperatorUsers/v1/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createForwarderUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes forwarder user entity
         * @param {string} userId Admin user id
         * @param {string} forwarderUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeForwarderUserRole: async (userId: string, forwarderUserId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeForwarderUserRole', 'userId', userId)
            // verify required parameter 'forwarderUserId' is not null or undefined
            assertParamExists('removeForwarderUserRole', 'forwarderUserId', forwarderUserId)
            const localVarPath = `/web/forwarderOperatorUsers/v1/{userId}/{forwarderUserId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderUserId"}}`, encodeURIComponent(String(forwarderUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates forwarder user entity
         * @param {string} userId Admin user id
         * @param {ForwarderUserTO} forwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateForwarderUser: async (userId: string, forwarderUserTO: ForwarderUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateForwarderUser', 'userId', userId)
            // verify required parameter 'forwarderUserTO' is not null or undefined
            assertParamExists('updateForwarderUser', 'forwarderUserTO', forwarderUserTO)
            const localVarPath = `/web/forwarderOperatorUsers/v1/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forwarderUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForwarderUserApi - functional programming interface
 * @export
 */
export const ForwarderUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForwarderUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates a Forwarder User Role
         * @param {string} userId Admin user id
         * @param {ForwarderUserTO} forwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoleForForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForwarderUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoleForForwarderUser(userId, forwarderUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderUserApi.addRoleForForwarderUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a Forwarder User
         * @param {string} userId Admin user id
         * @param {CreateForwarderUserTO} createForwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserForForwarder(userId: string, createForwarderUserTO: CreateForwarderUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForwarderUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserForForwarder(userId, createForwarderUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderUserApi.createUserForForwarder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes forwarder user entity
         * @param {string} userId Admin user id
         * @param {string} forwarderUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeForwarderUserRole(userId: string, forwarderUserId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeForwarderUserRole(userId, forwarderUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderUserApi.removeForwarderUserRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates forwarder user entity
         * @param {string} userId Admin user id
         * @param {ForwarderUserTO} forwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForwarderUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateForwarderUser(userId, forwarderUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForwarderUserApi.updateForwarderUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForwarderUserApi - factory interface
 * @export
 */
export const ForwarderUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForwarderUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates a Forwarder User Role
         * @param {string} userId Admin user id
         * @param {ForwarderUserTO} forwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleForForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, options?: RawAxiosRequestConfig): AxiosPromise<ForwarderUserTO> {
            return localVarFp.addRoleForForwarderUser(userId, forwarderUserTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Forwarder User
         * @param {string} userId Admin user id
         * @param {CreateForwarderUserTO} createForwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserForForwarder(userId: string, createForwarderUserTO: CreateForwarderUserTO, options?: RawAxiosRequestConfig): AxiosPromise<ForwarderUserTO> {
            return localVarFp.createUserForForwarder(userId, createForwarderUserTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes forwarder user entity
         * @param {string} userId Admin user id
         * @param {string} forwarderUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeForwarderUserRole(userId: string, forwarderUserId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeForwarderUserRole(userId, forwarderUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates forwarder user entity
         * @param {string} userId Admin user id
         * @param {ForwarderUserTO} forwarderUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, options?: RawAxiosRequestConfig): AxiosPromise<ForwarderUserTO> {
            return localVarFp.updateForwarderUser(userId, forwarderUserTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForwarderUserApi - object-oriented interface
 * @export
 * @class ForwarderUserApi
 * @extends {BaseAPI}
 */
export class ForwarderUserApi extends BaseAPI {
    /**
     * 
     * @summary Updates a Forwarder User Role
     * @param {string} userId Admin user id
     * @param {ForwarderUserTO} forwarderUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderUserApi
     */
    public addRoleForForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, options?: RawAxiosRequestConfig) {
        return ForwarderUserApiFp(this.configuration).addRoleForForwarderUser(userId, forwarderUserTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Forwarder User
     * @param {string} userId Admin user id
     * @param {CreateForwarderUserTO} createForwarderUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderUserApi
     */
    public createUserForForwarder(userId: string, createForwarderUserTO: CreateForwarderUserTO, options?: RawAxiosRequestConfig) {
        return ForwarderUserApiFp(this.configuration).createUserForForwarder(userId, createForwarderUserTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes forwarder user entity
     * @param {string} userId Admin user id
     * @param {string} forwarderUserId User to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderUserApi
     */
    public removeForwarderUserRole(userId: string, forwarderUserId: string, options?: RawAxiosRequestConfig) {
        return ForwarderUserApiFp(this.configuration).removeForwarderUserRole(userId, forwarderUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates forwarder user entity
     * @param {string} userId Admin user id
     * @param {ForwarderUserTO} forwarderUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForwarderUserApi
     */
    public updateForwarderUser(userId: string, forwarderUserTO: ForwarderUserTO, options?: RawAxiosRequestConfig) {
        return ForwarderUserApiFp(this.configuration).updateForwarderUser(userId, forwarderUserTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IspsAdminUsersApi - axios parameter creator
 * @export
 */
export const IspsAdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes TruckerIdentification tries and automatically approves
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId Trucker id that needs to be reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTruckerIdentifications: async (userId: string, truckerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTruckerIdentifications', 'userId', userId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('deleteTruckerIdentifications', 'truckerId', truckerId)
            const localVarPath = `/web/v1/ispsAdminUser/users/{userId}/truckers/{truckerId}/changes/reset`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a request to delete the trucker
         * @summary Request to Delete Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTruckerRequest: async (userId: string, truckerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTruckerRequest', 'userId', userId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('deleteTruckerRequest', 'truckerId', truckerId)
            const localVarPath = `/web/v1/ispsAdminUser/users/{userId}/truckers/{truckerId}/changes/delete`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get for trucker in regards of identification and licensing
         * @summary get Isps Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be retrieved
         * @param {IspsTruckerTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIspsTrucker: async (userId: string, truckerId: string, request: IspsTruckerTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getIspsTrucker', 'userId', userId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('getIspsTrucker', 'truckerId', truckerId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('getIspsTrucker', 'request', request)
            const localVarPath = `/web/v1/ispsAdminUser/users/{userId}/truckers/{truckerId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates for trucker in regards of identification and licensing
         * @summary get Isps Truckers
         * @param {string} userId UUID that comes from firebase authentication
         * @param {GetIspsTruckersDocumentIdentificationStatusEnum} [documentIdentificationStatus] Document Identification Status
         * @param {GetIspsTruckersTerrorDatabaseStatusEnum} [terrorDatabaseStatus] Terror Database Status
         * @param {string} [truckerId] Trucker ID
         * @param {number} [page] Page number
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIspsTruckers: async (userId: string, documentIdentificationStatus?: GetIspsTruckersDocumentIdentificationStatusEnum, terrorDatabaseStatus?: GetIspsTruckersTerrorDatabaseStatusEnum, truckerId?: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getIspsTruckers', 'userId', userId)
            const localVarPath = `/web/v1/ispsAdminUser/users/{userId}/truckers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (documentIdentificationStatus !== undefined) {
                localVarQueryParameter['documentIdentificationStatus'] = documentIdentificationStatus;
            }

            if (terrorDatabaseStatus !== undefined) {
                localVarQueryParameter['terrorDatabaseStatus'] = terrorDatabaseStatus;
            }

            if (truckerId !== undefined) {
                localVarQueryParameter['truckerId'] = truckerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * cerate for trucker in regards of identification and licensing
         * @summary create isps trucker log
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be edited
         * @param {IspsTruckerChangeRequestTO} ispsTruckerChangeRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ispsTruckerLog: async (userId: string, truckerId: string, ispsTruckerChangeRequestTO: IspsTruckerChangeRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('ispsTruckerLog', 'userId', userId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('ispsTruckerLog', 'truckerId', truckerId)
            // verify required parameter 'ispsTruckerChangeRequestTO' is not null or undefined
            assertParamExists('ispsTruckerLog', 'ispsTruckerChangeRequestTO', ispsTruckerChangeRequestTO)
            const localVarPath = `/web/v1/ispsAdminUser/users/{userId}/truckers/{truckerId}/changes`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ispsTruckerChangeRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates for trucker in regards of identification and licensing
         * @summary Patch Isps Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be approved
         * @param {number} changeId Id of the change to be approved
         * @param {IspsChangeApprovalRequestTO} ispsChangeApprovalRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchIspsTrucker: async (userId: string, truckerId: string, changeId: number, ispsChangeApprovalRequestTO: IspsChangeApprovalRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchIspsTrucker', 'userId', userId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('patchIspsTrucker', 'truckerId', truckerId)
            // verify required parameter 'changeId' is not null or undefined
            assertParamExists('patchIspsTrucker', 'changeId', changeId)
            // verify required parameter 'ispsChangeApprovalRequestTO' is not null or undefined
            assertParamExists('patchIspsTrucker', 'ispsChangeApprovalRequestTO', ispsChangeApprovalRequestTO)
            const localVarPath = `/web/v1/ispsAdminUser/users/{userId}/trucker/{truckerId}/changes/{changeId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"truckerId"}}`, encodeURIComponent(String(truckerId)))
                .replace(`{${"changeId"}}`, encodeURIComponent(String(changeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ispsChangeApprovalRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IspsAdminUsersApi - functional programming interface
 * @export
 */
export const IspsAdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IspsAdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes TruckerIdentification tries and automatically approves
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId Trucker id that needs to be reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTruckerIdentifications(userId: string, truckerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IspsTruckerTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTruckerIdentifications(userId, truckerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsAdminUsersApi.deleteTruckerIdentifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send a request to delete the trucker
         * @summary Request to Delete Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTruckerRequest(userId: string, truckerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IspsTruckerTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTruckerRequest(userId, truckerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsAdminUsersApi.deleteTruckerRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get for trucker in regards of identification and licensing
         * @summary get Isps Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be retrieved
         * @param {IspsTruckerTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIspsTrucker(userId: string, truckerId: string, request: IspsTruckerTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IspsTruckerTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIspsTrucker(userId, truckerId, request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsAdminUsersApi.getIspsTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates for trucker in regards of identification and licensing
         * @summary get Isps Truckers
         * @param {string} userId UUID that comes from firebase authentication
         * @param {GetIspsTruckersDocumentIdentificationStatusEnum} [documentIdentificationStatus] Document Identification Status
         * @param {GetIspsTruckersTerrorDatabaseStatusEnum} [terrorDatabaseStatus] Terror Database Status
         * @param {string} [truckerId] Trucker ID
         * @param {number} [page] Page number
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIspsTruckers(userId: string, documentIdentificationStatus?: GetIspsTruckersDocumentIdentificationStatusEnum, terrorDatabaseStatus?: GetIspsTruckersTerrorDatabaseStatusEnum, truckerId?: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IspsTruckerTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIspsTruckers(userId, documentIdentificationStatus, terrorDatabaseStatus, truckerId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsAdminUsersApi.getIspsTruckers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * cerate for trucker in regards of identification and licensing
         * @summary create isps trucker log
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be edited
         * @param {IspsTruckerChangeRequestTO} ispsTruckerChangeRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ispsTruckerLog(userId: string, truckerId: string, ispsTruckerChangeRequestTO: IspsTruckerChangeRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IspsTruckerTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ispsTruckerLog(userId, truckerId, ispsTruckerChangeRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsAdminUsersApi.ispsTruckerLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates for trucker in regards of identification and licensing
         * @summary Patch Isps Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be approved
         * @param {number} changeId Id of the change to be approved
         * @param {IspsChangeApprovalRequestTO} ispsChangeApprovalRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchIspsTrucker(userId: string, truckerId: string, changeId: number, ispsChangeApprovalRequestTO: IspsChangeApprovalRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchIspsTrucker(userId, truckerId, changeId, ispsChangeApprovalRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsAdminUsersApi.patchIspsTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IspsAdminUsersApi - factory interface
 * @export
 */
export const IspsAdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IspsAdminUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes TruckerIdentification tries and automatically approves
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId Trucker id that needs to be reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTruckerIdentifications(userId: string, truckerId: string, options?: RawAxiosRequestConfig): AxiosPromise<IspsTruckerTO> {
            return localVarFp.deleteTruckerIdentifications(userId, truckerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Send a request to delete the trucker
         * @summary Request to Delete Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTruckerRequest(userId: string, truckerId: string, options?: RawAxiosRequestConfig): AxiosPromise<IspsTruckerTO> {
            return localVarFp.deleteTruckerRequest(userId, truckerId, options).then((request) => request(axios, basePath));
        },
        /**
         * get for trucker in regards of identification and licensing
         * @summary get Isps Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be retrieved
         * @param {IspsTruckerTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIspsTrucker(userId: string, truckerId: string, request: IspsTruckerTO, options?: RawAxiosRequestConfig): AxiosPromise<IspsTruckerTO> {
            return localVarFp.getIspsTrucker(userId, truckerId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates for trucker in regards of identification and licensing
         * @summary get Isps Truckers
         * @param {string} userId UUID that comes from firebase authentication
         * @param {GetIspsTruckersDocumentIdentificationStatusEnum} [documentIdentificationStatus] Document Identification Status
         * @param {GetIspsTruckersTerrorDatabaseStatusEnum} [terrorDatabaseStatus] Terror Database Status
         * @param {string} [truckerId] Trucker ID
         * @param {number} [page] Page number
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIspsTruckers(userId: string, documentIdentificationStatus?: GetIspsTruckersDocumentIdentificationStatusEnum, terrorDatabaseStatus?: GetIspsTruckersTerrorDatabaseStatusEnum, truckerId?: string, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<IspsTruckerTO>> {
            return localVarFp.getIspsTruckers(userId, documentIdentificationStatus, terrorDatabaseStatus, truckerId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * cerate for trucker in regards of identification and licensing
         * @summary create isps trucker log
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be edited
         * @param {IspsTruckerChangeRequestTO} ispsTruckerChangeRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ispsTruckerLog(userId: string, truckerId: string, ispsTruckerChangeRequestTO: IspsTruckerChangeRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<IspsTruckerTO> {
            return localVarFp.ispsTruckerLog(userId, truckerId, ispsTruckerChangeRequestTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates for trucker in regards of identification and licensing
         * @summary Patch Isps Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} truckerId UUID of trucker to be approved
         * @param {number} changeId Id of the change to be approved
         * @param {IspsChangeApprovalRequestTO} ispsChangeApprovalRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchIspsTrucker(userId: string, truckerId: string, changeId: number, ispsChangeApprovalRequestTO: IspsChangeApprovalRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchIspsTrucker(userId, truckerId, changeId, ispsChangeApprovalRequestTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IspsAdminUsersApi - object-oriented interface
 * @export
 * @class IspsAdminUsersApi
 * @extends {BaseAPI}
 */
export class IspsAdminUsersApi extends BaseAPI {
    /**
     * 
     * @summary Deletes TruckerIdentification tries and automatically approves
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} truckerId Trucker id that needs to be reset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsAdminUsersApi
     */
    public deleteTruckerIdentifications(userId: string, truckerId: string, options?: RawAxiosRequestConfig) {
        return IspsAdminUsersApiFp(this.configuration).deleteTruckerIdentifications(userId, truckerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a request to delete the trucker
     * @summary Request to Delete Trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} truckerId UUID of trucker to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsAdminUsersApi
     */
    public deleteTruckerRequest(userId: string, truckerId: string, options?: RawAxiosRequestConfig) {
        return IspsAdminUsersApiFp(this.configuration).deleteTruckerRequest(userId, truckerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get for trucker in regards of identification and licensing
     * @summary get Isps Trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} truckerId UUID of trucker to be retrieved
     * @param {IspsTruckerTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsAdminUsersApi
     */
    public getIspsTrucker(userId: string, truckerId: string, request: IspsTruckerTO, options?: RawAxiosRequestConfig) {
        return IspsAdminUsersApiFp(this.configuration).getIspsTrucker(userId, truckerId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * @summary get Isps Truckers
     * @param {string} userId UUID that comes from firebase authentication
     * @param {GetIspsTruckersDocumentIdentificationStatusEnum} [documentIdentificationStatus] Document Identification Status
     * @param {GetIspsTruckersTerrorDatabaseStatusEnum} [terrorDatabaseStatus] Terror Database Status
     * @param {string} [truckerId] Trucker ID
     * @param {number} [page] Page number
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsAdminUsersApi
     */
    public getIspsTruckers(userId: string, documentIdentificationStatus?: GetIspsTruckersDocumentIdentificationStatusEnum, terrorDatabaseStatus?: GetIspsTruckersTerrorDatabaseStatusEnum, truckerId?: string, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return IspsAdminUsersApiFp(this.configuration).getIspsTruckers(userId, documentIdentificationStatus, terrorDatabaseStatus, truckerId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * cerate for trucker in regards of identification and licensing
     * @summary create isps trucker log
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} truckerId UUID of trucker to be edited
     * @param {IspsTruckerChangeRequestTO} ispsTruckerChangeRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsAdminUsersApi
     */
    public ispsTruckerLog(userId: string, truckerId: string, ispsTruckerChangeRequestTO: IspsTruckerChangeRequestTO, options?: RawAxiosRequestConfig) {
        return IspsAdminUsersApiFp(this.configuration).ispsTruckerLog(userId, truckerId, ispsTruckerChangeRequestTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates for trucker in regards of identification and licensing
     * @summary Patch Isps Trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} truckerId UUID of trucker to be approved
     * @param {number} changeId Id of the change to be approved
     * @param {IspsChangeApprovalRequestTO} ispsChangeApprovalRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsAdminUsersApi
     */
    public patchIspsTrucker(userId: string, truckerId: string, changeId: number, ispsChangeApprovalRequestTO: IspsChangeApprovalRequestTO, options?: RawAxiosRequestConfig) {
        return IspsAdminUsersApiFp(this.configuration).patchIspsTrucker(userId, truckerId, changeId, ispsChangeApprovalRequestTO, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetIspsTruckersDocumentIdentificationStatusEnum = {
    Accepted: 'ACCEPTED',
    Pending: 'PENDING',
    Denied: 'DENIED',
    Warn: 'WARN',
    DeniedPreliminary: 'DENIED_PRELIMINARY',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED',
    Duplicate: 'DUPLICATE',
    IdentityNotMatchingOnReverification: 'IDENTITY_NOT_MATCHING_ON_REVERIFICATION',
    Failed: 'FAILED'
} as const;
export type GetIspsTruckersDocumentIdentificationStatusEnum = typeof GetIspsTruckersDocumentIdentificationStatusEnum[keyof typeof GetIspsTruckersDocumentIdentificationStatusEnum];
/**
 * @export
 */
export const GetIspsTruckersTerrorDatabaseStatusEnum = {
    Ok: 'OK',
    Warning: 'WARNING',
    RedAlert: 'RED_ALERT',
    ManuallyApproved: 'MANUALLY_APPROVED',
    ManuallyDenied: 'MANUALLY_DENIED'
} as const;
export type GetIspsTruckersTerrorDatabaseStatusEnum = typeof GetIspsTruckersTerrorDatabaseStatusEnum[keyof typeof GetIspsTruckersTerrorDatabaseStatusEnum];


/**
 * IspsLicensesApi - axios parameter creator
 * @export
 */
export const IspsLicensesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Migrates the license from old trucker to new trucker
         * @summary Migrates ISPS License to new Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} licenseId ID of the license to be migrated
         * @param {MigrateISPSLicenseRequestTO} migrateISPSLicenseRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateISPSLicenseToNewTrucker: async (userId: string, licenseId: string, migrateISPSLicenseRequestTO: MigrateISPSLicenseRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('migrateISPSLicenseToNewTrucker', 'userId', userId)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('migrateISPSLicenseToNewTrucker', 'licenseId', licenseId)
            // verify required parameter 'migrateISPSLicenseRequestTO' is not null or undefined
            assertParamExists('migrateISPSLicenseToNewTrucker', 'migrateISPSLicenseRequestTO', migrateISPSLicenseRequestTO)
            const localVarPath = `/web/v1/ispsLicense/users/{userId}/licenses/{licenseId}/migrateLicense`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateISPSLicenseRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IspsLicensesApi - functional programming interface
 * @export
 */
export const IspsLicensesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IspsLicensesApiAxiosParamCreator(configuration)
    return {
        /**
         * Migrates the license from old trucker to new trucker
         * @summary Migrates ISPS License to new Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} licenseId ID of the license to be migrated
         * @param {MigrateISPSLicenseRequestTO} migrateISPSLicenseRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateISPSLicenseToNewTrucker(userId: string, licenseId: string, migrateISPSLicenseRequestTO: MigrateISPSLicenseRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateISPSLicenseToNewTrucker(userId, licenseId, migrateISPSLicenseRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsLicensesApi.migrateISPSLicenseToNewTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IspsLicensesApi - factory interface
 * @export
 */
export const IspsLicensesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IspsLicensesApiFp(configuration)
    return {
        /**
         * Migrates the license from old trucker to new trucker
         * @summary Migrates ISPS License to new Trucker
         * @param {string} userId UUID that comes from firebase authentication
         * @param {string} licenseId ID of the license to be migrated
         * @param {MigrateISPSLicenseRequestTO} migrateISPSLicenseRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateISPSLicenseToNewTrucker(userId: string, licenseId: string, migrateISPSLicenseRequestTO: MigrateISPSLicenseRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.migrateISPSLicenseToNewTrucker(userId, licenseId, migrateISPSLicenseRequestTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IspsLicensesApi - object-oriented interface
 * @export
 * @class IspsLicensesApi
 * @extends {BaseAPI}
 */
export class IspsLicensesApi extends BaseAPI {
    /**
     * Migrates the license from old trucker to new trucker
     * @summary Migrates ISPS License to new Trucker
     * @param {string} userId UUID that comes from firebase authentication
     * @param {string} licenseId ID of the license to be migrated
     * @param {MigrateISPSLicenseRequestTO} migrateISPSLicenseRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsLicensesApi
     */
    public migrateISPSLicenseToNewTrucker(userId: string, licenseId: string, migrateISPSLicenseRequestTO: MigrateISPSLicenseRequestTO, options?: RawAxiosRequestConfig) {
        return IspsLicensesApiFp(this.configuration).migrateISPSLicenseToNewTrucker(userId, licenseId, migrateISPSLicenseRequestTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IspsTerminalOperatorBlocklistApi - axios parameter creator
 * @export
 */
export const IspsTerminalOperatorBlocklistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates blocklist entry for isps terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {TruckerBlocklistRequestTO} truckerBlocklistRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBlockListEntryForIspsTerminal: async (userId: string, facilityId: number, truckerBlocklistRequestTO: TruckerBlocklistRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createBlockListEntryForIspsTerminal', 'userId', userId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('createBlockListEntryForIspsTerminal', 'facilityId', facilityId)
            // verify required parameter 'truckerBlocklistRequestTO' is not null or undefined
            assertParamExists('createBlockListEntryForIspsTerminal', 'truckerBlocklistRequestTO', truckerBlocklistRequestTO)
            const localVarPath = `/web/ispsfacilities/v1/users/{userId}/facilities/{facilityId}/blocks`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(truckerBlocklistRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes  blocklist entry for isps terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {number} blocklistId id of blocklist entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockListEntryForIspsTerminal: async (userId: string, facilityId: number, blocklistId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteBlockListEntryForIspsTerminal', 'userId', userId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('deleteBlockListEntryForIspsTerminal', 'facilityId', facilityId)
            // verify required parameter 'blocklistId' is not null or undefined
            assertParamExists('deleteBlockListEntryForIspsTerminal', 'blocklistId', blocklistId)
            const localVarPath = `/web/ispsfacilities/v1/users/{userId}/facilities/{facilityId}/blocks/{blocklistId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"blocklistId"}}`, encodeURIComponent(String(blocklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes  blocklist entry for isps terminal by truckerId
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {string} truckerId id of trucker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockListEntryForIspsTerminalByTrucker: async (userId: string, facilityId: number, truckerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteBlockListEntryForIspsTerminalByTrucker', 'userId', userId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('deleteBlockListEntryForIspsTerminalByTrucker', 'facilityId', facilityId)
            // verify required parameter 'truckerId' is not null or undefined
            assertParamExists('deleteBlockListEntryForIspsTerminalByTrucker', 'truckerId', truckerId)
            const localVarPath = `/web/ispsfacilities/v1/users/{userId}/facilities/{facilityId}/blocks`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (truckerId !== undefined) {
                localVarQueryParameter['truckerId'] = truckerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all blocklist entries of a facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlocklistEntiresForFacility: async (userId: string, facilityId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBlocklistEntiresForFacility', 'userId', userId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('getBlocklistEntiresForFacility', 'facilityId', facilityId)
            const localVarPath = `/web/ispsfacilities/v1/users/{userId}/facilities/{facilityId}/blocks`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IspsTerminalOperatorBlocklistApi - functional programming interface
 * @export
 */
export const IspsTerminalOperatorBlocklistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IspsTerminalOperatorBlocklistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates blocklist entry for isps terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {TruckerBlocklistRequestTO} truckerBlocklistRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBlockListEntryForIspsTerminal(userId: string, facilityId: number, truckerBlocklistRequestTO: TruckerBlocklistRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckerBlocklistResponseTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBlockListEntryForIspsTerminal(userId, facilityId, truckerBlocklistRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsTerminalOperatorBlocklistApi.createBlockListEntryForIspsTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes  blocklist entry for isps terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {number} blocklistId id of blocklist entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBlockListEntryForIspsTerminal(userId: string, facilityId: number, blocklistId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBlockListEntryForIspsTerminal(userId, facilityId, blocklistId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsTerminalOperatorBlocklistApi.deleteBlockListEntryForIspsTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes  blocklist entry for isps terminal by truckerId
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {string} truckerId id of trucker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBlockListEntryForIspsTerminalByTrucker(userId: string, facilityId: number, truckerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBlockListEntryForIspsTerminalByTrucker(userId, facilityId, truckerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsTerminalOperatorBlocklistApi.deleteBlockListEntryForIspsTerminalByTrucker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all blocklist entries of a facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlocklistEntiresForFacility(userId: string, facilityId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TruckerBlocklistResponseTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlocklistEntiresForFacility(userId, facilityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IspsTerminalOperatorBlocklistApi.getBlocklistEntiresForFacility']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IspsTerminalOperatorBlocklistApi - factory interface
 * @export
 */
export const IspsTerminalOperatorBlocklistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IspsTerminalOperatorBlocklistApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates blocklist entry for isps terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {TruckerBlocklistRequestTO} truckerBlocklistRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBlockListEntryForIspsTerminal(userId: string, facilityId: number, truckerBlocklistRequestTO: TruckerBlocklistRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<TruckerBlocklistResponseTO> {
            return localVarFp.createBlockListEntryForIspsTerminal(userId, facilityId, truckerBlocklistRequestTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes  blocklist entry for isps terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {number} blocklistId id of blocklist entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockListEntryForIspsTerminal(userId: string, facilityId: number, blocklistId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBlockListEntryForIspsTerminal(userId, facilityId, blocklistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes  blocklist entry for isps terminal by truckerId
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested facility
         * @param {string} truckerId id of trucker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockListEntryForIspsTerminalByTrucker(userId: string, facilityId: number, truckerId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBlockListEntryForIspsTerminalByTrucker(userId, facilityId, truckerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all blocklist entries of a facility
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlocklistEntiresForFacility(userId: string, facilityId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TruckerBlocklistResponseTO>> {
            return localVarFp.getBlocklistEntiresForFacility(userId, facilityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IspsTerminalOperatorBlocklistApi - object-oriented interface
 * @export
 * @class IspsTerminalOperatorBlocklistApi
 * @extends {BaseAPI}
 */
export class IspsTerminalOperatorBlocklistApi extends BaseAPI {
    /**
     * 
     * @summary Creates blocklist entry for isps terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of requested facility
     * @param {TruckerBlocklistRequestTO} truckerBlocklistRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsTerminalOperatorBlocklistApi
     */
    public createBlockListEntryForIspsTerminal(userId: string, facilityId: number, truckerBlocklistRequestTO: TruckerBlocklistRequestTO, options?: RawAxiosRequestConfig) {
        return IspsTerminalOperatorBlocklistApiFp(this.configuration).createBlockListEntryForIspsTerminal(userId, facilityId, truckerBlocklistRequestTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes  blocklist entry for isps terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of requested facility
     * @param {number} blocklistId id of blocklist entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsTerminalOperatorBlocklistApi
     */
    public deleteBlockListEntryForIspsTerminal(userId: string, facilityId: number, blocklistId: number, options?: RawAxiosRequestConfig) {
        return IspsTerminalOperatorBlocklistApiFp(this.configuration).deleteBlockListEntryForIspsTerminal(userId, facilityId, blocklistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes  blocklist entry for isps terminal by truckerId
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of requested facility
     * @param {string} truckerId id of trucker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsTerminalOperatorBlocklistApi
     */
    public deleteBlockListEntryForIspsTerminalByTrucker(userId: string, facilityId: number, truckerId: string, options?: RawAxiosRequestConfig) {
        return IspsTerminalOperatorBlocklistApiFp(this.configuration).deleteBlockListEntryForIspsTerminalByTrucker(userId, facilityId, truckerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all blocklist entries of a facility
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId id of requested terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IspsTerminalOperatorBlocklistApi
     */
    public getBlocklistEntiresForFacility(userId: string, facilityId: number, options?: RawAxiosRequestConfig) {
        return IspsTerminalOperatorBlocklistApiFp(this.configuration).getBlocklistEntiresForFacility(userId, facilityId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MirrorAppUserVersionsApi - axios parameter creator
 * @export
 */
export const MirrorAppUserVersionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all specific versions per query filtering
         * @param {string} userId UUID that comes from firebase authentication
         * @param {GetAllHostedAppVersionsAppVersionEnum} appVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHostedAppVersions: async (userId: string, appVersion: GetAllHostedAppVersionsAppVersionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllHostedAppVersions', 'userId', userId)
            // verify required parameter 'appVersion' is not null or undefined
            assertParamExists('getAllHostedAppVersions', 'appVersion', appVersion)
            const localVarPath = `/web/mirrorAppUsers/v1/users/{userId}/appVersions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appVersion !== undefined) {
                localVarQueryParameter['app version'] = appVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MirrorAppUserVersionsApi - functional programming interface
 * @export
 */
export const MirrorAppUserVersionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MirrorAppUserVersionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all specific versions per query filtering
         * @param {string} userId UUID that comes from firebase authentication
         * @param {GetAllHostedAppVersionsAppVersionEnum} appVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHostedAppVersions(userId: string, appVersion: GetAllHostedAppVersionsAppVersionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppVersionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHostedAppVersions(userId, appVersion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MirrorAppUserVersionsApi.getAllHostedAppVersions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MirrorAppUserVersionsApi - factory interface
 * @export
 */
export const MirrorAppUserVersionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MirrorAppUserVersionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all specific versions per query filtering
         * @param {string} userId UUID that comes from firebase authentication
         * @param {GetAllHostedAppVersionsAppVersionEnum} appVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHostedAppVersions(userId: string, appVersion: GetAllHostedAppVersionsAppVersionEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<AppVersionResponse>> {
            return localVarFp.getAllHostedAppVersions(userId, appVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MirrorAppUserVersionsApi - object-oriented interface
 * @export
 * @class MirrorAppUserVersionsApi
 * @extends {BaseAPI}
 */
export class MirrorAppUserVersionsApi extends BaseAPI {
    /**
     * 
     * @summary Gets all specific versions per query filtering
     * @param {string} userId UUID that comes from firebase authentication
     * @param {GetAllHostedAppVersionsAppVersionEnum} appVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MirrorAppUserVersionsApi
     */
    public getAllHostedAppVersions(userId: string, appVersion: GetAllHostedAppVersionsAppVersionEnum, options?: RawAxiosRequestConfig) {
        return MirrorAppUserVersionsApiFp(this.configuration).getAllHostedAppVersions(userId, appVersion, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAllHostedAppVersionsAppVersionEnum = {
    Android: 'android',
    Ios: 'ios'
} as const;
export type GetAllHostedAppVersionsAppVersionEnum = typeof GetAllHostedAppVersionsAppVersionEnum[keyof typeof GetAllHostedAppVersionsAppVersionEnum];


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a specific statistic value
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {RangedTerminalStatisticsValueRequest} rangedTerminalStatisticsValueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticFloatValue: async (userId: string, terminalId: number, rangedTerminalStatisticsValueRequest: RangedTerminalStatisticsValueRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getStatisticFloatValue', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getStatisticFloatValue', 'terminalId', terminalId)
            // verify required parameter 'rangedTerminalStatisticsValueRequest' is not null or undefined
            assertParamExists('getStatisticFloatValue', 'rangedTerminalStatisticsValueRequest', rangedTerminalStatisticsValueRequest)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/statistics/floatValue`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rangedTerminalStatisticsValueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a specific statistic timeSeries
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {TimeSeriesRequest} timeSeriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSeries: async (userId: string, terminalId: number, timeSeriesRequest: TimeSeriesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTimeSeries', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getTimeSeries', 'terminalId', terminalId)
            // verify required parameter 'timeSeriesRequest' is not null or undefined
            assertParamExists('getTimeSeries', 'timeSeriesRequest', timeSeriesRequest)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/statistics/timeSeries`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSeriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a specific statistic value
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {RangedTerminalStatisticsValueRequest} rangedTerminalStatisticsValueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatisticFloatValue(userId: string, terminalId: number, rangedTerminalStatisticsValueRequest: RangedTerminalStatisticsValueRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangedTerminalStatisticsValueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatisticFloatValue(userId, terminalId, rangedTerminalStatisticsValueRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatisticsApi.getStatisticFloatValue']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a specific statistic timeSeries
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {TimeSeriesRequest} timeSeriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeSeries(userId: string, terminalId: number, timeSeriesRequest: TimeSeriesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSeriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeSeries(userId, terminalId, timeSeriesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatisticsApi.getTimeSeries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a specific statistic value
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {RangedTerminalStatisticsValueRequest} rangedTerminalStatisticsValueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticFloatValue(userId: string, terminalId: number, rangedTerminalStatisticsValueRequest: RangedTerminalStatisticsValueRequest, options?: RawAxiosRequestConfig): AxiosPromise<RangedTerminalStatisticsValueResponse> {
            return localVarFp.getStatisticFloatValue(userId, terminalId, rangedTerminalStatisticsValueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a specific statistic timeSeries
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {TimeSeriesRequest} timeSeriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSeries(userId: string, terminalId: number, timeSeriesRequest: TimeSeriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<TimeSeriesResponse> {
            return localVarFp.getTimeSeries(userId, terminalId, timeSeriesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @summary Gets a specific statistic value
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {RangedTerminalStatisticsValueRequest} rangedTerminalStatisticsValueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getStatisticFloatValue(userId: string, terminalId: number, rangedTerminalStatisticsValueRequest: RangedTerminalStatisticsValueRequest, options?: RawAxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getStatisticFloatValue(userId, terminalId, rangedTerminalStatisticsValueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a specific statistic timeSeries
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {TimeSeriesRequest} timeSeriesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getTimeSeries(userId: string, terminalId: number, timeSeriesRequest: TimeSeriesRequest, options?: RawAxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getTimeSeries(userId, terminalId, timeSeriesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TerminalOperatorTruckerApi - axios parameter creator
 * @export
 */
export const TerminalOperatorTruckerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all terminal operator bookings
         * @summary Get all Bookings
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId Internal FacilityId
         * @param {number} [page] page to be shown
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTerminalOperatorBookings: async (userId: string, facilityId: number, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listTerminalOperatorBookings', 'userId', userId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('listTerminalOperatorBookings', 'facilityId', facilityId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/facilities/{facilityId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reroute Trucker from booking
         * @param {string} userId Admin user id
         * @param {number} bookingId booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rerouteTruckerFromBooking: async (userId: string, bookingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('rerouteTruckerFromBooking', 'userId', userId)
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('rerouteTruckerFromBooking', 'bookingId', bookingId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/bookings/{bookingId}/reroute`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalOperatorTruckerApi - functional programming interface
 * @export
 */
export const TerminalOperatorTruckerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalOperatorTruckerApiAxiosParamCreator(configuration)
    return {
        /**
         * List all terminal operator bookings
         * @summary Get all Bookings
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId Internal FacilityId
         * @param {number} [page] page to be shown
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTerminalOperatorBookings(userId: string, facilityId: number, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalOperatorBookingTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTerminalOperatorBookings(userId, facilityId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalOperatorTruckerApi.listTerminalOperatorBookings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reroute Trucker from booking
         * @param {string} userId Admin user id
         * @param {number} bookingId booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rerouteTruckerFromBooking(userId: string, bookingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rerouteTruckerFromBooking(userId, bookingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalOperatorTruckerApi.rerouteTruckerFromBooking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TerminalOperatorTruckerApi - factory interface
 * @export
 */
export const TerminalOperatorTruckerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalOperatorTruckerApiFp(configuration)
    return {
        /**
         * List all terminal operator bookings
         * @summary Get all Bookings
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId Internal FacilityId
         * @param {number} [page] page to be shown
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTerminalOperatorBookings(userId: string, facilityId: number, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TerminalOperatorBookingTO>> {
            return localVarFp.listTerminalOperatorBookings(userId, facilityId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reroute Trucker from booking
         * @param {string} userId Admin user id
         * @param {number} bookingId booking id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rerouteTruckerFromBooking(userId: string, bookingId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.rerouteTruckerFromBooking(userId, bookingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalOperatorTruckerApi - object-oriented interface
 * @export
 * @class TerminalOperatorTruckerApi
 * @extends {BaseAPI}
 */
export class TerminalOperatorTruckerApi extends BaseAPI {
    /**
     * List all terminal operator bookings
     * @summary Get all Bookings
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId Internal FacilityId
     * @param {number} [page] page to be shown
     * @param {number} [size] page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalOperatorTruckerApi
     */
    public listTerminalOperatorBookings(userId: string, facilityId: number, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return TerminalOperatorTruckerApiFp(this.configuration).listTerminalOperatorBookings(userId, facilityId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reroute Trucker from booking
     * @param {string} userId Admin user id
     * @param {number} bookingId booking id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalOperatorTruckerApi
     */
    public rerouteTruckerFromBooking(userId: string, bookingId: number, options?: RawAxiosRequestConfig) {
        return TerminalOperatorTruckerApiFp(this.configuration).rerouteTruckerFromBooking(userId, bookingId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TerminalOperatorUserApi - axios parameter creator
 * @export
 */
export const TerminalOperatorUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates a terminal operator User Role
         * @param {string} userId Admin user id
         * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTerminalOperatorUserRole: async (userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addTerminalOperatorUserRole', 'userId', userId)
            // verify required parameter 'terminalOperatorUserTO' is not null or undefined
            assertParamExists('addTerminalOperatorUserRole', 'terminalOperatorUserTO', terminalOperatorUserTO)
            const localVarPath = `/web/terminalOperatorUsers/v1/{userId}/addTerminalOperatorUserRole`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalOperatorUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Terminal Operator User
         * @param {string} userId Admin user id
         * @param {CreateTerminalOperatorUserTO} createTerminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserForTerminalOperator: async (userId: string, createTerminalOperatorUserTO: CreateTerminalOperatorUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createUserForTerminalOperator', 'userId', userId)
            // verify required parameter 'createTerminalOperatorUserTO' is not null or undefined
            assertParamExists('createUserForTerminalOperator', 'createTerminalOperatorUserTO', createTerminalOperatorUserTO)
            const localVarPath = `/web/terminalOperatorUsers/v1/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTerminalOperatorUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes terminal user entity
         * @param {string} userId Admin user id
         * @param {string} terminalOperatorUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTerminalOperatorUserRole: async (userId: string, terminalOperatorUserId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeTerminalOperatorUserRole', 'userId', userId)
            // verify required parameter 'terminalOperatorUserId' is not null or undefined
            assertParamExists('removeTerminalOperatorUserRole', 'terminalOperatorUserId', terminalOperatorUserId)
            const localVarPath = `/web/terminalOperatorUsers/v1/{userId}/{terminalOperatorUserId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalOperatorUserId"}}`, encodeURIComponent(String(terminalOperatorUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates terminal operator user entity
         * @param {string} userId Admin user id
         * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTerminalOperatorUser: async (userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateTerminalOperatorUser', 'userId', userId)
            // verify required parameter 'terminalOperatorUserTO' is not null or undefined
            assertParamExists('updateTerminalOperatorUser', 'terminalOperatorUserTO', terminalOperatorUserTO)
            const localVarPath = `/web/terminalOperatorUsers/v1/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalOperatorUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalOperatorUserApi - functional programming interface
 * @export
 */
export const TerminalOperatorUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalOperatorUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates a terminal operator User Role
         * @param {string} userId Admin user id
         * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTerminalOperatorUserRole(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalOperatorUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTerminalOperatorUserRole(userId, terminalOperatorUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalOperatorUserApi.addTerminalOperatorUserRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a Terminal Operator User
         * @param {string} userId Admin user id
         * @param {CreateTerminalOperatorUserTO} createTerminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserForTerminalOperator(userId: string, createTerminalOperatorUserTO: CreateTerminalOperatorUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalOperatorUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserForTerminalOperator(userId, createTerminalOperatorUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalOperatorUserApi.createUserForTerminalOperator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes terminal user entity
         * @param {string} userId Admin user id
         * @param {string} terminalOperatorUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTerminalOperatorUserRole(userId: string, terminalOperatorUserId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTerminalOperatorUserRole(userId, terminalOperatorUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalOperatorUserApi.removeTerminalOperatorUserRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates terminal operator user entity
         * @param {string} userId Admin user id
         * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTerminalOperatorUser(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalOperatorUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTerminalOperatorUser(userId, terminalOperatorUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalOperatorUserApi.updateTerminalOperatorUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TerminalOperatorUserApi - factory interface
 * @export
 */
export const TerminalOperatorUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalOperatorUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates a terminal operator User Role
         * @param {string} userId Admin user id
         * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTerminalOperatorUserRole(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options?: RawAxiosRequestConfig): AxiosPromise<TerminalOperatorUserTO> {
            return localVarFp.addTerminalOperatorUserRole(userId, terminalOperatorUserTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Terminal Operator User
         * @param {string} userId Admin user id
         * @param {CreateTerminalOperatorUserTO} createTerminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserForTerminalOperator(userId: string, createTerminalOperatorUserTO: CreateTerminalOperatorUserTO, options?: RawAxiosRequestConfig): AxiosPromise<TerminalOperatorUserTO> {
            return localVarFp.createUserForTerminalOperator(userId, createTerminalOperatorUserTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes terminal user entity
         * @param {string} userId Admin user id
         * @param {string} terminalOperatorUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTerminalOperatorUserRole(userId: string, terminalOperatorUserId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeTerminalOperatorUserRole(userId, terminalOperatorUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates terminal operator user entity
         * @param {string} userId Admin user id
         * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTerminalOperatorUser(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options?: RawAxiosRequestConfig): AxiosPromise<TerminalOperatorUserTO> {
            return localVarFp.updateTerminalOperatorUser(userId, terminalOperatorUserTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalOperatorUserApi - object-oriented interface
 * @export
 * @class TerminalOperatorUserApi
 * @extends {BaseAPI}
 */
export class TerminalOperatorUserApi extends BaseAPI {
    /**
     * 
     * @summary Updates a terminal operator User Role
     * @param {string} userId Admin user id
     * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalOperatorUserApi
     */
    public addTerminalOperatorUserRole(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options?: RawAxiosRequestConfig) {
        return TerminalOperatorUserApiFp(this.configuration).addTerminalOperatorUserRole(userId, terminalOperatorUserTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Terminal Operator User
     * @param {string} userId Admin user id
     * @param {CreateTerminalOperatorUserTO} createTerminalOperatorUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalOperatorUserApi
     */
    public createUserForTerminalOperator(userId: string, createTerminalOperatorUserTO: CreateTerminalOperatorUserTO, options?: RawAxiosRequestConfig) {
        return TerminalOperatorUserApiFp(this.configuration).createUserForTerminalOperator(userId, createTerminalOperatorUserTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes terminal user entity
     * @param {string} userId Admin user id
     * @param {string} terminalOperatorUserId User to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalOperatorUserApi
     */
    public removeTerminalOperatorUserRole(userId: string, terminalOperatorUserId: string, options?: RawAxiosRequestConfig) {
        return TerminalOperatorUserApiFp(this.configuration).removeTerminalOperatorUserRole(userId, terminalOperatorUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates terminal operator user entity
     * @param {string} userId Admin user id
     * @param {TerminalOperatorUserTO} terminalOperatorUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalOperatorUserApi
     */
    public updateTerminalOperatorUser(userId: string, terminalOperatorUserTO: TerminalOperatorUserTO, options?: RawAxiosRequestConfig) {
        return TerminalOperatorUserApiFp(this.configuration).updateTerminalOperatorUser(userId, terminalOperatorUserTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TerminalUsersApi - axios parameter creator
 * @export
 */
export const TerminalUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/web/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all terminals for user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTerminalsForUser', 'userId', userId)
            const localVarPath = `/web/v1/users/{userId}/terminals`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user by Id
         * @summary Get user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/web/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * resets password for terminal operator
         * @summary reset password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPassword: ResetPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassword' is not null or undefined
            assertParamExists('resetPassword', 'resetPassword', resetPassword)
            const localVarPath = `/web/v1/users/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {WebappUser} webappUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, webappUser: WebappUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'webappUser' is not null or undefined
            assertParamExists('updateUser', 'webappUser', webappUser)
            const localVarPath = `/web/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webappUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * verify email for terminal operator
         * @summary verify email
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (verifyEmail: VerifyEmail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmail' is not null or undefined
            assertParamExists('verifyEmail', 'verifyEmail', verifyEmail)
            const localVarPath = `/web/v1/users/verifyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalUsersApi - functional programming interface
 * @export
 */
export const TerminalUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalUsersApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all terminals for user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalsForUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Terminal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalsForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalUsersApi.getTerminalsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user by Id
         * @summary Get user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebappUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalUsersApi.getUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * resets password for terminal operator
         * @summary reset password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPassword: ResetPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalUsersApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {WebappUser} webappUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, webappUser: WebappUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebappUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, webappUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalUsersApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * verify email for terminal operator
         * @summary verify email
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(verifyEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalUsersApi.verifyEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TerminalUsersApi - factory interface
 * @export
 */
export const TerminalUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalUsersApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all terminals for user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalsForUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Terminal>> {
            return localVarFp.getTerminalsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user by Id
         * @summary Get user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<WebappUser> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * resets password for terminal operator
         * @summary reset password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPassword: ResetPassword, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Updates user
         * @param {string} userId UUID that comes from firebase authentication
         * @param {WebappUser} webappUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, webappUser: WebappUser, options?: RawAxiosRequestConfig): AxiosPromise<WebappUser> {
            return localVarFp.updateUser(userId, webappUser, options).then((request) => request(axios, basePath));
        },
        /**
         * verify email for terminal operator
         * @summary verify email
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.verifyEmail(verifyEmail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalUsersApi - object-oriented interface
 * @export
 * @class TerminalUsersApi
 * @extends {BaseAPI}
 */
export class TerminalUsersApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalUsersApi
     */
    public deleteUser(userId: string, options?: RawAxiosRequestConfig) {
        return TerminalUsersApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all terminals for user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalUsersApi
     */
    public getTerminalsForUser(userId: string, options?: RawAxiosRequestConfig) {
        return TerminalUsersApiFp(this.configuration).getTerminalsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user by Id
     * @summary Get user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalUsersApi
     */
    public getUserById(userId: string, options?: RawAxiosRequestConfig) {
        return TerminalUsersApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * resets password for terminal operator
     * @summary reset password
     * @param {ResetPassword} resetPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalUsersApi
     */
    public resetPassword(resetPassword: ResetPassword, options?: RawAxiosRequestConfig) {
        return TerminalUsersApiFp(this.configuration).resetPassword(resetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Updates user
     * @param {string} userId UUID that comes from firebase authentication
     * @param {WebappUser} webappUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalUsersApi
     */
    public updateUser(userId: string, webappUser: WebappUser, options?: RawAxiosRequestConfig) {
        return TerminalUsersApiFp(this.configuration).updateUser(userId, webappUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * verify email for terminal operator
     * @summary verify email
     * @param {VerifyEmail} verifyEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalUsersApi
     */
    public verifyEmail(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig) {
        return TerminalUsersApiFp(this.configuration).verifyEmail(verifyEmail, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TerminalsApi - axios parameter creator
 * @export
 */
export const TerminalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a specific terminal module by Id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} moduleId id of requested terminal module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleById: async (userId: string, terminalId: number, moduleId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getModuleById', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getModuleById', 'terminalId', terminalId)
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('getModuleById', 'moduleId', moduleId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/modules/{moduleId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)))
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all modules of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModulesForTerminal: async (userId: string, terminalId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getModulesForTerminal', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getModulesForTerminal', 'terminalId', terminalId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}/modules`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a specific terminal by Id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalById: async (userId: string, terminalId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTerminalById', 'userId', userId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getTerminalById', 'terminalId', terminalId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/terminals/{terminalId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalsApi - functional programming interface
 * @export
 */
export const TerminalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a specific terminal module by Id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} moduleId id of requested terminal module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleById(userId: string, terminalId: number, moduleId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalModule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleById(userId, terminalId, moduleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalsApi.getModuleById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all modules of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModulesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalModule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModulesForTerminal(userId, terminalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalsApi.getModulesForTerminal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a specific terminal by Id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalById(userId: string, terminalId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Terminal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalById(userId, terminalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminalsApi.getTerminalById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TerminalsApi - factory interface
 * @export
 */
export const TerminalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a specific terminal module by Id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {number} moduleId id of requested terminal module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleById(userId: string, terminalId: number, moduleId: number, options?: RawAxiosRequestConfig): AxiosPromise<TerminalModule> {
            return localVarFp.getModuleById(userId, terminalId, moduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all modules of a terminal
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModulesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TerminalModule>> {
            return localVarFp.getModulesForTerminal(userId, terminalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a specific terminal by Id
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} terminalId id of requested terminal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalById(userId: string, terminalId: number, options?: RawAxiosRequestConfig): AxiosPromise<Terminal> {
            return localVarFp.getTerminalById(userId, terminalId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminalsApi - object-oriented interface
 * @export
 * @class TerminalsApi
 * @extends {BaseAPI}
 */
export class TerminalsApi extends BaseAPI {
    /**
     * 
     * @summary Gets a specific terminal module by Id
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {number} moduleId id of requested terminal module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getModuleById(userId: string, terminalId: number, moduleId: number, options?: RawAxiosRequestConfig) {
        return TerminalsApiFp(this.configuration).getModuleById(userId, terminalId, moduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all modules of a terminal
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getModulesForTerminal(userId: string, terminalId: number, options?: RawAxiosRequestConfig) {
        return TerminalsApiFp(this.configuration).getModulesForTerminal(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a specific terminal by Id
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} terminalId id of requested terminal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalsApi
     */
    public getTerminalById(userId: string, terminalId: number, options?: RawAxiosRequestConfig) {
        return TerminalsApiFp(this.configuration).getTerminalById(userId, terminalId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrainOperatorBookingsApi - axios parameter creator
 * @export
 */
export const TrainOperatorBookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary confirms bookings from train operator
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} trainOperatorId id of train operator
         * @param {number} terminalId id of requested terminal
         * @param {ConfirmBookingEntries} confirmBookingEntries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmBookingEntries: async (userId: string, trainOperatorId: number, terminalId: number, confirmBookingEntries: ConfirmBookingEntries, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('confirmBookingEntries', 'userId', userId)
            // verify required parameter 'trainOperatorId' is not null or undefined
            assertParamExists('confirmBookingEntries', 'trainOperatorId', trainOperatorId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('confirmBookingEntries', 'terminalId', terminalId)
            // verify required parameter 'confirmBookingEntries' is not null or undefined
            assertParamExists('confirmBookingEntries', 'confirmBookingEntries', confirmBookingEntries)
            const localVarPath = `/web/trainOperators/v1/users/{userId}/trainOperators/{trainOperatorId}/terminals/{terminalId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"trainOperatorId"}}`, encodeURIComponent(String(trainOperatorId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmBookingEntries, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets current available train operators
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTrainOperators: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAvailableTrainOperators', 'userId', userId)
            const localVarPath = `/web/trainOperators/v1/users/{userId}/trainOperators`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets bookings for trainOperator and terminal(s)
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} trainOperatorId id of train operator
         * @param {number} terminalId id of requested terminal
         * @param {number} page page
         * @param {number} size size
         * @param {boolean} [active] boolean whether active or non active bookings should be supplied
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingsForTrainOperator: async (userId: string, trainOperatorId: number, terminalId: number, page: number, size: number, active?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBookingsForTrainOperator', 'userId', userId)
            // verify required parameter 'trainOperatorId' is not null or undefined
            assertParamExists('getBookingsForTrainOperator', 'trainOperatorId', trainOperatorId)
            // verify required parameter 'terminalId' is not null or undefined
            assertParamExists('getBookingsForTrainOperator', 'terminalId', terminalId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getBookingsForTrainOperator', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getBookingsForTrainOperator', 'size', size)
            const localVarPath = `/web/trainOperators/v1/users/{userId}/trainOperators/{trainOperatorId}/terminals/{terminalId}/bookings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"trainOperatorId"}}`, encodeURIComponent(String(trainOperatorId)))
                .replace(`{${"terminalId"}}`, encodeURIComponent(String(terminalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainOperatorBookingsApi - functional programming interface
 * @export
 */
export const TrainOperatorBookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainOperatorBookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary confirms bookings from train operator
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} trainOperatorId id of train operator
         * @param {number} terminalId id of requested terminal
         * @param {ConfirmBookingEntries} confirmBookingEntries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmBookingEntries(userId: string, trainOperatorId: number, terminalId: number, confirmBookingEntries: ConfirmBookingEntries, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmBookingEntries(userId, trainOperatorId, terminalId, confirmBookingEntries, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainOperatorBookingsApi.confirmBookingEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets current available train operators
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableTrainOperators(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrainOperator>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableTrainOperators(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainOperatorBookingsApi.getAvailableTrainOperators']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets bookings for trainOperator and terminal(s)
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} trainOperatorId id of train operator
         * @param {number} terminalId id of requested terminal
         * @param {number} page page
         * @param {number} size size
         * @param {boolean} [active] boolean whether active or non active bookings should be supplied
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingsForTrainOperator(userId: string, trainOperatorId: number, terminalId: number, page: number, size: number, active?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrainOperatorBooking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingsForTrainOperator(userId, trainOperatorId, terminalId, page, size, active, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainOperatorBookingsApi.getBookingsForTrainOperator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TrainOperatorBookingsApi - factory interface
 * @export
 */
export const TrainOperatorBookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainOperatorBookingsApiFp(configuration)
    return {
        /**
         * 
         * @summary confirms bookings from train operator
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} trainOperatorId id of train operator
         * @param {number} terminalId id of requested terminal
         * @param {ConfirmBookingEntries} confirmBookingEntries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmBookingEntries(userId: string, trainOperatorId: number, terminalId: number, confirmBookingEntries: ConfirmBookingEntries, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.confirmBookingEntries(userId, trainOperatorId, terminalId, confirmBookingEntries, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets current available train operators
         * @param {string} userId UUID that comes from firebase authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTrainOperators(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TrainOperator>> {
            return localVarFp.getAvailableTrainOperators(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets bookings for trainOperator and terminal(s)
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} trainOperatorId id of train operator
         * @param {number} terminalId id of requested terminal
         * @param {number} page page
         * @param {number} size size
         * @param {boolean} [active] boolean whether active or non active bookings should be supplied
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingsForTrainOperator(userId: string, trainOperatorId: number, terminalId: number, page: number, size: number, active?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<TrainOperatorBooking>> {
            return localVarFp.getBookingsForTrainOperator(userId, trainOperatorId, terminalId, page, size, active, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainOperatorBookingsApi - object-oriented interface
 * @export
 * @class TrainOperatorBookingsApi
 * @extends {BaseAPI}
 */
export class TrainOperatorBookingsApi extends BaseAPI {
    /**
     * 
     * @summary confirms bookings from train operator
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} trainOperatorId id of train operator
     * @param {number} terminalId id of requested terminal
     * @param {ConfirmBookingEntries} confirmBookingEntries 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainOperatorBookingsApi
     */
    public confirmBookingEntries(userId: string, trainOperatorId: number, terminalId: number, confirmBookingEntries: ConfirmBookingEntries, options?: RawAxiosRequestConfig) {
        return TrainOperatorBookingsApiFp(this.configuration).confirmBookingEntries(userId, trainOperatorId, terminalId, confirmBookingEntries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets current available train operators
     * @param {string} userId UUID that comes from firebase authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainOperatorBookingsApi
     */
    public getAvailableTrainOperators(userId: string, options?: RawAxiosRequestConfig) {
        return TrainOperatorBookingsApiFp(this.configuration).getAvailableTrainOperators(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets bookings for trainOperator and terminal(s)
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} trainOperatorId id of train operator
     * @param {number} terminalId id of requested terminal
     * @param {number} page page
     * @param {number} size size
     * @param {boolean} [active] boolean whether active or non active bookings should be supplied
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainOperatorBookingsApi
     */
    public getBookingsForTrainOperator(userId: string, trainOperatorId: number, terminalId: number, page: number, size: number, active?: boolean, options?: RawAxiosRequestConfig) {
        return TrainOperatorBookingsApiFp(this.configuration).getBookingsForTrainOperator(userId, trainOperatorId, terminalId, page, size, active, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrainOperatorUserApi - axios parameter creator
 * @export
 */
export const TrainOperatorUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates a Train operator User Role
         * @param {string} userId Admin user id
         * @param {TrainOperatorUserTO} trainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrainOperatorUserRole: async (userId: string, trainOperatorUserTO: TrainOperatorUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addTrainOperatorUserRole', 'userId', userId)
            // verify required parameter 'trainOperatorUserTO' is not null or undefined
            assertParamExists('addTrainOperatorUserRole', 'trainOperatorUserTO', trainOperatorUserTO)
            const localVarPath = `/web/trainOperatorUsers/v1/{userId}/addTrainOperatorUserRole`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainOperatorUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Train Operator User
         * @param {string} userId Admin user id
         * @param {CreateTrainOperatorUserTO} createTrainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserForTrainOperator: async (userId: string, createTrainOperatorUserTO: CreateTrainOperatorUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createUserForTrainOperator', 'userId', userId)
            // verify required parameter 'createTrainOperatorUserTO' is not null or undefined
            assertParamExists('createUserForTrainOperator', 'createTrainOperatorUserTO', createTrainOperatorUserTO)
            const localVarPath = `/web/trainOperatorUsers/v1/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTrainOperatorUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes train operator user entity
         * @param {string} userId Admin user id
         * @param {string} trainOperatorUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTrainOperatorUserRole: async (userId: string, trainOperatorUserId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeTrainOperatorUserRole', 'userId', userId)
            // verify required parameter 'trainOperatorUserId' is not null or undefined
            assertParamExists('removeTrainOperatorUserRole', 'trainOperatorUserId', trainOperatorUserId)
            const localVarPath = `/web/trainOperatorUsers/v1/{userId}/{trainOperatorUserId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"trainOperatorUserId"}}`, encodeURIComponent(String(trainOperatorUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates train operator user entity
         * @param {string} userId Admin user id
         * @param {TrainOperatorUserTO} trainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrainOperatorUser: async (userId: string, trainOperatorUserTO: TrainOperatorUserTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateTrainOperatorUser', 'userId', userId)
            // verify required parameter 'trainOperatorUserTO' is not null or undefined
            assertParamExists('updateTrainOperatorUser', 'trainOperatorUserTO', trainOperatorUserTO)
            const localVarPath = `/web/trainOperatorUsers/v1/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainOperatorUserTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainOperatorUserApi - functional programming interface
 * @export
 */
export const TrainOperatorUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainOperatorUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates a Train operator User Role
         * @param {string} userId Admin user id
         * @param {TrainOperatorUserTO} trainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTrainOperatorUserRole(userId: string, trainOperatorUserTO: TrainOperatorUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainOperatorUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTrainOperatorUserRole(userId, trainOperatorUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainOperatorUserApi.addTrainOperatorUserRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a Train Operator User
         * @param {string} userId Admin user id
         * @param {CreateTrainOperatorUserTO} createTrainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserForTrainOperator(userId: string, createTrainOperatorUserTO: CreateTrainOperatorUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainOperatorUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserForTrainOperator(userId, createTrainOperatorUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainOperatorUserApi.createUserForTrainOperator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes train operator user entity
         * @param {string} userId Admin user id
         * @param {string} trainOperatorUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTrainOperatorUserRole(userId: string, trainOperatorUserId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTrainOperatorUserRole(userId, trainOperatorUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainOperatorUserApi.removeTrainOperatorUserRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates train operator user entity
         * @param {string} userId Admin user id
         * @param {TrainOperatorUserTO} trainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrainOperatorUser(userId: string, trainOperatorUserTO: TrainOperatorUserTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainOperatorUserTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrainOperatorUser(userId, trainOperatorUserTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainOperatorUserApi.updateTrainOperatorUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TrainOperatorUserApi - factory interface
 * @export
 */
export const TrainOperatorUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainOperatorUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates a Train operator User Role
         * @param {string} userId Admin user id
         * @param {TrainOperatorUserTO} trainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrainOperatorUserRole(userId: string, trainOperatorUserTO: TrainOperatorUserTO, options?: RawAxiosRequestConfig): AxiosPromise<TrainOperatorUserTO> {
            return localVarFp.addTrainOperatorUserRole(userId, trainOperatorUserTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Train Operator User
         * @param {string} userId Admin user id
         * @param {CreateTrainOperatorUserTO} createTrainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserForTrainOperator(userId: string, createTrainOperatorUserTO: CreateTrainOperatorUserTO, options?: RawAxiosRequestConfig): AxiosPromise<TrainOperatorUserTO> {
            return localVarFp.createUserForTrainOperator(userId, createTrainOperatorUserTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes train operator user entity
         * @param {string} userId Admin user id
         * @param {string} trainOperatorUserId User to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTrainOperatorUserRole(userId: string, trainOperatorUserId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeTrainOperatorUserRole(userId, trainOperatorUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates train operator user entity
         * @param {string} userId Admin user id
         * @param {TrainOperatorUserTO} trainOperatorUserTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrainOperatorUser(userId: string, trainOperatorUserTO: TrainOperatorUserTO, options?: RawAxiosRequestConfig): AxiosPromise<TrainOperatorUserTO> {
            return localVarFp.updateTrainOperatorUser(userId, trainOperatorUserTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainOperatorUserApi - object-oriented interface
 * @export
 * @class TrainOperatorUserApi
 * @extends {BaseAPI}
 */
export class TrainOperatorUserApi extends BaseAPI {
    /**
     * 
     * @summary Updates a Train operator User Role
     * @param {string} userId Admin user id
     * @param {TrainOperatorUserTO} trainOperatorUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainOperatorUserApi
     */
    public addTrainOperatorUserRole(userId: string, trainOperatorUserTO: TrainOperatorUserTO, options?: RawAxiosRequestConfig) {
        return TrainOperatorUserApiFp(this.configuration).addTrainOperatorUserRole(userId, trainOperatorUserTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Train Operator User
     * @param {string} userId Admin user id
     * @param {CreateTrainOperatorUserTO} createTrainOperatorUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainOperatorUserApi
     */
    public createUserForTrainOperator(userId: string, createTrainOperatorUserTO: CreateTrainOperatorUserTO, options?: RawAxiosRequestConfig) {
        return TrainOperatorUserApiFp(this.configuration).createUserForTrainOperator(userId, createTrainOperatorUserTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes train operator user entity
     * @param {string} userId Admin user id
     * @param {string} trainOperatorUserId User to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainOperatorUserApi
     */
    public removeTrainOperatorUserRole(userId: string, trainOperatorUserId: string, options?: RawAxiosRequestConfig) {
        return TrainOperatorUserApiFp(this.configuration).removeTrainOperatorUserRole(userId, trainOperatorUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates train operator user entity
     * @param {string} userId Admin user id
     * @param {TrainOperatorUserTO} trainOperatorUserTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainOperatorUserApi
     */
    public updateTrainOperatorUser(userId: string, trainOperatorUserTO: TrainOperatorUserTO, options?: RawAxiosRequestConfig) {
        return TrainOperatorUserApiFp(this.configuration).updateTrainOperatorUser(userId, trainOperatorUserTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebappDepotsApi - axios parameter creator
 * @export
 */
export const WebappDepotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new tour object in the data base with the contained bookings
         * @summary Creates Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {TourCreationRequestTO} tourCreationRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTour: async (userId: string, depotId: number, tourCreationRequestTO: TourCreationRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createTour', 'userId', userId)
            // verify required parameter 'depotId' is not null or undefined
            assertParamExists('createTour', 'depotId', depotId)
            // verify required parameter 'tourCreationRequestTO' is not null or undefined
            assertParamExists('createTour', 'tourCreationRequestTO', tourCreationRequestTO)
            const localVarPath = `/web/v1/users/{userId}/depots/{depotId}/tours`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"depotId"}}`, encodeURIComponent(String(depotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tourCreationRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customers that belong to a depot
         * @summary Get list of all customers per depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomers: async (userId: string, depotId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllCustomers', 'userId', userId)
            // verify required parameter 'depotId' is not null or undefined
            assertParamExists('getAllCustomers', 'depotId', depotId)
            const localVarPath = `/web/v1/users/{userId}/depots/{depotId}/customers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"depotId"}}`, encodeURIComponent(String(depotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get status of multiple container requests
         * @summary Get status of multiple container requests
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getStatus', 'userId', userId)
            // verify required parameter 'depotId' is not null or undefined
            assertParamExists('getStatus', 'depotId', depotId)
            // verify required parameter 'depotGetStatusMultiRequestTO' is not null or undefined
            assertParamExists('getStatus', 'depotGetStatusMultiRequestTO', depotGetStatusMultiRequestTO)
            const localVarPath = `/web/v1/users/{userId}/depots/{depotId}/containers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"depotId"}}`, encodeURIComponent(String(depotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(depotGetStatusMultiRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebappDepotsApi - functional programming interface
 * @export
 */
export const WebappDepotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebappDepotsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new tour object in the data base with the contained bookings
         * @summary Creates Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {TourCreationRequestTO} tourCreationRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTour(userId: string, depotId: number, tourCreationRequestTO: TourCreationRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTour(userId, depotId, tourCreationRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebappDepotsApi.createTour']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all customers that belong to a depot
         * @summary Get list of all customers per depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomers(userId: string, depotId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomers(userId, depotId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebappDepotsApi.getAllCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get status of multiple container requests
         * @summary Get status of multiple container requests
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotGetStatusMultiResponseTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(userId, depotId, depotGetStatusMultiRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebappDepotsApi.getStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebappDepotsApi - factory interface
 * @export
 */
export const WebappDepotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebappDepotsApiFp(configuration)
    return {
        /**
         * Creates a new tour object in the data base with the contained bookings
         * @summary Creates Tour
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {TourCreationRequestTO} tourCreationRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTour(userId: string, depotId: number, tourCreationRequestTO: TourCreationRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<TourTO> {
            return localVarFp.createTour(userId, depotId, tourCreationRequestTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customers that belong to a depot
         * @summary Get list of all customers per depot-facilities
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomers(userId: string, depotId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerTO>> {
            return localVarFp.getAllCustomers(userId, depotId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get status of multiple container requests
         * @summary Get status of multiple container requests
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} depotId UUID that defines the depot
         * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<DepotGetStatusMultiResponseTO> {
            return localVarFp.getStatus(userId, depotId, depotGetStatusMultiRequestTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebappDepotsApi - object-oriented interface
 * @export
 * @class WebappDepotsApi
 * @extends {BaseAPI}
 */
export class WebappDepotsApi extends BaseAPI {
    /**
     * Creates a new tour object in the data base with the contained bookings
     * @summary Creates Tour
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} depotId UUID that defines the depot
     * @param {TourCreationRequestTO} tourCreationRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebappDepotsApi
     */
    public createTour(userId: string, depotId: number, tourCreationRequestTO: TourCreationRequestTO, options?: RawAxiosRequestConfig) {
        return WebappDepotsApiFp(this.configuration).createTour(userId, depotId, tourCreationRequestTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customers that belong to a depot
     * @summary Get list of all customers per depot-facilities
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} depotId UUID that defines the depot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebappDepotsApi
     */
    public getAllCustomers(userId: string, depotId: number, options?: RawAxiosRequestConfig) {
        return WebappDepotsApiFp(this.configuration).getAllCustomers(userId, depotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get status of multiple container requests
     * @summary Get status of multiple container requests
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} depotId UUID that defines the depot
     * @param {DepotGetStatusMultiRequestTO} depotGetStatusMultiRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebappDepotsApi
     */
    public getStatus(userId: string, depotId: number, depotGetStatusMultiRequestTO: DepotGetStatusMultiRequestTO, options?: RawAxiosRequestConfig) {
        return WebappDepotsApiFp(this.configuration).getStatus(userId, depotId, depotGetStatusMultiRequestTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebappStatisticsForForwarderApi - axios parameter creator
 * @export
 */
export const WebappStatisticsForForwarderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets statistical float value for the forwarders
         * @summary Gets a specific statistic value
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {ConstantForwarderStatisticValueRequestTO} constantForwarderStatisticValueRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticFloatValue1: async (userId: string, forwarderId: number, constantForwarderStatisticValueRequestTO: ConstantForwarderStatisticValueRequestTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getStatisticFloatValue1', 'userId', userId)
            // verify required parameter 'forwarderId' is not null or undefined
            assertParamExists('getStatisticFloatValue1', 'forwarderId', forwarderId)
            // verify required parameter 'constantForwarderStatisticValueRequestTO' is not null or undefined
            assertParamExists('getStatisticFloatValue1', 'constantForwarderStatisticValueRequestTO', constantForwarderStatisticValueRequestTO)
            const localVarPath = `/web/forwarderOperators/v1/users/{userId}/forwarders/{forwarderId}/statistics/floatValue`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"forwarderId"}}`, encodeURIComponent(String(forwarderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(constantForwarderStatisticValueRequestTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebappStatisticsForForwarderApi - functional programming interface
 * @export
 */
export const WebappStatisticsForForwarderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebappStatisticsForForwarderApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets statistical float value for the forwarders
         * @summary Gets a specific statistic value
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {ConstantForwarderStatisticValueRequestTO} constantForwarderStatisticValueRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatisticFloatValue1(userId: string, forwarderId: number, constantForwarderStatisticValueRequestTO: ConstantForwarderStatisticValueRequestTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConstantForwarderStatisticFloatValueResponseTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatisticFloatValue1(userId, forwarderId, constantForwarderStatisticValueRequestTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebappStatisticsForForwarderApi.getStatisticFloatValue1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebappStatisticsForForwarderApi - factory interface
 * @export
 */
export const WebappStatisticsForForwarderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebappStatisticsForForwarderApiFp(configuration)
    return {
        /**
         * Gets statistical float value for the forwarders
         * @summary Gets a specific statistic value
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} forwarderId id of the forwarder
         * @param {ConstantForwarderStatisticValueRequestTO} constantForwarderStatisticValueRequestTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatisticFloatValue1(userId: string, forwarderId: number, constantForwarderStatisticValueRequestTO: ConstantForwarderStatisticValueRequestTO, options?: RawAxiosRequestConfig): AxiosPromise<ConstantForwarderStatisticFloatValueResponseTO> {
            return localVarFp.getStatisticFloatValue1(userId, forwarderId, constantForwarderStatisticValueRequestTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebappStatisticsForForwarderApi - object-oriented interface
 * @export
 * @class WebappStatisticsForForwarderApi
 * @extends {BaseAPI}
 */
export class WebappStatisticsForForwarderApi extends BaseAPI {
    /**
     * Gets statistical float value for the forwarders
     * @summary Gets a specific statistic value
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} forwarderId id of the forwarder
     * @param {ConstantForwarderStatisticValueRequestTO} constantForwarderStatisticValueRequestTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebappStatisticsForForwarderApi
     */
    public getStatisticFloatValue1(userId: string, forwarderId: number, constantForwarderStatisticValueRequestTO: ConstantForwarderStatisticValueRequestTO, options?: RawAxiosRequestConfig) {
        return WebappStatisticsForForwarderApiFp(this.configuration).getStatisticFloatValue1(userId, forwarderId, constantForwarderStatisticValueRequestTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebappTruckersApi - axios parameter creator
 * @export
 */
export const WebappTruckersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * dasdas
         * @summary Get all Truckers
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId Facility Id
         * @param {number} [page] Page to be shown
         * @param {number} [size] Page size
         * @param {string} [lastName] Last name of the trucker (optional)
         * @param {ListTruckersForFacilityBlockedStateEnum} [blockedState] Trucker blocked state (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTruckersForFacility: async (userId: string, facilityId: number, page?: number, size?: number, lastName?: string, blockedState?: ListTruckersForFacilityBlockedStateEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listTruckersForFacility', 'userId', userId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('listTruckersForFacility', 'facilityId', facilityId)
            const localVarPath = `/web/terminalOperators/v1/users/{userId}/facilities/{facilityId}/truckers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (blockedState !== undefined) {
                localVarQueryParameter['blockedState'] = blockedState;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebappTruckersApi - functional programming interface
 * @export
 */
export const WebappTruckersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebappTruckersApiAxiosParamCreator(configuration)
    return {
        /**
         * dasdas
         * @summary Get all Truckers
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId Facility Id
         * @param {number} [page] Page to be shown
         * @param {number} [size] Page size
         * @param {string} [lastName] Last name of the trucker (optional)
         * @param {ListTruckersForFacilityBlockedStateEnum} [blockedState] Trucker blocked state (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTruckersForFacility(userId: string, facilityId: number, page?: number, size?: number, lastName?: string, blockedState?: ListTruckersForFacilityBlockedStateEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminalOperatorTruckerTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTruckersForFacility(userId, facilityId, page, size, lastName, blockedState, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebappTruckersApi.listTruckersForFacility']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebappTruckersApi - factory interface
 * @export
 */
export const WebappTruckersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebappTruckersApiFp(configuration)
    return {
        /**
         * dasdas
         * @summary Get all Truckers
         * @param {string} userId UUID that comes from firebase authentication
         * @param {number} facilityId Facility Id
         * @param {number} [page] Page to be shown
         * @param {number} [size] Page size
         * @param {string} [lastName] Last name of the trucker (optional)
         * @param {ListTruckersForFacilityBlockedStateEnum} [blockedState] Trucker blocked state (optional)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTruckersForFacility(userId: string, facilityId: number, page?: number, size?: number, lastName?: string, blockedState?: ListTruckersForFacilityBlockedStateEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<TerminalOperatorTruckerTO>> {
            return localVarFp.listTruckersForFacility(userId, facilityId, page, size, lastName, blockedState, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebappTruckersApi - object-oriented interface
 * @export
 * @class WebappTruckersApi
 * @extends {BaseAPI}
 */
export class WebappTruckersApi extends BaseAPI {
    /**
     * dasdas
     * @summary Get all Truckers
     * @param {string} userId UUID that comes from firebase authentication
     * @param {number} facilityId Facility Id
     * @param {number} [page] Page to be shown
     * @param {number} [size] Page size
     * @param {string} [lastName] Last name of the trucker (optional)
     * @param {ListTruckersForFacilityBlockedStateEnum} [blockedState] Trucker blocked state (optional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebappTruckersApi
     */
    public listTruckersForFacility(userId: string, facilityId: number, page?: number, size?: number, lastName?: string, blockedState?: ListTruckersForFacilityBlockedStateEnum, options?: RawAxiosRequestConfig) {
        return WebappTruckersApiFp(this.configuration).listTruckersForFacility(userId, facilityId, page, size, lastName, blockedState, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListTruckersForFacilityBlockedStateEnum = {
    Blocked: 'BLOCKED',
    NotBlocked: 'NOT_BLOCKED'
} as const;
export type ListTruckersForFacilityBlockedStateEnum = typeof ListTruckersForFacilityBlockedStateEnum[keyof typeof ListTruckersForFacilityBlockedStateEnum];


