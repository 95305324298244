import { h, Component } from "vue";
import type { IconSet, IconProps } from "vuetify";
import ContainerIcon from "@/assets/icons/ContainerIcon.vue";
import GateIcon from "@/assets/icons/GateIcon.vue";
import GateWhiteIcon from "@/assets/icons/GateWhiteIcon.vue";
import HomeIcon from "@/assets/icons/HomeIcon.vue";
import TrainIcon from "@/assets/icons/TrainIcon.vue";
import TrainWhiteIcon from "@/assets/icons/TrainWhiteIcon.vue";
import TruckIcon from "@/assets/icons/TruckIcon.vue";
import TruckWhiteIcon from "@/assets/icons/TruckWhiteIcon.vue";
import OrdersFilled from "@/assets/icons/OrdersFilled.vue";
import OrdersOutlined from "@/assets/icons/OrdersOutlined.vue";
import CompassOutlined from "@/assets/icons/CompassOutlined.vue";
import CompassFilled from "@/assets/icons/CompassFilled.vue";
import DisturbOutlined from "@/assets/icons/DisturbOutlined.vue";
import DisturbFilled from "@/assets/icons/DisturbFilled.vue";
import BlockOutlined from "@/assets/icons/BlockOutlined.vue";
import BlockFilled from "@/assets/icons/BlockFilled.vue";
import TimeOutlined from "@/assets/icons/TimeOutlined.vue";
import TimeFilled from "@/assets/icons/TimeFilled.vue";
import UserOutlined from "@/assets/icons/UserOutlined.vue";
import UserFilled from "@/assets/icons/UserFilled.vue";
import SettingsFilled from "@/assets/icons/SettingsFilled.vue";
import SettingsOutlined from "@/assets/icons/SettingsOutlined.vue";

const customSvgNameToComponent: Record<string, Component> = {
  HomeIcon,
  ContainerIcon,
  GateIcon,
  GateWhiteIcon,
  TrainIcon,
  TrainWhiteIcon,
  TruckIcon,
  TruckWhiteIcon,
  OrdersFilled,
  OrdersOutlined,
  CompassFilled,
  CompassOutlined,
  DisturbFilled,
  DisturbOutlined,
  BlockFilled,
  BlockOutlined,
  TimeOutlined,
  TimeFilled,
  UserOutlined,
  UserFilled,
  SettingsFilled,
  SettingsOutlined,
};

const customSVGs: IconSet = {
  component: (props: IconProps) => {
    const iconComponent = customSvgNameToComponent[props.icon];
    return iconComponent ? h(iconComponent) : null;
  },
};

export { customSVGs };
