<template>
  <router-view id="app" />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useAuthStore } from "./store/useAuthStore";

const terminalStore = useTerminalStore();
const authStore = useAuthStore();

watch(
  () => authStore.authUser?.userId,
  async user => {
    if (user) {
      await terminalStore.fetchTerminals();
    }
  },
);
</script>

<style>
/*TODO: import this in index.html*/
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  hyphens: auto;
}
</style>
