import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import baseRoutes from "./base.routes";
import guestRoutes from "./guest.routes";
import { WebappUserRolesEnum } from "@/services/client/generated";

export const routes = [...baseRoutes, ...guestRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: RouteLocationNormalized, _from, next) => {
  const title = (to.meta as { title?: string }).title ?? "CONROO TERMINAL APP";
  document.title = title;

  const authStore = useAuthStore();

  const authenticated = authStore.authUser != null;

  if (!authenticated && to.path !== "/login") {
    return next({ path: "/login" });
  }

  if (["/", "/login"].includes(to.path) && authenticated) {
    return next({ path: "/homepage" });
  }

  const requiredRoles =
    (to.meta as { roles?: WebappUserRolesEnum[] }).roles || [];
  const userRoles = authStore.authUser?.userData?.roles || [];

  if (
    requiredRoles.length > 0 &&
    !requiredRoles.some(role => userRoles.includes(role))
  ) {
    return next({ path: "/403" });
  }

  next();
});

export default router;
