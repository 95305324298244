import { defineStore } from "pinia";
import { computed, ref } from "vue";
import UserAuth from "./interfaces/user-auth";
import { WebappUser } from "@/services/client/generated";
import { User as FirebaseUser } from "firebase/auth";
import { terimnalUserClient } from "@/services/client/configs/services";
import { authService } from "@/services/business/auth-service";
import { useRouter } from "vue-router";

export const useAuthStore = defineStore(
  "auth",
  () => {
    const authUser = ref<UserAuth | null>(null);
    const firebaseUser = ref<FirebaseUser | null>(null);
    const router = useRouter();

    const userData = computed(() => authUser.value?.userData);

    function setUser(user: UserAuth | null) {
      if (user) {
        authUser.value = { ...authUser.value, ...user };
      } else {
        authUser.value = null;
      }
    }

    function setUserData(user: WebappUser) {
      if (authUser.value) {
        authUser.value.userData = user;
      }
    }

    function setFirebaseUser(user: FirebaseUser | null) {
      firebaseUser.value = user;
    }

    async function login(payload: { email: string; password: string }) {
      const { email, password } = payload;
      const user = await authService.login(email, password);

      if (user) {
        setFirebaseUser(user as FirebaseUser);
        setUser({
          userId: user.uid,
          emailVerified: user.emailVerified,
          accessToken: await user.getIdToken(),
        });

        const response = await terimnalUserClient.getUserById(user.uid);
        setUserData(response.data);
      }
    }

    async function updateAccessToken(): Promise<string | undefined> {
      const user = firebaseUser.value;

      if (!user || typeof user.getIdToken !== "function") {
        await logout();
        return;
      }

      try {
        const authToken = await user.getIdToken();
        setUser({
          userId: user.uid,
          emailVerified: user.emailVerified,
          accessToken: authToken,
          userData: authUser.value?.userData,
        });
        return authToken;
      } catch (_) {
        await logout();
      }
    }

    async function logout() {
      await authService.logout();
      setUser(null);
      setFirebaseUser(null);
      router.push("/login");
    }

    return {
      authUser,
      firebaseUser,
      userData,
      setUser,
      setUserData,
      setFirebaseUser,
      login,
      updateAccessToken,
      getUser: () => authUser.value,
      logout,
    };
  },
  {
    persist: {
      key: "auth-store",
      paths: ["authUser"],
    },
  },
);
