<template>
  <div>
    <ScheduleXCalendar class="calendar" :calendar-app="calendarApp">
      <template #timeGridEvent="{ calendarEvent }">
        <div
          class="pa-1 h-100"
          :class="{ 'cursor-default': isPastSlot(calendarEvent.end) }"
        >
          <v-tooltip :text="t('slotManagement.double_click_to_select_all')">
            <template #activator="{ props: activeProps }">
              <v-sheet
                :color="`${getTimeSlotColor(calendarEvent.id)}-lighten-1`"
                class="h-100"
                v-bind="activeProps"
                rounded="lg"
              >
                <span
                  class="position-absolute text-h6 text-white font-weight-bold text-center rounded-lg capacity-text w-50"
                >
                  {{ getTimeSlotCapacity(calendarEvent.id) }}
                </span>
                <v-icon
                  v-if="isSelected(calendarEvent.id)"
                  size="18"
                  icon="mdi-check-circle"
                  color="white"
                  class="checkmark position-absolute"
                />

                <v-sheet
                  :color="getTimeSlotColor(calendarEvent.id)"
                  rounded="lg"
                  class="h-100"
                  :style="{ width: getCapacityWidth(calendarEvent.id) }"
                />
              </v-sheet>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template #headerContentRightPrepend>
        <v-btn
          class="mr-2"
          prepend-icon="mdi-close-circle-outline"
          :disabled="!selectedTimeslots.length"
          @click="selectedTimeslots = []"
        >
          {{ t("cancel_edit") }}
        </v-btn>
        <v-btn
          prepend-icon="mdi-timer-edit-outline"
          color="primary"
          :disabled="!selectedTimeslots.length"
          @click="emitTimeSlots"
        >
          {{ t("edit_timeslots") }}
          {{
            selectedTimeslots.length > 0 ? `(${selectedTimeslots.length})` : ""
          }}
        </v-btn>
      </template>
    </ScheduleXCalendar>
  </div>
</template>

<script setup lang="ts">
import { ScheduleXCalendar } from "@schedule-x/vue";
import { createCalendar, createViewWeek } from "@schedule-x/calendar";
import { createCurrentTimePlugin } from "@schedule-x/current-time";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { createCalendarControlsPlugin } from "@schedule-x/calendar-controls";

import { Module, TimeSlot } from "@/internal-models/terminalMockData";
import { computed, onMounted, onUnmounted, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  module: {
    type: Object as PropType<Module>,
    default: () => ({}),
    required: true,
  },
  drawer: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const { locale, t } = useI18n();

const emit = defineEmits(["selected-timeslots"]);

const selectedTimeslots = ref<TimeSlot[]>([]);

const emitTimeSlots = () => {
  emit("selected-timeslots", selectedTimeslots.value);
};

const calculateHoursDifference = (start: string, end: string): number => {
  const [startHour] = start.split(":").map(Number);
  const [endHour] = end.split(":").map(Number);
  return Math.max(endHour - startHour, 0);
};

const getDynamicGridHeight = (start: string, end: string): number => {
  const hourDifference = calculateHoursDifference(start, end);
  return hourDifference <= 9 ? 600 : 600 + (hourDifference - 9) * 50;
};

const isPastSlot = (slotEnd: string | number | Date) => {
  const slotEndTime = new Date(slotEnd);
  return slotEndTime < currentTime.value;
};

const initialGridHeight = getDynamicGridHeight(
  props.module.openingHour,
  props.module.closingHour,
);

const mapEvent = (timeSlot: TimeSlot) => ({
  id: timeSlot.id,
  title: timeSlot.title,
  start: timeSlot.slotBegin,
  end: timeSlot.slotEnd,
});

const viewWeek = createViewWeek();
const timePlugin = createCurrentTimePlugin({
  fullWeekWidth: true,
});

const eventsServicePlugin = createEventsServicePlugin();
const calendarControls = createCalendarControlsPlugin();

const calendarEvents = computed(() => props.module.timeSlots.map(mapEvent));
const currentTime = ref(new Date());

const findTimeSlotById = (id: number): TimeSlot | undefined => {
  return props.module.timeSlots.find(timeSlot => timeSlot.id === id);
};

const calendarApp = createCalendar(
  {
    locale: `${locale.value}-${locale.value.toUpperCase()}`,
    selectedDate: new Date().toISOString().split("T")[0],
    views: [viewWeek],
    events: calendarEvents.value,
    dayBoundaries: {
      start: props.module.openingHour,
      end: props.module.closingHour,
    },
    weekOptions: {
      timeAxisFormatOptions: {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      },
      gridHeight: initialGridHeight,
    },
    callbacks: {
      onEventClick(updatedEvent) {
        const timeSlot = findTimeSlotById(updatedEvent.id as number);
        if (!timeSlot) {
          return;
        }

        const index = selectedTimeslots.value.findIndex(
          slot => slot.id === timeSlot.id,
        );

        const eventEndTime = new Date(timeSlot.slotEnd);

        if (eventEndTime >= currentTime.value) {
          if (index > -1) {
            selectedTimeslots.value.splice(index, 1);
          } else {
            selectedTimeslots.value.push(timeSlot);
          }
        }
      },
      onDoubleClickEvent(dateTime) {
        const clickedSlot = findTimeSlotById(dateTime.id as number);
        if (!clickedSlot || isPastSlot(clickedSlot.slotEnd)) {
          return;
        }

        const clickedDate = new Date(clickedSlot.slotBegin)
          .toISOString()
          .split("T")[0];

        selectedTimeslots.value = props.module.timeSlots.filter(slot => {
          const slotDate = new Date(slot.slotBegin).toISOString().split("T")[0];
          const isNotPast = !isPastSlot(slot.slotEnd);
          return slotDate === clickedDate && isNotPast;
        });
      },
    },
  },
  [timePlugin, eventsServicePlugin, calendarControls],
);

const isSelected = (id: number): boolean => {
  return selectedTimeslots.value.some(slot => slot.id === id);
};

const getTimeSlotColor = (id: number): string => {
  const timeSlot = props.module.timeSlots.find(slot => slot.id === id);
  if (!timeSlot) return "grey";

  if (isSelected(id)) return "primary";

  const slotEndTime = new Date(timeSlot.slotEnd);
  if (currentTime.value > slotEndTime) return "grey";

  return timeSlot.category ?? "grey";
};

const getTimeSlotCapacity = (id: number) => {
  const timeSlotCapacity = props.module.timeSlots.find(
    slot => slot.id === id,
  )?.capacity;

  return `${timeSlotCapacity?.currentNumber} / ${timeSlotCapacity?.maximum}`;
};

const getCapacityWidth = (id: number) => {
  const event = props.module.timeSlots.find(slot => slot.id === id);
  if (!event) return "0%";

  const percentage =
    (event.capacity.currentNumber / event.capacity.maximum) * 100;
  return `${percentage}%`;
};

watch(
  () => props.module,
  newModule => {
    eventsServicePlugin.set(newModule.timeSlots.map(mapEvent));
    calendarControls.setDayBoundaries({
      start: newModule.openingHour,
      end: newModule.closingHour,
    });

    const updatedGridHeight = getDynamicGridHeight(
      newModule.openingHour,
      newModule.closingHour,
    );

    calendarControls.setWeekOptions({
      gridHeight: updatedGridHeight,
      nDays: 7,
      eventWidth: 100,
      timeAxisFormatOptions: {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      },
    });
  },
);

watch(
  () => locale.value,
  newLocale => {
    calendarControls.setLocale(`${newLocale}-${newLocale.toUpperCase()}`);
  },
);

watch(
  () => props.drawer,
  newDrawerValue => {
    if (!newDrawerValue) {
      selectedTimeslots.value = [];
    }
  },
);

onMounted(() => {
  const interval = setInterval(() => {
    currentTime.value = new Date();
  }, 60000);

  onUnmounted(() => clearInterval(interval));
});
</script>

<style lang="css" scoped>
.sx__calendar {
  border: unset !important;
}

.sx__current-time-indicator-full-week {
  background-color: rgb(var(--v-theme-primary)) !important;
}
.sx__current-time-indicator {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.sx__current-time-indicator::before {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.capacity-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%);
  width: 4em;
}

.checkmark {
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
</style>
