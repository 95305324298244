<template>
  <div>
    <LineChart
      v-if="chartData"
      :data="chartData"
      :options="chartOptions"
      :data-testid="`test-${chartTitle}`"
    />
  </div>
</template>

<script setup lang="ts">
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line as LineChart } from "vue-chartjs";
import { LineChartData } from "@/internal-models/charts";
import { computed } from "vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const props = defineProps<{
  chartData: LineChartData;
  chartTitle: string;
}>();

const chartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: props.chartTitle,
      font: {
        size: 24,
      },
    },
    legend: {
      display: false,
    },
  },
}));
</script>
