<template>
  <div>
    <TheMainHeader
      class="text-left pa-10"
      :text="$t('acceptanceRestrictions')"
    />
    <div>
      <v-container fluid class="pt-10 pa-10">
        <v-tabs v-model="tab" align-tabs="center" grow>
          <v-tab href="#acceptance">
            <div class="text-h5 text-primary font-weight-medium">
              {{ $t("acceptanceDeadline") }}
            </div>
          </v-tab>
          <v-tab href="#oneToOne">
            <div
              class="text-h5 text-primary font-weight-medium"
              data-testid="test-onetoOneRestriction"
            >
              {{ $t("oneToOneRestriction") }}
            </div>
          </v-tab>
        </v-tabs>

        <v-window v-model="tab">
          <v-window-item :key="1" value="acceptance">
            <TerminalOperatorAcceptanceDeadline />
          </v-window-item>

          <v-window-item :key="2" value="oneToOne">
            <TerminalOperatorOneToOneRestriction />
          </v-window-item>
        </v-window>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TerminalOperatorAcceptanceDeadline from "@/views/terminal-operator/acceptance-deadline/TerminalOperatorAcceptanceDeadline.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import TerminalOperatorOneToOneRestriction from "@/views/terminal-operator/one-to-one-restriction/TerminalOperatorOneToOneRestriction.vue";

const tab = ref("acceptance");
</script>
