<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6796 13.1549L20.6679 13.1783C20.7146 12.7933 20.7612 12.3966 20.7612 11.9999C20.7612 11.6033 20.7262 11.2299 20.6796 10.8449L20.6912 10.8683L23.5379 8.62825L20.7029 3.70492L17.3546 5.05825L17.3662 5.06992C16.7596 4.60325 16.0946 4.20659 15.3712 3.90325H15.3829L14.8462 0.333252H9.16457L8.65123 3.91492H8.6629C7.93957 4.21825 7.27457 4.61492 6.6679 5.08159L6.67957 5.06992L3.31957 3.70492L0.4729 8.62825L3.31957 10.8683L3.33123 10.8449C3.28457 11.2299 3.24957 11.6033 3.24957 11.9999C3.24957 12.3966 3.28457 12.7933 3.3429 13.1783L3.33123 13.1549L0.881234 15.0799L0.496234 15.3833L3.33123 20.2833L6.69123 18.9416L6.6679 18.8949C7.28623 19.3733 7.95123 19.7699 8.68623 20.0733H8.65123L9.17623 23.6666H14.8346C14.8346 23.6666 14.8696 23.4566 14.9046 23.1766L15.3479 20.0849H15.3362C16.0596 19.7816 16.7362 19.3849 17.3546 18.9066L17.3312 18.9533L20.6912 20.2949L23.5262 15.3949C23.5262 15.3949 23.3629 15.2549 23.1412 15.0916L20.6796 13.1549V13.1549ZM11.9996 16.0833C9.7479 16.0833 7.91623 14.2516 7.91623 11.9999C7.91623 9.74825 9.7479 7.91659 11.9996 7.91659C14.2512 7.91659 16.0829 9.74825 16.0829 11.9999C16.0829 14.2516 14.2512 16.0833 11.9996 16.0833Z"
      fill="#FDFDFD"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>
