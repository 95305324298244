<template>
  <div>
    <TheMainHeader
      :text="$t('home')"
      class="text-left pl-10 pt-10"
      data-testid="test-home"
    />
    <div>
      <v-container fluid class="pt-10 pa-8">
        <v-row>
          <v-col
            v-for="(pageCard, key) in filteredPageCards"
            :key="key"
            sm="6"
            md="6"
            lg="6"
            xl="4"
          >
            <ThePageCard
              :usable="hasPermissionBasedOnRole(pageCard.roles, userRoles)"
              :item="pageCard"
              :data-testid="`test-${key}-card`"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAuthStore } from "@/store/useAuthStore";
import ThePageCard from "@/components/ThePageCard.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { hasPermissionBasedOnRole } from "@/utils/role-utils";
import getPageCards from "@/constants/homepage-page-cards";

const pageCards = getPageCards();

const authStore = useAuthStore();
const userRoles = computed(() => authStore.userData?.roles || []);

const filteredPageCards = computed(() => {
  return Object.values(pageCards).filter(pageCard =>
    hasPermissionBasedOnRole(pageCard.roles, userRoles.value),
  );
});
</script>

<style lang="scss" scoped></style>
