<template>
  <div>
    <v-card class="rounded-lg" color="primary" elevation="2" rounded>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="7">
            <span v-if="terminal" class="text-h5 font-weight-bold">
              {{ terminal.name }}
            </span>
          </v-col>
          <v-col cols="12">
            <GetStatusBatchRequestForm
              :state-indicator-form="true"
              :is-resubmit-batch-button-disabled="isResubmitBatchButtonDisabled"
              :terminal-id="terminal.id"
              @submit-csv="submitCSV"
            />
          </v-col>
          <v-col cols="10" class="text-h6">
            {{ $t("containersBeingProcessed") }}:
            <span class="text-white font-weight-bold text-h6">
              {{ progressState.currentIndex }} / {{ lengthOfAllRequests }}
            </span>
          </v-col>
          <v-col cols="10" class="text-h6">
            {{ $t("foundContainers") }}:
            <span class="text-green-accent-3 font-weight-bold text-h6">
              {{ progressState.successfulItems }}
            </span>
          </v-col>
          <v-col cols="10" class="text-h6">
            {{ $t("notFoundContainers") }}:
            <span class="text-red font-weight-bold text-h6">{{
              progressState.failedItems
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import QueueProgressIndicator from "@/views/forwarder-operator/get-status-batch/models/queue-progress-indicator";
import GetStatusBatchRequestForm from "@/views/forwarder-operator/get-status/GetStatusBatchRequestForm.vue";
import { Terminal } from "@/services/client/generated";
import { PropType } from "vue";

defineProps({
  progressState: {
    type: Object as PropType<QueueProgressIndicator>,
    default: () => ({}),
  },
  lengthOfAllRequests: {
    type: Number,
    required: true,
  },
  terminal: {
    type: Object as PropType<Terminal>,
    default: () => ({}),
  },
  isResubmitBatchButtonDisabled: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["submit-csv"]);

const submitCSV = (payload: { file: File }) => {
  emit("submit-csv", payload);
};
</script>

<style scoped lang="scss"></style>
