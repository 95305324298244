<template>
  <v-form v-model="valid" class="w-100">
    <v-row>
      <v-col sm="12" :md="stateIndicatorForm ? 7 : 4">
        <TheFileInput
          accept="text/csv"
          :outlined="true"
          :label="$t('selectFile')"
          :rules="fileValidationRules"
          @change-file="setFile"
        />
      </v-col>

      <v-col>
        <v-spacer />
      </v-col>

      <v-col cols="12" :md="stateIndicatorForm ? 4 : 2">
        <TheButton
          id="submit-button"
          color="primary"
          :is-disabled="isResubmitBatchButtonDisabled || !valid"
          :loading="loading"
          :title="$t('request')"
          @click="submitCsv"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TheButton from "@/components/TheButton.vue";
import TheFileInput from "@/components/TheFileInput.vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  terminalId: {
    type: Number,
    required: true,
  },
  isResubmitBatchButtonDisabled: {
    type: Boolean,
    required: false,
  },
  stateIndicatorForm: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["submit-csv"]);
const { t } = useI18n();

const valid = ref(false);
const currentFile = ref<File>();
const loading = ref(false);

const setFile = (payload: { label: string; value: File }) => {
  currentFile.value = payload.value;
};

const fileValidationRules = [
  (v: File[]) => !!v || t("fieldRequired"),
  (v: File[]) => (v[0] && v[0].size < 1000000) || t("fileTooBig"),
];

const submitCsv = (): void => {
  if (valid.value) {
    loading.value = true;
    emit("submit-csv", {
      file: currentFile.value,
      terminalId: props.terminalId,
    });
    loading.value = false;
  }
};
</script>

<style scoped lang="scss">
.fit-content {
  max-width: fit-content;
}

#submit-button:disabled {
  background-color: #ababab !important;
}
</style>
