<template>
  <v-col cols="12">
    <div v-if="userSelectedId.length !== 0">
      <ConfirmationDialog
        :user-id="userSelectedId"
        @submit="(t: any) => resetUser(t.id)"
        @close="(t: any) => (openResetDialog = t.close)"
        :custom-text="t('userManagement.reset_id')"
        :custom-submit="t('accept')"
        :custom-cancel="t('cancel')"
        v-model="openResetDialog"
      />
      <HistoryDialog
        v-if="currentUser != null"
        @approve="(t: any) => approveUser(t, userSelectedId)"
        @close="(t: any) => (openHistoryDialog = t.close)"
        v-model="openHistoryDialog"
        :users="currentUser?.changes"
      />
      <MigrateLicenseDialog
        v-if="currentUser != null"
        v-model="openMigrateLicenseDialog"
        @confirm="(newUserId: string) => migrateLicense(newUserId)"
        @close="() => (openMigrateLicenseDialog = !openMigrateLicenseDialog)"
      />

      <DeleteUserDialog
        v-if="currentUser != null"
        v-model="openDeleteDialog"
        @confirm="() => deleteUser()"
        @close="() => (openDeleteDialog = !openDeleteDialog)"
      />
    </div>
    <VirtualTable
      :items="props.users"
      :headers="headers"
      :row-props="getRowClass"
      :pages-loaded="pagesLoaded"
      @load-more="loadMore"
    >
      <template #item.deletedAt="{ value }">
        <v-tooltip :text="t('deleted')" location="bottom">
          <template #activator="{ props: activeProps }">
            <v-icon
              v-if="value.deletedDate"
              v-bind="activeProps"
              color="red"
              icon="mdi-alert"
            />
          </template>
        </v-tooltip>
      </template>
      <template #item.userId="{ value }">
        {{ value.userId }}
      </template>
      <template #item.phoneNumber="{ value }">
        {{ value.phoneNumber != null ? t("phone") : t("email") }}
      </template>
      <template #item.email="{ value }">
        {{ value.email ?? t("missing") }}
      </template>
      <template #item.phoneNumberStatus="{ value }">
        {{ value.phoneNumber ?? t("missing") }}
      </template>
      <template #item.terrorState="{ value }">
        <EditableDropdown
          :selected-value="changeToSend?.terrorStatus"
          @select="
            (t: IspsTruckerChangeTOTerrorStatusEnum | undefined) => {
              if (changeToSend) changeToSend.terrorStatus = t;
            }
          "
          :is-editable="currentlyEditableRow === value.userId"
          :entries="validTerrorStates"
          :title="
            getLastArrayElement(value.truckerIdentification!)?.terrorStatus ??
            ''
          "
        />
      </template>
      <template #item.documentState="{ value }">
        <EditableDropdown
          :selected-value="changeToSend?.documentStatus"
          @select="
            (t: IspsTruckerChangeTODocumentStatusEnum | undefined) => {
              if (changeToSend) changeToSend.documentStatus = t;
            }
          "
          :is-editable="currentlyEditableRow === value.userId"
          :entries="validIdentificationStates"
          :title="
            getLastArrayElement(value.truckerIdentification!)?.documentStatus ??
            ''
          "
        />
      </template>
      <template #item.firstName="{ value }">
        <EditableInput
          v-model="changeToSend.firstName"
          :title="value.firstName"
          :is-editable="currentlyEditableRow === value.userId"
          placeholder="firstname"
          @update:model-value="
            (t: string | undefined) => {
              if (changeToSend) changeToSend.firstName = t;
            }
          "
        />
      </template>
      <template #item.lastName="{ value }">
        <EditableInput
          :title="value.lastName"
          @update:model-value="
            (t: string | undefined) => (changeToSend.lastName = t)
          "
          :is-editable="currentlyEditableRow === value.userId"
          v-model="changeToSend.lastName"
          placeholder="lastname"
        />
      </template>
      <template #item.birth="{ value }">
        {{ getBirthday(value) }}
      </template>
      <template #item.verificationStarted="{ value }">
        {{ getVerificationDate(value) }}
      </template>
      <template #item.lastEditedDate="{ value }">
        {{ getLastEditedDate(value) }}
      </template>
      <template #item.deletedAtDate="{ value }">
        {{ format(value.deletedDate) }}
      </template>
      <template #item.actions="{ value }">
        <v-row>
          <v-col
            v-if="currentlyEditableRow !== value.userId"
            class="d-flex justify-space-between"
          >
            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => changeCurrentlyEditable(value.userId!)"
                >
                  <v-icon icon="mdi-pencil" />
                </v-btn>
              </template>
              <span>{{ t("edit") }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => showUserHistory(value.userId!)"
                >
                  <v-icon icon="mdi-history" />
                </v-btn>
              </template>
              <span>{{ t("userManagement.show_user_history") }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => showResetUser(value.userId!)"
                >
                  <v-icon icon="mdi-refresh" />
                </v-btn>
              </template>
              <span>{{ t("userManagement.reset_idnow_tries") }}</span>
            </v-tooltip>

            <v-tooltip v-if="value.ispsLicense" location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon="mdi-swap-horizontal"
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => showMigrateLicense(value.userId!)"
                />
              </template>
              <span>Migrate License</span>
            </v-tooltip>
            <v-tooltip
              v-if="
                !value.deletedDate &&
                !value.changes.some(
                  (e: IspsTruckerChangeTO) => e.delete === true,
                )
              "
              location="bottom"
            >
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon="mdi-trash-can"
                  variant="text"
                  v-bind="activatorProps"
                  @click="() => showDeleteDialog(value.userId!)"
                />
              </template>
              <span>{{ t("userManagement.delete_user") }}</span>
            </v-tooltip>
          </v-col>
          <v-col v-else class="d-flex justify-space-between">
            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="tonal"
                  color="green"
                  v-bind="activatorProps"
                  @click="applyChanges"
                >
                  <v-icon icon="mdi-check" />
                </v-btn>
              </template>
              <span>{{ t("save") }}</span>
            </v-tooltip>

            <v-tooltip location="bottom">
              <template #activator="{ props: activatorProps }">
                <v-btn
                  icon
                  variant="text"
                  color="elevated"
                  v-bind="activatorProps"
                  @click="discardChanges"
                >
                  <v-icon icon="mdi-close" />
                </v-btn>
              </template>
              <span>{{ t("discard") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </VirtualTable>
  </v-col>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import ConfirmationDialog from "@/components/user-management/ConfirmationDialog.vue";
import HistoryDialog from "./HistoryDialog.vue";
import MigrateLicenseDialog from "./MigrateLicenseDialog.vue";
import DeleteUserDialog from "./DeleteUserDialog.vue";
import EditableDropdown from "@/components/user-management/EditableDropdown.vue";
import EditableInput from "@/components/user-management/EditableInput.vue";
import { deleteAllKeys, getLastArrayElement } from "@/utils/object-util";
import { dateService } from "@/services/business/date-service";
import {
  IspsTruckerChangeRequestTOTerrorStatusEnum,
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
  IspsTruckerTO,
} from "@/services/client/generated";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/utils/date-utils";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";

const emit = defineEmits<{
  submit: [payload: { userId: string; data: IspsTruckerChangeTO }];
  approve: [payload: { userId: string; entryId: number; approve: boolean }];
  reset: [payload: string];
  "load-more": [done: (status: "loading" | "error" | "empty" | "ok") => void];
  migrate: [
    payload: { userSelectedId: string; newUserId: string; licenseId: number },
  ];
  delete: [payload: { userId: string }];
}>();

const { t } = useI18n();

const props = defineProps<{ users: IspsTruckerTO[]; pagesLoaded: number }>();

const validIdentificationStates: IspsTruckerChangeTODocumentStatusEnum[] = [
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
];

const validTerrorStates: IspsTruckerChangeRequestTOTerrorStatusEnum[] = [
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
];

const currentlyEditableRow = ref<string>("");
const userSelectedId = ref<string>("");

const snackBarStore = useSnackbarStore();

const openHistoryDialog = ref(false);
const openResetDialog = ref(false);
const openMigrateLicenseDialog = ref(false);
const openDeleteDialog = ref(false);

const loadMore = (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  emit("load-more", done);
};

const currentUser = computed<IspsTruckerTO | undefined>(
  () => props.users.filter(e => e.userId === userSelectedId.value)[0],
);
const changeToSend = ref<IspsTruckerChangeTO>({});

const changeCurrentlyEditable = (userId: string) => {
  currentlyEditableRow.value = userId;
  changeToSend.value = {};
};

const format = (date: string | undefined) => {
  return date ? formatDate(date) : t("missing");
};

const discardChanges = () => {
  currentlyEditableRow.value = "";
  changeToSend.value = {};
};

const getBirthday = (user: IspsTruckerTO): string => {
  //some util might be needed
  if (
    user.truckerIdentification == null ||
    user.truckerIdentification.length === 0
  ) {
    return "";
  }

  const result =
    user.truckerIdentification[
      user.truckerIdentification.length - 1
    ].dateOfBirth?.toString();

  if (result === undefined) {
    return "";
  }

  return dateService.convertTimeToLocalReadable(new Date(result));
};

const getVerificationDate = (user: IspsTruckerTO): string => {
  if (
    user.truckerIdentification == null ||
    user.truckerIdentification.length === 0
  ) {
    return "";
  }

  const result =
    user.truckerIdentification[
      user.truckerIdentification.length - 1
    ].verified_date?.toString();

  if (result === undefined) {
    return "";
  }

  return dateService.convertTimeToLocalReadable(new Date(result));
};

const getLastEditedDate = (user: IspsTruckerTO): string => {
  const lastChange = user.changes?.[user.changes.length - 1]?.createdDate;
  return lastChange
    ? dateService.convertTimeToLocalReadable(new Date(lastChange))
    : "";
};

const isChangeValid = (change: IspsTruckerChangeTO): boolean => {
  return Object.keys(change).length !== 0;
};

const applyChanges = () => {
  if (changeToSend.value === null) {
    return;
  }
  const validChange = isChangeValid(changeToSend.value);
  if (!validChange) {
    snackBarStore.showSnackbar({
      text: "Can't add an empty change",
      snackbarType: SnackbarType.ERROR,
    });
    return;
  }
  const payload = {
    userId: currentlyEditableRow.value,
    data: { ...changeToSend.value },
  };
  emit("submit", payload);
  currentlyEditableRow.value = "";
  deleteAllKeys(changeToSend.value);
};

const showResetUser = (userId: string) => {
  userSelectedId.value = userId;
  openResetDialog.value = !openResetDialog.value;
};

const showUserHistory = (userId: string) => {
  userSelectedId.value = userId;
  openHistoryDialog.value = !openHistoryDialog.value;
};

const showMigrateLicense = (userId: string) => {
  userSelectedId.value = userId;
  openMigrateLicenseDialog.value = !openMigrateLicenseDialog.value;
};

const showDeleteDialog = (userId: string) => {
  userSelectedId.value = userId;
  openDeleteDialog.value = !openDeleteDialog.value;
};

const approveUser = (
  payload: { approve: boolean; id: number },
  userId: string,
) => {
  const { approve, id } = payload;
  emit("approve", { userId, entryId: id, approve });
};

const resetUser = (payload: string) => {
  emit("reset", payload);
};

const migrateLicense = (newUserId: string) => {
  const licenseId = currentUser.value?.ispsLicense?.id;
  if (licenseId) {
    emit("migrate", {
      userSelectedId: userSelectedId.value,
      newUserId,
      licenseId,
    });
  }
};

const deleteUser = () => {
  if (userSelectedId.value) {
    emit("delete", {
      userId: userSelectedId.value,
    });
  }
};

const headers = computed(
  () =>
    [
      {
        title: "",
        value: "deletedAt",
        align: "center",
        width: "2em",
      },
      { title: t("user_id"), value: "userId", align: "center" },
      {
        title: t("account_type"),
        value: "phoneNumber",
        align: "center",
      },
      { title: t("email"), value: "email", align: "center" },
      {
        title: t("phone_number"),
        value: "phoneNumberStatus",
        align: "center",
      },
      {
        title: t("userManagement.terror_state"),
        value: "terrorState",
        align: "center",
      },
      {
        title: t("userManagement.document_state"),
        value: "documentState",
        align: "center",
      },
      {
        title: t("first_name"),
        value: "firstName",
        align: "center",
      },
      {
        title: t("last_name"),
        value: "lastName",
        align: "center",
      },
      { title: t("birth"), value: "birth", align: "center" },
      {
        title: t("userManagement.verification_started"),
        value: "verificationStarted",
        align: "center",
      },
      {
        title: t("last_edited_date"),
        value: "lastEditedDate",
        align: "center",
      },
      {
        title: t("userManagement.deleted_at"),
        value: "deletedAtDate",
        align: "center",
      },
      { title: "", value: "actions", sortable: false },
    ] as const,
);

const getRowClass = (item: { item: IspsTruckerTO }) => {
  const user = item.item;
  const rowClass = {
    "bg-grey-lighten-1": !!user.deletedDate,
    "bg-orange-400":
      getLastArrayElement(user.truckerIdentification!)?.documentStatus ===
      "WARN",
    "bg-red-accent-2":
      user.changes?.filter(e => e.state === "PENDING").length !== 0,
  };
  return { class: rowClass };
};

watch(
  () => props.users,
  newUsers => {
    if (newUsers.length > 0 && currentlyEditableRow.value) {
      const user = newUsers.find(
        user => user.userId === currentlyEditableRow.value,
      );
      if (user) {
        changeToSend.value = user;
      }
    }
  },
  { immediate: true },
);

watch(currentlyEditableRow, newUserId => {
  const user = props.users.find(user => user.userId === newUserId);
  if (user) {
    changeToSend.value = user;
  }
});
</script>
