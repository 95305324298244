<template>
  <div>
    <div class="text-center py-8">
      <h1 class="text-h2 font-weight-bold text-primary">
        {{ $t("userManagement.title") }}
      </h1>
    </div>

    <div>
      <UserSearchBar @submit="t => filterUsers(t)" />
    </div>

    <div class="mt-8">
      <ManagedUserTable
        :users="users.data"
        :pages-loaded="page"
        @approve="p => approveUser(p)"
        @submit="p => addUserRevision(p)"
        @delete="p => deleteUser(p)"
        @reset="p => resetIDNow(p)"
        @load-more="handleLoadMore"
        @migrate="
          p =>
            migrateLicense({
              userId: p.userSelectedId,
              newUserId: p.newUserId,
              licenseId: p.licenseId,
            })
        "
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import UserSearchBar from "@/components/user-management/UserSearchBar.vue";
import ManagedUserTable from "@/components/user-management/ManagedUserTable.vue";
import { useUserManagementLogic } from "./user-management-logic";

const {
  users,
  page,
  handleLoadMore,
  approveUser,
  deleteUser,
  filterUsers,
  addUserRevision,
  resetIDNow,
  migrateLicense,
} = useUserManagementLogic();
</script>

<style scoped></style>
