<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center" class="justify-center align-center">
      <v-col class="text-primary text-h4 font-weight-bold" sm="6" md="3">
        <div class="mb-8">
          {{ $t("pageNotFound") }}
        </div>
        <div>
          <v-img fluid cover src="../../../assets/main/error-404.png"></v-img>
        </div>
        <div>
          <TheButton
            :loading="false"
            :title="$t('goBack')"
            @clicked-button="goBack"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import TheButton from "@/components/TheButton.vue";

const router = useRouter();

const goBack = (): void => {
  router.go(-1);
};
</script>

<style lang="scss" scoped></style>
