<template>
  <v-form v-model="valid" class="w-100">
    <v-row no-gutters>
      <v-col cols="12" md="6" class="pa-0 ma-0">
        <v-row class="pt-1">
          <v-col cols="4">
            <TheDropdown
              item-value="id"
              label="bookingType"
              data-testid="test-getStatusTypeDropdown"
              :default-value="getStatusType"
              :rules="[typeOfBookingMustBeSelected]"
              :outlined="true"
              :items="bookingTypes"
              @change-item="changeAssignedBookingType"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="referenceNumber"
              variant="outlined"
              class="pr-2"
              data-testid="test-referenceNumberInputField"
              :label="$t('referenceNumber')"
              :rules="[
                (v: string) => (!!v && v !== '0') || $t('fieldRequired'),
              ]"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-if="getStatusType === $t('pickup')"
              v-model="containerNumber"
              variant="outlined"
              data-testid="test-containerNumberInputField"
              :label="$t('containerNumber')"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-spacer />
      </v-col>

      <v-col cols="12" md="2">
        <TheButton
          color="primary"
          data-testid="test-requestButton"
          :is-disabled="isButtonDisabled"
          :loading="loading"
          :title="$t('request')"
          @click="submitRequestData"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import { ref, watch, PropType, computed } from "vue";
import TheDropdown from "@/components/TheDropdown.vue";
import TheButton from "@/components/TheButton.vue";
import {
  GetStatusRequestTypeEnum,
  GetStatusResponse,
  Terminal,
} from "@/services/client/generated";
import { GetStatusFormPreferences } from "@/store/interfaces/user-role-config";
import { useI18n } from "vue-i18n";

const emits = defineEmits(["change-data", "submit-request"]);
const props = defineProps({
  preferences: {
    type: Object as PropType<GetStatusFormPreferences | null>,
    default: () => ({}) as GetStatusFormPreferences,
    required: false,
  },
  terminal: {
    type: Object as PropType<Terminal | null>,
    default: () => ({}) as Terminal,
    required: false,
  },
  terminalId: {
    type: Number,
    default: null,
  },
  statusResponse: {
    type: Object as PropType<GetStatusResponse | null>,
    default: () => ({}) as GetStatusResponse,
    required: false,
  },
});

const i18n = useI18n();
const valid = ref(false);
const referenceNumber = ref("");
const containerNumber = ref("");
const getStatusType = ref();
const loading = ref(false);

const bookingTypes = [
  i18n.t(GetStatusRequestTypeEnum.Pickup),
  i18n.t(GetStatusRequestTypeEnum.Delivery),
];

const isButtonDisabled = computed(() => {
  if (loading.value || props.terminal === null) {
    return true;
  }

  const isPickup = getStatusType.value === i18n.t("pickup");
  const isDelivery = getStatusType.value === i18n.t("delivery");

  const isValidPickup =
    isPickup && containerNumber.value && referenceNumber.value;
  const isValidDelivery = isDelivery && referenceNumber.value;

  return !(isValidPickup || isValidDelivery);
});

watch(
  () => props.preferences,
  newVal => {
    if (newVal && newVal.bookingType && !getStatusType.value) {
      getStatusType.value = newVal.bookingType;
    }
  },
  { immediate: true },
);

const changeAssignedBookingType = (payload: {
  label: string;
  value: string;
}) => {
  if (!payload.value) {
    getStatusType.value = null;
  } else {
    getStatusType.value =
      payload.value === i18n.t("pickup")
        ? i18n.t("pickup")
        : i18n.t("delivery");
  }
  notifyDataChange();
};

const typeOfBookingMustBeSelected = (): boolean | string => {
  return !!getStatusType.value || (i18n.t("notSelected") as string);
};

const notifyDataChange = (): void => {
  emits("change-data", {
    terminal: props.terminal,
    bookingType: getStatusType.value,
  });
};

const submitRequestData = (): void => {
  loading.value = true;
  if (valid.value) {
    emits("submit-request", {
      getStatusRequest: {
        referenceNumber: referenceNumber.value,
        containerNumber: containerNumber.value,
        type:
          getStatusType.value === i18n.t("pickup")
            ? GetStatusRequestTypeEnum.Pickup
            : GetStatusRequestTypeEnum.Delivery,
        terminalId: props.terminalId,
      },
      terminal: props.terminal,
    });
  }
  loading.value = false;
};
</script>

<style lang="scss"></style>
